import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider, createTheme, Box, CircularProgress } from '@mui/material';
import UniversalBackground from './components/UniversalBackground';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { StoryPreview } from './types';

// Component imports
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
// import SurveyBanner from './components/DiscordBanner';
import About from './components/About';
import NewStory from './components/NewStory';
import Story from './components/Story';
import UnifiedLibrary from './components/UnifiedLibrary';
import Profile from './components/Profile';
import Public from './components/Public';
import Navbar from './components/Navbar';
import Signup from './components/Signup';
import Login from './components/Login';
import RequestReset from './components/RequestReset';
import ResetPassword from './components/ResetPassword';
import StoryList from './components/StoryList';
import ClassLibrary from './components/ClassLibrary';
import CreateClass from './components/CreateClass';
import ClassEnrollment from './components/ClassEnrollment';
import ClassDetail from './components/ClassDetail';
import ClassManager from './components/ClassManager';
import UnlvStudentPortal from './components/UnlvStudentPortal'; // Import UnlvStudentPortal component

// Constants
const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

// Theme configuration
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
      light: '#424242',
      dark: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FF4F00',
      light: '#FF8A00',
      dark: '#CC3F00',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
      light: '#f5f5f5',
      dark: '#e0e0e0',
      contrastText: '#000000',
    },
    secondary: {
      main: '#FF4F00',
      light: '#FF8A00',
      dark: '#CC3F00',
      contrastText: '#ffffff',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
});

// Theme context
interface ColorModeContextType {
  toggleColorMode: () => void;
  mode: 'light' | 'dark';
}

export const ColorModeContext = React.createContext<ColorModeContextType>({
  toggleColorMode: () => {},
  mode: 'light'
});

interface ClassPreview {
  id: number;
  name: string;
  description: string;
  students_count: number;
  stories_count: number;
  owner: string;
}

interface User {
  username: string;
  is_unlv_student: boolean;
  email?: string;
  first_name?: string;
  last_name?: string;
}

const AppContent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'));
  const [stories, setStories] = useState<StoryPreview[]>([]);
  const [popularClasses, setPopularClasses] = useState<ClassPreview[]>([]);
  const [myStories, setMyStories] = useState<StoryPreview[]>([]);
  const [myClasses, setMyClasses] = useState<ClassPreview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [quickTitle, setQuickTitle] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [userDataLoading, setUserDataLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const isHome = location.pathname === '/';

  const parseStoryData = useCallback((data: any[]) => {
    return data.map((story: any) => ({
      id: story.id,
      title: story.title,
      context: story.context,
      education_level: story.education_level || '',
      cover_image_url: story.cover_image_url,
      rating: story.rating || 0,
      num_ratings: story.num_ratings || 0,
      plays: story.plays || 0,
      like_count: story.like_count || 0,
      is_liked: story.is_liked || false,
      author: story.author,
      current_node: story.current_node ? {
        image_url: story.current_node.image_url || '',
        scene_number: story.current_node.scene_number
      } : undefined,
      tags: story.tags || [],
      created_at: story.created_at || new Date().toISOString(),
      has_completed_survey: Boolean(story.has_completed_survey),
      learning_style: story.learning_style,
      story_modes: story.story_modes || []
    }));
  }, []);

  const fetchUserData = useCallback(async () => {
    if (isAuthenticated) {
      setUserDataLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/accounts/user/`);
        console.log('[User Data]', {
          received: response.data,
          username: response.data?.username,
          isUnlvStudent: response.data?.is_unlv_student,
          timestamp: new Date().toISOString()
        });
        setUser(response.data);
      } catch (error) {
        console.error('[User Data] Error:', error);
      } finally {
        setUserDataLoading(false);
      }
    }
  }, [isAuthenticated]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      if (isAuthenticated) {
        // Fetch each endpoint separately to handle individual failures
        try {
          const storiesRes = await axios.get(`${API_BASE_URL}/stories/`);
          if (storiesRes.data) {
            setStories(storiesRes.data);
          } else {
            console.error('Error fetching explore stories: No data returned');
            setStories([]);
          }
        } catch (error) {
          console.error('Error fetching explore stories:', error);
          setStories([]);
        }

        try {
          const classesRes = await axios.get(`${API_BASE_URL}/classes/popular/`);
          if (classesRes.data) {
            const popularClassesData = Array.isArray(classesRes.data) ? classesRes.data : [];
            setPopularClasses(popularClassesData);
          } else {
            console.error('Error fetching popular classes: No data returned');
            setPopularClasses([]);
          }
        } catch (error) {
          console.error('Error fetching popular classes:', error);
          setPopularClasses([]);
        }

        try {
          const myClassesRes = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
          if (myClassesRes.data) {
            const myClassesData = Array.isArray(myClassesRes.data?.owned_classes) 
              ? myClassesRes.data.owned_classes 
              : [];
            setMyClasses(myClassesData);
          } else {
            console.error('Error fetching my classes: No data returned');
            setMyClasses([]);
          }
        } catch (error) {
          console.error('Error fetching my classes:', error);
          setMyClasses([]);
        }

        try {
          const myStoriesRes = await axios.get(`${API_BASE_URL}/stories/personal_library/`);
          if (myStoriesRes.data) {
            const parsedStories = parseStoryData(myStoriesRes.data);
            setMyStories(parsedStories);
          } else {
            console.error('Error fetching personal library: No data returned');
            setMyStories([]);
          }
        } catch (error) {
          console.error('Error fetching personal library:', error);
          setMyStories([]);
        }
      } else {
        // For unauthenticated users, only fetch public data
        try {
          const storiesRes = await axios.get(`${API_BASE_URL}/stories/`);
          if (storiesRes.data) {
            setStories(storiesRes.data);
          } else {
            console.error('Error fetching explore stories: No data returned');
            setStories([]);
          }
        } catch (error) {
          console.error('Error fetching explore stories:', error);
          setStories([]);
        }

        try {
          const classesRes = await axios.get(`${API_BASE_URL}/classes/popular/`);
          if (classesRes.data) {
            const popularClassesData = Array.isArray(classesRes.data) ? classesRes.data : [];
            setPopularClasses(popularClassesData);
          } else {
            console.error('Error fetching popular classes: No data returned');
            setPopularClasses([]);
          }
        } catch (error) {
          console.error('Error fetching popular classes:', error);
          setPopularClasses([]);
        }
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
      setError('Some content failed to load');
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated, parseStoryData]);

  const validImageStories = useMemo(() => {
    if (!stories || !Array.isArray(stories)) return [];
    return stories.filter(story => 
      story.current_node?.image_url && 
      story.current_node.image_url !== FALLBACK_IMAGE
    );
  }, [stories]);

  const popularStories = useMemo(() => {
    if (!stories || !Array.isArray(stories)) return [];
    return stories
      .filter(story => story.plays > 0)
      .sort((a, b) => b.plays - a.plays)
      .slice(0, 6);
  }, [stories]);

  const getImageUrl = (url: string | undefined | null): string => {
    if (!url) return FALLBACK_IMAGE;
    if (url.startsWith('http') || url.startsWith('data:')) return url;
    return `${S3_BUCKET_URL}/${url}`;
  };

  const hasValidImage = (story: StoryPreview): boolean => {
    return !!story.current_node?.image_url && story.current_node.image_url !== FALLBACK_IMAGE;
  };

  const handleLogin = useCallback(() => {
    setIsAuthenticated(true);
    // Wait for auth state to update before fetching user data
    setTimeout(() => {
      fetchUserData();
      fetchData();
    }, 100);
  }, [fetchUserData, fetchData]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    setMyStories([]);
    setMyClasses([]);
  };

  const handleAction = (path: string) => {
    window.location.href = path;
  };

  const handleQuickCreate = () => {
    if (quickTitle.trim()) {
      handleAction(`/new?title=${encodeURIComponent(quickTitle.trim())}`);
    }
  };

  const ImageWithFallback: React.FC<{ src: string | undefined | null; alt: string; className?: string }> = ({ src, alt, className }) => {
    const [imgSrc, setImgSrc] = useState(() => getImageUrl(src));

    useEffect(() => {
      setImgSrc(getImageUrl(src));
    }, [src]);

    return (
      <img
        src={imgSrc}
        alt={alt}
        onError={() => setImgSrc(FALLBACK_IMAGE)}
        className={className}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    );
  };

  const isLoading = isAuthenticated ? loading || userDataLoading : loading;

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
      fetchUserData();
    } else {
      fetchData();
    }
  }, [isAuthenticated, fetchData, fetchUserData]);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      }
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated, fetchUserData]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      background: 'transparent',
      position: 'relative',
      '& > *': {
        flexShrink: 0
      }
    }}>
      {/* <SurveyBanner /> */}
      <Navbar 
        isAuthenticated={isAuthenticated} 
        onLogout={handleLogout}
        isTransparent={true}
      />
      <Box component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          position: 'relative',
          zIndex: 0,
          background: 'transparent',
          marginTop: '48px'
        }}
      >
        <Routes>
          {/* Home Route */}
          <Route path="/" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <Dashboard
                  exploreStories={stories}
                  myStories={myStories}
                  handleAction={handleAction}
                  quickTitle={quickTitle}
                  setQuickTitle={setQuickTitle}
                  handleQuickCreate={handleQuickCreate}
                  ImageWithFallback={ImageWithFallback}
                  isUnlvStudent={user?.is_unlv_student}
                />
              </Container>
            ) : (
              <LandingPage
                validImageStories={validImageStories}
                stories={stories}
                popularClasses={popularClasses}
                currentImageIndex={0}
                getImageUrl={getImageUrl}
                loading={loading}
                error={error || ''}
                FALLBACK_IMAGE={FALLBACK_IMAGE}
                totalStoriesCreated={stories.length}
                totalScenesPlayed={stories.reduce((total, story) => total + (story.plays || 0), 0)}
              />
            )
          } />

          {/* Public routes */}
          <Route path="/about" element={<About />} />
          <Route path="/signup" element={
            <Container maxWidth="lg" sx={{ background: 'transparent' }}>
              <Signup onLogin={handleLogin} />
            </Container>
          } />
          <Route path="/public" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <Public />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/public' }} />
            )
          } />
          <Route path="/login" element={
            <Container maxWidth="lg" sx={{ background: 'transparent' }}>
              <Login onLogin={handleLogin} />
            </Container>
          } />
          <Route path="/forgot-password" element={
            <Container maxWidth="lg" sx={{ background: 'transparent' }}>
              <RequestReset />
            </Container>
          } />
          <Route path="/reset-password/:uid/:token" element={
            <Container maxWidth="lg" sx={{ background: 'transparent' }}>
              <ResetPassword />
            </Container>
          } />

          {/* Protected Routes */}
          <Route path="/story/:id" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <Story />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/story/:id' }} />
            )
          } />
          <Route path="/new" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <NewStory />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/new' }} />
            )
          } />
          <Route path="/enroll" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <ClassEnrollment />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/enroll' }} />
            )
          } />
          <Route path="/library" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <UnifiedLibrary />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/library' }} />
            )
          } />
          <Route path="/my-library" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <UnifiedLibrary />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/my-library' }} />
            )
          } />
          <Route path="/profile/*" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <Profile />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/profile' }} />
            )
          } />
          {/* Class Routes */}
          <Route path="/classes" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <ClassManager />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/classes' }} />
            )
          } />
          <Route path="/classes/create" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <CreateClass />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/classes/create' }} />
            )
          } />
          <Route path="/classes/enroll" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <ClassEnrollment />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/classes/enroll' }} />
            )
          } />
          <Route path="/classes/:classId" element={
            isAuthenticated ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <ClassDetail />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/classes/:classId' }} />
            )
          } />
          <Route path="/unlv-portal" element={
            isAuthenticated && user?.is_unlv_student ? (
              <Container maxWidth="lg" sx={{ background: 'transparent' }}>
                <UnlvStudentPortal 
                  stories={myStories}
                  onCreateStory={() => navigate('/new')}
                  onTakeSurvey={(storyId) => navigate(`/survey/${storyId}`)}
                />
              </Container>
            ) : (
              <Navigate to="/" />
            )
          } />
          {/* Catch-all redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Box>
  );
};

function App() {
  const [mode, setMode] = React.useState<'light' | 'dark'>(() => {
    const savedMode = localStorage.getItem('theme-mode');
    if (!savedMode) {
      localStorage.setItem('theme-mode', 'dark');
    }
    return (savedMode as 'light' | 'dark') || 'dark';
  });

  const toggleColorMode = React.useCallback(() => {
    setMode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme-mode', newMode);
      return newMode;
    });
  }, []);

  const theme = React.useMemo(
    () => createTheme(mode === 'light' ? lightTheme : darkTheme),
    [mode]
  );

  const value = React.useMemo(
    () => ({
      toggleColorMode,
      mode
    }),
    [toggleColorMode, mode]
  );

  return (
    <ColorModeContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UniversalBackground>
          <SnackbarProvider>
            <Router>
              <Box sx={{ 
                minHeight: '100vh',
                pt: show => show ? '48px' : 0,  // Add padding when banner is shown
                transition: 'padding-top 0.3s ease'
              }}>
                {/* <SurveyBanner /> */}
                <AppContent />
              </Box>
            </Router>
          </SnackbarProvider>
        </UniversalBackground>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;