import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Button, Card, CardContent, Grid, Chip,
  IconButton, Alert, Snackbar, Paper, useTheme, CircularProgress,
  LinearProgress, Tooltip, Badge
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Favorite,
  FavoriteBorder,
  BookmarkAdd,
  BookmarkAdded,
  ArrowBack as ArrowBackIcon,
  EmojiEvents as TrophyIcon,
  Timeline as TimelineIcon,
  Star as StarIcon,
  Speed as SpeedIcon,
  LocalFireDepartment as FireIcon,
  Psychology as InsightIcon
} from '@mui/icons-material';
import axios from 'axios';
import StoryEnd from './StoryEnd';
import UniversalBackground from './UniversalBackground';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const DEFAULT_NODE: StoryNode = {
  id: 0,  // Default ID for fallback node
  content: "Something went wrong. Please try again.",
  choices: ["Go back"],
  scene_number: 1,
  image_prompt: "",
  image_url: FALLBACK_IMAGE,
  summary: "Error state",
  characters: []
};

interface StoryData {
  id: number;
  title: string;
  current_node: StoryNode;
  current_scene: number;
  plays: number;
  likes: number;
  shares: number;
  cache_percentage: number;
  cover_image?: string;
  is_liked: boolean;
  is_saved: boolean;
  like_count: number;
  rating: number;
  num_ratings: number;
  comments: Array<{
    id: number;
    user?: {
      id: number;
      username: string;
    };
    text: string;
    created_at: string;
    rating: number;
  }>;
  nodes: StoryNode[];
}

interface StoryNode {
  content: string;
  choices: string[];
  scene_number: number;
  image_prompt: string;
  image_url: string;
  summary: string;
  characters: string[];
  parent_choice?: string;
  id: number;
}

interface FormattedTextProps {
  text: string;
}

const FormattedText: React.FC<FormattedTextProps> = ({ text }) => {
  const formatText = (content: string) => {
    if (!content) return null;

    // Handle case where content might be a JSON string
    let processedContent = content;
    if (typeof content === 'string') {
      try {
        const parsed = JSON.parse(content);
        if (parsed && typeof parsed === 'object' && parsed.content) {
          processedContent = parsed.content;
        }
      } catch (e) {
        // Not a JSON string, use as is
      }
    }
    
    const sentences = processedContent.split(/(\n+|"[^"]+?")/g);

    return sentences.map((part: string, index: number) => {
      if (!part?.trim()) {
        return null;
      }

      if (part.trim().startsWith('"') && part.trim().endsWith('"')) {
        const nextPart = sentences[index + 1] || '';
        const speakerMatch = nextPart.match(/,?\s*([\w\s]+)\s*(proclaimed|said|murmured|mused|chimed in|suggested|observed|whispered|asked|replied)/i);
        const speaker = speakerMatch ? speakerMatch[1].trim() : '';

        return (
          <div key={index} className="flex flex-col mb-4">
            <div className="flex items-start gap-3">
              {speaker && (
                <span className="text-sm font-semibold text-gray-800 mt-1 min-w-[100px] border-r pr-3">
                  {speaker}
                </span>
              )}
              <p className="text-lg flex-1 font-light italic">
                {part.trim()}
              </p>
            </div>
          </div>
        );
      } else if (part.trim()) {
        return (
          <p key={index} className="text-gray-700 mb-4 leading-relaxed text-lg">
            {part.trim()}
          </p>
        );
      }
      return null;
    }).filter(Boolean);
  };

  return (
    <div className="prose max-w-none">
      {text ? formatText(text) : null}
    </div>
  );
};

const StoryWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  position: 'relative',
  overflowX: 'hidden',
  paddingBottom: theme.spacing(4),
  backgroundColor: 'transparent'
}));

const StoryContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(4),
  position: 'relative',
  zIndex: 1,
  maxWidth: '1200px',
  margin: '0 auto',
}));

const StoryCard = styled(Card)(({ theme }) => ({
  background: theme.palette.mode === 'dark' 
    ? 'rgba(18, 18, 18, 0.8)'
    : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(4),
}));

const ChoiceButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '1rem',
  background: theme.palette.mode === 'dark'
    ? '#1E293B'
    : '#FFFFFF',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.2)' 
    : 'rgba(0, 0, 0, 0.12)'}`,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0, 0, 0, 0.3)'
    : '0 4px 12px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  textAlign: 'center',
  '&:hover': {
    background: theme.palette.mode === 'dark'
      ? '#2D3748'
      : '#F8FAFC',
    transform: 'translateY(-2px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 6px 16px rgba(0, 0, 0, 0.4)'
      : '0 6px 16px rgba(0, 0, 0, 0.15)',
  },
}));

const ChoicesSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const ProgressScoreCard = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark' 
    ? 'rgba(18, 18, 18, 0.8)'
    : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  border: `1px solid ${theme.palette.divider}`,
}));

const AchievementBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(0, 0, 0, 0.05)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-1px)',
  }
}));

interface UserProfile {
  level: number;
  total_points: number;
  current_streak: number;
  points_to_next_level: number;
}

const PointAnimation = styled('div')<{ showing: boolean }>(({ theme, showing }) => ({
  position: 'absolute',
  top: '50%',
  right: '100%',
  marginRight: '8px',
  transform: showing ? 'translate(0, -50%)' : 'translate(0, -100%)',
  opacity: showing ? 1 : 0,
  transition: 'all 0.3s ease-out',
  color: theme.palette.success.main,
  fontWeight: 'bold',
  fontSize: '0.8rem',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  pointerEvents: 'none',
  zIndex: 1,
}));

const LevelProgress = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '4px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  borderRadius: '2px',
  overflow: 'hidden',
  marginTop: '4px',
}));

const LevelProgressBar = styled('div')<{ progress: number }>(({ theme, progress }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: `${progress}%`,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '2px',
  transition: 'width 0.3s ease-out',
}));

interface PointGain {
  amount: number;
  timestamp: number;
}

const Story: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const [story, setStory] = useState<StoryData | null>(null);
  const [currentNode, setCurrentNode] = useState<StoryNode | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingChoice, setLoadingChoice] = useState<number | null>(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [showEndModal, setShowEndModal] = useState(false);
  const [failedImages, setFailedImages] = useState<Set<string>>(new Set());
  const [playRecorded, setPlayRecorded] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [resetCounter, setResetCounter] = useState(0);
  const [streakCount, setStreakCount] = useState(1);
  const [insightPoints, setInsightPoints] = useState(0);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [pointGains, setPointGains] = useState<PointGain[]>([]);
  const [previousPoints, setPreviousPoints] = useState(0);

  const getImageUrl = useCallback((url: string | undefined): string => {
    if (!url) return FALLBACK_IMAGE;
    if (url.startsWith('http') || url.startsWith('data:')) return url;
    if (process.env.NODE_ENV === 'production') {
      return `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${url}`;
    } else {
      return `/media/${url}`;
    }
  }, []);

  const fetchStory = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<StoryData>(`${API_BASE_URL}/stories/${id}/`);
      
      // Validate response data
      if (!response.data) {
        throw new Error('No data received from server');
      }
      
      console.log('Raw response data:', response.data);
      console.log('Current node before parsing:', response.data.current_node);
      
      // Parse current_node if needed
      let parsedNode = response.data.current_node;
      if (!parsedNode) {
        console.error('No current_node in response');
        parsedNode = DEFAULT_NODE;
      } else if (typeof parsedNode === 'string') {
        try {
          parsedNode = JSON.parse(parsedNode);
          console.log('Parsed current_node from string:', parsedNode);
        } catch (e) {
          console.error('Failed to parse current_node:', e);
          parsedNode = DEFAULT_NODE;
        }
      }

      // Validate parsed node has required fields
      if (!parsedNode || typeof parsedNode !== 'object' || !parsedNode.content || !Array.isArray(parsedNode.choices)) {
        console.error('Invalid node structure:', parsedNode);
        parsedNode = DEFAULT_NODE;
      }

      const updatedStory = {
        ...response.data,
        current_node: parsedNode
      };
      
      console.log('Final story state:', updatedStory);
      console.log('Final current_node:', parsedNode);
      
      setStory(updatedStory);
      setCurrentNode(parsedNode);
    } catch (error) {
      console.error('Error fetching story:', error);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          setError('Story not found or has been removed due to incompleteness.');
        } else if (error.response.status === 401) {
          navigate('/login', { state: { from: `/story/${id}` } });
        } else {
          setError(`Failed to fetch story: ${error.response.data.detail || error.response.statusText}`);
        }
      } else {
        setError('Failed to fetch story. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    fetchStory();
  }, [fetchStory, resetCounter]);

  const handlePlay = useCallback(async () => {
    if (!story || !id || playRecorded) return;
    try {
      await axios.post(`${API_BASE_URL}/stories/${id}/play/`);
      setPlayRecorded(true);
    } catch (error) {
      console.error('Error recording play:', error);
    }
  }, [id, story, playRecorded]);

  useEffect(() => {
    if (story && !playRecorded) {
      handlePlay();
    }
  }, [story, playRecorded, handlePlay]);

  useEffect(() => {
    if (currentNode?.image_url && !failedImages.has(currentNode.image_url)) {
      const imageUrl = getImageUrl(currentNode.image_url);
      console.log('Current image URL:', imageUrl);
    }
  }, [currentNode?.image_url, failedImages, getImageUrl]);

  const calculateReadingTime = (content: string) => {
    const wordsPerMinute = 200;
    const words = content.split(/\s+/).length;
    return Math.max(1, Math.round(words / wordsPerMinute));
  };

  const calculateInsightPoints = useCallback(() => {
    let points = 0;
    if (story?.current_scene && currentNode?.content) {
      // Points for story progress
      points += (story.current_scene - 1) * 125;
      
      // Points for engagement
      if (story.is_liked) points += 50;
      if (story.is_saved) points += 75;
      
      // Points for story completion speed
      const readingTime = calculateReadingTime(currentNode.content);
      points += Math.round(readingTime * 5);
      
      // Bonus points for maintaining streak
      points += streakCount * 15;
    }
    return points;
  }, [story, currentNode, streakCount]);

  const updateUserPoints = useCallback(async (points: number) => {
    try {
      // Calculate point gain
      const gain = points - previousPoints;
      if (gain > 0) {
        setPointGains(prev => [...prev, { amount: gain, timestamp: Date.now() }]);
        // Clean up old point gains after animation
        setTimeout(() => {
          setPointGains([]);
        }, 1000);
      }
      setPreviousPoints(points);

      const response = await axios.post<UserProfile>(
        `${API_BASE_URL}/user/points/update/`,
        { points }
      );
      setUserProfile(response.data);
      setStreakCount(response.data.current_streak);
    } catch (error) {
      console.error('Error updating user points:', error);
    }
  }, [previousPoints]);

  useEffect(() => {
    const points = calculateInsightPoints();
    setInsightPoints(points);
    
    // Only update backend points when there's actual progress
    if (story?.current_scene && story.current_scene > 1) {
      updateUserPoints(points);
    }
  }, [calculateInsightPoints, story?.current_scene, updateUserPoints]);

  const handleChoice = useCallback(async (choiceIndex: number) => {
    if (!id) return;
    setLoadingChoice(choiceIndex);
    setError(null);
    try {
      console.log('Making choice:', choiceIndex);
      const response = await axios.post<StoryData>(
        `${API_BASE_URL}/stories/${id}/make_choice/`,
        { choice_index: choiceIndex }
      );

      if (response.data && response.data.current_node) {
        let parsedNode = response.data.current_node;
        if (typeof parsedNode === 'string') {
          try {
            parsedNode = JSON.parse(parsedNode);
          } catch (e) {
            console.error('Failed to parse current_node:', e);
            parsedNode = DEFAULT_NODE;
          }
        }

        // Validate node has required fields
        if (!parsedNode || typeof parsedNode !== 'object' || !parsedNode.content || !Array.isArray(parsedNode.choices)) {
          console.error('Invalid node structure:', parsedNode);
          setError('Received invalid node data. Please try again.');
          return;
        }

        // Validate scene progression
        const currentScene = story?.current_scene || 0;
        const newScene = parsedNode.scene_number;
        
        console.log('Scene progression:', {
          currentScene,
          newScene,
          nodeData: parsedNode
        });

        if (!newScene || newScene <= currentScene) {
          console.error('Invalid scene progression:', {
            current: currentScene,
            new: newScene
          });
          setError('Error progressing story. Please try again.');
          return;
        }

        // Update both states atomically, preserving all story data
        const updatedStory = {
          ...response.data,
          current_node: parsedNode,
          current_scene: newScene,
          cache_percentage: response.data.cache_percentage
        };

        // Set both states together to ensure consistency
        setStory(updatedStory);
        setCurrentNode(parsedNode);

        // Calculate and update points after successful choice
        const newPoints = calculateInsightPoints();
        setInsightPoints(newPoints);
        updateUserPoints(newPoints);

      } else {
        console.error('Invalid response structure:', response.data);
        setError('Received invalid data from server. Please try again.');
      }
    } catch (error) {
      console.error('Error making choice:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error || error.response?.statusText || 'Failed to process choice';
        setError(errorMessage);
      } else {
        setError('An unexpected error occurred while making choice');
      }
    } finally {
      setLoadingChoice(null);
    }
  }, [id, story?.current_scene, calculateInsightPoints, updateUserPoints]);

  useEffect(() => {
    let progressInterval: NodeJS.Timeout;
    
    if (loadingChoice !== null) {
      setLoadingProgress(0);
      const startTime = Date.now();
      const duration = 30000; // 30 seconds

      progressInterval = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const progress = Math.min((elapsed / duration) * 100, 100);
        setLoadingProgress(progress);
        
        if (progress >= 100) {
          clearInterval(progressInterval);
        }
      }, 100); // Update every 100ms for smooth animation
    } else {
      setLoadingProgress(0);
    }

    return () => {
      if (progressInterval) {
        clearInterval(progressInterval);
      }
    };
  }, [loadingChoice]);

  const handleRestart = async () => {
    if (!id) return;
    setLoading(true);
    setError(null);
    try {
      // Get the initial node from the database
      const initialNode = story?.nodes?.find(node => node.scene_number === 1);
      if (!initialNode) {
        throw new Error('Could not find initial node');
      }

      // Update story state
      const updatedStory = {
        ...story,
        current_node: initialNode,
        current_scene: 1
      };
      
      // Update server state
      await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/reset/`, {
        node_id: initialNode.id
      });
      
      // Update local state
      setStory(updatedStory);
      setCurrentNode(initialNode);
      setShowEndModal(false);
      setPlayRecorded(false);
      setResetCounter(prev => prev + 1);
      setError(null);
      console.log('Story reset successfully:', updatedStory);
    } catch (error) {
      console.error('Error resetting story:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error || error.response?.statusText || 'Failed to reset story';
        setError(errorMessage);
      } else {
        setError('An unexpected error occurred while resetting the story');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = async () => {
    if (!id || !story || story.current_scene <= 1) {
      setSnackbar({ open: true, message: "You are already at the beginning of the story." });
      return;
    }

    setLoading(true);
    setError(null);

    try {
      console.log('Calling API:', `${API_BASE_URL}/stories/${id}/go_back/`);

      const response = await axios.post<StoryData>(
        `${API_BASE_URL}/stories/${id}/go_back/`,
        {}, // Empty body
        {
          headers: {
            'Content-Type': 'application/json',
            ...(localStorage.getItem('token') && {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            })
          },
          validateStatus: (status) => {
            return status >= 200 && status < 500; // Handle all responses
          }
        }
      );

      console.log('Go back response:', response.data);

      if (response.status === 200 && response.data) {
        setStory(response.data);
        
        // Parse current_node if it's a string
        let parsedNode = response.data.current_node;
        if (typeof parsedNode === 'string') {
          try {
            parsedNode = JSON.parse(parsedNode);
          } catch (e) {
            console.error('Failed to parse current_node:', e);
            parsedNode = DEFAULT_NODE;
          }
        }
        setCurrentNode(parsedNode);
        setResetCounter(prev => prev + 1);
        console.log('Updated story state after going back:', {
          scene: response.data.current_scene,
          node: parsedNode.scene_number,
          choices: parsedNode.choices
        });
      } else {
        throw new Error(
          (response.data as { error?: string })?.error || 'Failed to go back'
        );
      }
    } catch (error) {
      console.error('Error going back:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error || error.response?.statusText || 'Failed to go back';
        setError(errorMessage);
        if (error.response?.status === 404) {
          console.error('Endpoint not found:', error.response);
        }
      } else {
        setError('An unexpected error occurred while going back');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/like/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        is_liked: response.data.is_liked,
        like_count: response.data.like_count
      }) : null);
      setSnackbar({ open: true, message: response.data.is_liked ? 'Story liked!' : 'Story unliked.' });
    } catch (error) {
      console.error('Error liking story:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        navigate('/login', { state: { from: `/story/${id}` } });
      } else {
        setSnackbar({ open: true, message: 'Failed to like story. Please try again.' });
      }
    }
  };

  const handleSave = async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/save/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        is_saved: response.data.is_saved
      }) : null);
      setSnackbar({ open: true, message: response.data.is_saved ? 'Story saved!' : 'Story unsaved.' });
    } catch (error) {
      console.error('Error saving story:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        navigate('/login', { state: { from: `/story/${id}` } });
      } else {
        setSnackbar({ open: true, message: 'Failed to save story. Please try again.' });
      }
    }
  };

  const handleFinish = useCallback(() => {
    setShowEndModal(true);
  }, []);

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await axios.get<UserProfile>(`${API_BASE_URL}/user/profile/`);
      setUserProfile(response.data);
      setStreakCount(response.data.current_streak);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  }, []);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  if (loading) {
    console.log("Loading state triggered");
    return (
      <UniversalBackground>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          gap: 3,
        }}>
          <Box sx={{
            position: 'relative',
            display: 'inline-flex',
          }}>
            <CircularProgress
              size={80}
              thickness={4}
              sx={{
                color: theme.palette.primary.main,
                animation: 'pulse 1.5s ease-in-out infinite',
                '@keyframes pulse': {
                  '0%': {
                    transform: 'scale(0.95)',
                    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.3)',
                  },
                  '70%': {
                    transform: 'scale(1)',
                    boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
                  },
                  '100%': {
                    transform: 'scale(0.95)',
                    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                  },
                },
              }}
            />
            <Box sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <CircularProgress
                size={60}
                thickness={4}
                sx={{
                  color: theme.palette.secondary.main,
                  animation: 'reverse-spin 2s linear infinite',
                  '@keyframes reverse-spin': {
                    '0%': {
                      transform: 'rotate(360deg)',
                    },
                    '100%': {
                      transform: 'rotate(0deg)',
                    },
                  },
                }}
              />
            </Box>
          </Box>
          <Typography 
            variant="h6" 
            color="text.primary"
            sx={{
              opacity: 0.9,
              animation: 'fade 2s ease-in-out infinite',
              '@keyframes fade': {
                '0%, 100%': {
                  opacity: 0.6,
                },
                '50%': {
                  opacity: 1,
                },
              },
            }}
          >
            Loading...
          </Typography>
        </Box>
      </UniversalBackground>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4, maxWidth: 600, mx: 'auto', p: 3, backgroundColor: theme.palette.background.default }}>
        <Alert severity="error" sx={{ mb: 2, color: theme.palette.error.main, backgroundColor: theme.palette.error.light }}>{error}</Alert>
        <Button
          variant="outlined"
          onClick={() => navigate('/')}
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 2, borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#1a1a1a', color: theme.palette.text.primary }}
        >
          Back to Home
        </Button>
      </Box>
    );
  }

  if (!story || !currentNode) {
    return (
      <Box sx={{ mt: 4, maxWidth: 600, mx: 'auto', p: 3, backgroundColor: theme.palette.background.default }}>
        <Alert severity="warning" sx={{ mb: 2, color: theme.palette.warning.main, backgroundColor: theme.palette.warning.light }}>Story not found or has no content.</Alert>
        <Button
          variant="outlined"
          onClick={() => navigate('/')}
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 2, borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#1a1a1a', color: theme.palette.text.primary }}
        >
          Back to Home
        </Button>
      </Box>
    );
  }

  return (
    <>
      <StoryWrapper>
        <StoryContent>
          {/* Back Button */}
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
            sx={{
              mb: 2,
              color: 'text.primary',
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.05)',
              },
            }}
          >
            Back
          </Button>

          {/* Story Header */}
          <Box sx={{ mb: 3 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'flex-start',
              gap: 2,
              mb: 2
            }}>
              <Box>
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
                  {story.title}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                  <Chip 
                    label={`Scene ${story.current_scene || 1} of 8`}
                    color="primary"
                    variant="outlined"
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton
                      onClick={handleLike}
                      color={story.is_liked ? 'secondary' : 'default'}
                      sx={{
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                          color: theme.palette.secondary.main,
                        },
                        '&:active': {
                          transform: 'scale(0.95)',
                        },
                      }}
                    >
                      {story.is_liked ? <Favorite /> : <FavoriteBorder />}
                    </IconButton>
                    <Typography variant="body2" color="text.secondary">
                      {story.like_count || 0}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={handleSave}
                    color={story.is_saved ? 'primary' : 'default'}
                    sx={{
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        color: theme.palette.primary.main,
                      },
                      '&:active': {
                        transform: 'scale(0.95)',
                      },
                    }}
                  >
                    {story.is_saved ? <BookmarkAdded /> : <BookmarkAdd />}
                  </IconButton>
                </Box>
              </Box>

              {/* Story Progress Score */}
              <ProgressScoreCard>
                {/* Points and Level */}
                <Tooltip title="Total Story Points" arrow>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1,
                    position: 'relative',
                    minWidth: '100px',
                  }}>
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                      {/* Point gain animations */}
                      {pointGains.map((gain, index) => (
                        <PointAnimation 
                          key={gain.timestamp} 
                          showing={Date.now() - gain.timestamp < 1000}
                        >
                          +{gain.amount}
                        </PointAnimation>
                      ))}
                      <TrophyIcon color="primary" sx={{ fontSize: 20 }} />
                      <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', fontSize: '1.1rem', ml: 1 }}>
                        {insightPoints}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
                      <Chip
                        size="small"
                        label={`Lvl ${userProfile?.level || 1}`}
                        color="secondary"
                        sx={{ 
                          fontWeight: 'bold',
                          height: '20px',
                          '& .MuiChip-label': {
                            px: 1,
                            fontSize: '0.7rem'
                          }
                        }}
                      />
                      {/* Level progress bar */}
                      <Tooltip 
                        title={`${userProfile?.points_to_next_level || 0} points to next level`} 
                        arrow
                      >
                        <LevelProgress>
                          <LevelProgressBar 
                            progress={userProfile ? 
                              ((userProfile.total_points % 15000) / 15000) * 100 : 0
                            }
                          />
                        </LevelProgress>
                      </Tooltip>
                    </Box>
                  </Box>
                </Tooltip>

                {/* Divider */}
                <Box sx={{ 
                  height: '24px', 
                  width: '1px', 
                  backgroundColor: theme.palette.divider 
                }} />

                {/* Progress Bar */}
                <Tooltip title="Story Progress" arrow>
                  <Box sx={{ width: '100px' }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={Math.min((story.current_scene / 8) * 100, 100)} 
                      sx={{
                        height: 6,
                        borderRadius: 3,
                        backgroundColor: theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.1)'
                          : 'rgba(0, 0, 0, 0.1)',
                        '& .MuiLinearProgress-bar': {
                          borderRadius: 3,
                          backgroundImage: `linear-gradient(90deg, 
                            ${theme.palette.primary.main} 0%, 
                            ${theme.palette.secondary.main} 100%)`
                        }
                      }}
                    />
                  </Box>
                </Tooltip>

                {/* Divider */}
                <Box sx={{ 
                  height: '24px', 
                  width: '1px', 
                  backgroundColor: theme.palette.divider 
                }} />

                {/* Reading Streak */}
                <Tooltip title="Reading Streak - Keep reading daily to increase your streak!" arrow>
                  <AchievementBadge>
                    <FireIcon sx={{ color: '#FF6B6B', fontSize: 18 }} />
                    <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                      {streakCount}d
                    </Typography>
                  </AchievementBadge>
                </Tooltip>

                {/* Reading Time */}
                <Tooltip title="Estimated Reading Time" arrow>
                  <AchievementBadge>
                    <SpeedIcon sx={{ color: '#4ECDC4', fontSize: 18 }} />
                    <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                      {calculateReadingTime(currentNode?.content || '')}m
                    </Typography>
                  </AchievementBadge>
                </Tooltip>

                {/* Completion */}
                <Tooltip title="Story Completion" arrow>
                  <AchievementBadge>
                    <InsightIcon sx={{ color: '#FFD93D', fontSize: 18 }} />
                    <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                      {Math.round((story.current_scene / 8) * 100)}%
                    </Typography>
                  </AchievementBadge>
                </Tooltip>
              </ProgressScoreCard>
            </Box>
          </Box>

          {/* Story Content */}
          <StoryCard>
            <CardContent sx={{ p: 4 }}>
              <FormattedText text={currentNode?.content || DEFAULT_NODE.content} />
            </CardContent>
          </StoryCard>

          {/* Choices Section */}
          {!showEndModal && story.current_scene < 8 && currentNode?.choices && (
            <ChoicesSection>
              {currentNode.choices.map((choice, index) => (
                <ChoiceButton
                  key={index}
                  variant="outlined"
                  fullWidth
                  onClick={() => handleChoice(index)}
                  disabled={loadingChoice !== null}
                  sx={{
                    mb: 2,
                    position: 'relative',
                    overflow: 'hidden', // Ensure progress bar stays within button
                    '& .MuiButton-label': {
                      position: 'relative',
                      zIndex: 1
                    }
                  }}
                >
                  {/* Choice text */}
                  <span style={{ position: 'relative', zIndex: 2 }}>
                    {choice}
                  </span>

                  {/* Loading progress bar */}
                  {loadingChoice === index && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <LinearProgress
                        variant="determinate"
                        value={loadingProgress}
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(255, 255, 255, 0.12)',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: 'rgba(255, 255, 255, 0.24)',
                            transition: 'transform .1s linear'
                          }
                        }}
                      />
                    </Box>
                  )}
                </ChoiceButton>
              ))}
            </ChoicesSection>
          )}

          {/* Show Finish Story button on final scene */}
          {!showEndModal && story.current_scene === 8 && (
            <Box sx={{ 
              mt: 4, 
              display: 'flex', 
              justifyContent: 'center' 
            }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleFinish}
                sx={{
                  minWidth: 200,
                  py: 1.5,
                  fontSize: '1.1rem',
                  fontWeight: 'medium',
                }}
              >
                Finish Story
              </Button>
            </Box>
          )}

          {/* Navigation Buttons */}
          <Box sx={{ 
            mt: 4, 
            display: 'flex', 
            gap: 2,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={handleGoBack}
              disabled={loading || story.current_scene <= 1}
              sx={{
                minWidth: 150,
                color: 'text.primary',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                },
              }}
            >
              Previous Scene
            </Button>
            <Button
              variant="outlined"
              onClick={handleRestart}
              sx={{
                minWidth: 150,
                color: 'text.primary',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                },
              }}
            >
              Restart Story
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate('/')}
              sx={{
                minWidth: 150,
                color: 'text.primary',
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                },
              }}
            >
              Home
            </Button>
          </Box>
        </StoryContent>
      </StoryWrapper>

      {/* Story End Modal */}
      <StoryEnd
        open={showEndModal}
        onClose={() => setShowEndModal(false)}
        onRestart={handleRestart}
        title={story?.title || ''}
        storyData={{
          rating: story?.rating ?? 0,
          num_ratings: story?.num_ratings ?? 0,
          comments: story?.comments ?? []
        }}
      />

      {/* Loading and Error Snackbars */}
      <Snackbar
        open={loading}
        message="Loading next scene..."
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Story;