import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Chip,
  useTheme,
  Skeleton,
} from '@mui/material';
import {
  Add as AddIcon,
  School as SchoolIcon,
  ContentCopy as ContentCopyIcon,
  People as PeopleIcon,
  AutoStories as StoriesIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../constants';

interface ClassType {
  id: number;
  name: string;
  description: string;
  students_count: number;
  stories_count: number;
  owner: string;
  class_code?: string;
}

const ClassManager = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shouldOpenJoinDialog = searchParams.get('join') === 'true';

  const [activeTab, setActiveTab] = useState(0);
  const [ownedClasses, setOwnedClasses] = useState<ClassType[]>([]);
  const [enrolledClasses, setEnrolledClasses] = useState<ClassType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [joinDialogOpen, setJoinDialogOpen] = useState(shouldOpenJoinDialog);
  const [classCode, setClassCode] = useState('');
  const [newClassName, setNewClassName] = useState('');
  const [newClassDescription, setNewClassDescription] = useState('');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  // Fetch classes
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
        setOwnedClasses(response.data.owned_classes);
        setEnrolledClasses(response.data.enrolled_classes);
      } catch (error) {
        setError('Failed to load classes');
        console.error('Error fetching classes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  const handleJoinClass = async () => {
    try {
      await axios.post(`${API_BASE_URL}/classes/${classCode}/enroll/`);
      // Refresh classes after successful enrollment
      const classesResponse = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
      setEnrolledClasses(classesResponse.data.enrolled_classes);
      setJoinDialogOpen(false);
      setClassCode('');
      setError(null);
      setSuccess('Class joined successfully!');
      // Navigate to the class detail page
      const enrolledClass = classesResponse.data.enrolled_classes.find(
        (c: ClassType) => c.class_code === classCode
      );
      if (enrolledClass) {
        navigate(`/classes/${enrolledClass.id}`);
      }
    } catch (error: any) {
      let errorMessage = 'Failed to join class. Please check the class code.';
      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = error.response.data.detail || 'Invalid class code.';
            break;
          case 401:
            errorMessage = 'Please log in to join this class.';
            break;
          case 403:
            errorMessage = 'You do not have permission to join this class.';
            break;
          case 404:
            errorMessage = 'Class not found. Please check the code.';
            break;
          case 409:
            errorMessage = 'You are already enrolled in this class.';
            break;
        }
      }
      setError(errorMessage);
    }
  };

  const handleCreateClass = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/classes/`, {
        name: newClassName,
        description: newClassDescription
      });
      // Refresh classes after successful creation
      const classesResponse = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
      setOwnedClasses(classesResponse.data.owned_classes);
      setCreateDialogOpen(false);
      setNewClassName('');
      setNewClassDescription('');
      setError(null);
      setSuccess('Class created successfully!');
      // Navigate to the new class
      navigate(`/classes/${response.data.id}`);
    } catch (error: any) {
      let errorMessage = 'Failed to create class. Please try again.';
      if (error.response?.data?.detail) {
        errorMessage = error.response.data.detail;
      }
      setError(errorMessage);
    }
  };

  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code);
  };

  const ClassCard = ({ class_ }: { class_: ClassType }) => (
    <Card sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 2,
      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
      }
    }}>
      <CardActionArea 
        onClick={() => navigate(`/classes/${class_.id}`)}
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <CardContent sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column',
          p: 3 
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <SchoolIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {class_.name}
            </Typography>
          </Box>
          
          <Typography 
            variant="body2" 
            color="text.secondary" 
            sx={{ 
              mb: 2, 
              flexGrow: 1,
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {class_.description || 'No description provided'}
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2, 
            mt: 'auto',
            pt: 2,
            borderTop: 1,
            borderColor: 'divider'
          }}>
            <Chip
              icon={<PeopleIcon />}
              label={`${class_.students_count} Students`}
              size="small"
              variant="outlined"
            />
            <Chip
              icon={<StoriesIcon />}
              label={`${class_.stories_count} Stories`}
              size="small"
              variant="outlined"
            />
            {class_.class_code && (
              <Tooltip title="Copy class code">
                <IconButton 
                  size="small" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyCode(class_.class_code!);
                  }}
                  sx={{ 
                    ml: 'auto',
                    bgcolor: 'action.hover',
                    '&:hover': {
                      bgcolor: 'action.selected'
                    }
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  return (
    <Box sx={{ pt: 10 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ fontWeight: 600 }}>Classes</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              onClick={() => setJoinDialogOpen(true)}
              startIcon={<SchoolIcon />}
              sx={{
                bgcolor: '#2196F3',
                color: '#fff',
                '&:hover': {
                  bgcolor: '#1976D2',
                }
              }}
            >
              Join Class
            </Button>
            <Button
              variant="contained"
              onClick={() => setCreateDialogOpen(true)}
              startIcon={<AddIcon />}
              sx={{
                background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                color: '#fff',
                '&:hover': {
                  background: 'linear-gradient(45deg, #FF9500, #FF6000)',
                }
              }}
            >
              Create Class
            </Button>
          </Box>
        </Box>

        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 4 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" onClose={() => setSuccess(null)} sx={{ mb: 4 }}>
            {success}
          </Alert>
        )}

        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          sx={{ 
            mb: 4, 
            borderBottom: 1, 
            borderColor: 'divider',
            '& .MuiTab-root': {
              textTransform: 'none',
              minWidth: 120,
              fontSize: '1rem',
            }
          }}
        >
          <Tab 
            label={`Your Classes (${ownedClasses.length})`}
            icon={<SchoolIcon />}
            iconPosition="start"
          />
          <Tab 
            label={`Enrolled Classes (${enrolledClasses.length})`}
            icon={<PeopleIcon />}
            iconPosition="start"
          />
        </Tabs>

        {/* Class Grid */}
        <Grid container spacing={3}>
          {loading ? (
            // Loading skeletons
            [...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
                <Card sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 2,
                }}>
                  <CardContent sx={{ 
                    flexGrow: 1, 
                    display: 'flex', 
                    flexDirection: 'column',
                    p: 3 
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
                      <Skeleton variant="text" width="60%" height={32} />
                    </Box>
                    
                    <Skeleton variant="text" width="90%" sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" width="75%" sx={{ mb: 2 }} />

                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2, 
                      mt: 'auto',
                      pt: 2,
                      borderTop: 1,
                      borderColor: 'divider'
                    }}>
                      <Skeleton variant="rounded" width={100} height={24} />
                      <Skeleton variant="rounded" width={100} height={24} />
                      <Skeleton variant="circular" width={24} height={24} sx={{ ml: 'auto' }} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <>
              {(activeTab === 0 ? ownedClasses : enrolledClasses).map((class_) => (
                <Grid item xs={12} sm={6} md={4} key={class_.id}>
                  <ClassCard class_={class_} />
                </Grid>
              ))}
              {(activeTab === 0 ? ownedClasses : enrolledClasses).length === 0 && (
                <Grid item xs={12}>
                  <Box sx={{ 
                    textAlign: 'center', 
                    py: 8,
                    bgcolor: 'action.hover',
                    borderRadius: 2
                  }}>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                      {activeTab === 0 ? 'No classes created yet' : 'Not enrolled in any classes'}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => activeTab === 0 ? setCreateDialogOpen(true) : setJoinDialogOpen(true)}
                      startIcon={activeTab === 0 ? <AddIcon /> : <SchoolIcon />}
                      sx={{
                        mt: 2,
                        bgcolor: activeTab === 0 ? '#FF4F00' : '#2196F3',
                        color: '#fff',
                        '&:hover': {
                          bgcolor: activeTab === 0 ? '#FF6000' : '#1976D2',
                        }
                      }}
                    >
                      {activeTab === 0 ? 'Create Your First Class' : 'Join a Class'}
                    </Button>
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>

        {/* Join Class Dialog */}
        <Dialog 
          open={joinDialogOpen} 
          onClose={() => {
            setJoinDialogOpen(false);
            setClassCode('');
            setError(null);
          }}
          PaperProps={{
            sx: { borderRadius: 2 }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SchoolIcon color="primary" />
              <Typography variant="h6">Join a Class</Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ pt: '20px !important' }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Enter the class code provided by your instructor to join the class.
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              label="Class Code"
              fullWidth
              value={classCode}
              onChange={(e) => setClassCode(e.target.value)}
              error={!!error}
              helperText={error}
              sx={{ mt: 1 }}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button 
              onClick={() => {
                setJoinDialogOpen(false);
                setClassCode('');
                setError(null);
              }}
              sx={{ color: 'text.secondary' }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleJoinClass}
              variant="contained"
              disabled={!classCode.trim()}
              sx={{
                bgcolor: '#2196F3',
                color: '#fff',
                '&:hover': {
                  bgcolor: '#1976D2',
                }
              }}
            >
              Join Class
            </Button>
          </DialogActions>
        </Dialog>

        {/* Create Class Dialog */}
        <Dialog 
          open={createDialogOpen} 
          onClose={() => {
            setCreateDialogOpen(false);
            setNewClassName('');
            setNewClassDescription('');
            setError(null);
          }}
          PaperProps={{
            sx: { borderRadius: 2 }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AddIcon sx={{ color: '#FF4F00' }} />
              <Typography variant="h6">Create a New Class</Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ pt: '20px !important' }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Create a new class to share stories and track student progress.
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              label="Class Name"
              fullWidth
              value={newClassName}
              onChange={(e) => setNewClassName(e.target.value)}
              error={!!error}
              sx={{ mt: 1 }}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={newClassDescription}
              onChange={(e) => setNewClassDescription(e.target.value)}
              sx={{ mt: 2 }}
              helperText={error}
              error={!!error}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button 
              onClick={() => {
                setCreateDialogOpen(false);
                setNewClassName('');
                setNewClassDescription('');
                setError(null);
              }}
              sx={{ color: 'text.secondary' }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleCreateClass}
              variant="contained"
              disabled={!newClassName.trim()}
              sx={{
                background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                color: '#fff',
                '&:hover': {
                  background: 'linear-gradient(45deg, #FF9500, #FF6000)',
                }
              }}
            >
              Create Class
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default ClassManager;
