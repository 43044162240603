import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  TextField,
  Chip,
  Avatar,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  useTheme,
  Link as MuiLink,
  Paper,
  Alert,
  InputAdornment,
  InputLabel,
  ListSubheader,
  SelectChangeEvent,
} from "@mui/material";
import {
  Create,
  TrendingUp,
  School,
  People,
  MenuBook,
  ArrowForward,
  Search,
  Schedule,
  Star,
  Assignment,
  CheckCircle,
  LocalFireDepartment,
  EmojiEvents,
  Stars as StarsIcon,
  Whatshot,
  AutoStories,
  PlayCircle,
} from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import StoryCard from './StoryCard';
import GamificationStats from './GamificationStats';
import { StoryPreview } from '../types';
import axios from 'axios';
import { API_BASE_URL } from '../constants';

interface StoryData {
  id: number;
  title: string;
  current_node: {
    scene_number: number;
    image_url: string;
  };
  is_liked: boolean;
  like_count: number;
}

interface Story extends StoryPreview {
  // No additional fields needed
}

interface DashboardProps {
  exploreStories?: Story[];
  myStories?: Story[];
  handleAction: (path: string) => void;
  quickTitle: string;
  setQuickTitle: (title: string) => void;
  handleQuickCreate: () => void;
  ImageWithFallback: React.FC<{
    src?: string;
    alt: string;
    className?: string;
    style?: React.CSSProperties;
  }>;
  isUnlvStudent?: boolean;
}

interface UserProfile {
  level: number;
  total_points: number;
  points_to_next_level: number;
  current_streak: number;
  longest_streak: number;
  stories_created: number;
  stories_completed: number;
  scenes_played: number;
  username: string;
}

const DashboardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1400px',
  margin: '0 auto',
  paddingTop: theme.spacing(4),
  paddingX: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    paddingX: theme.spacing(3),
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1400px',
  padding: `0 ${theme.spacing(3)}`,
  margin: '0 auto',
  background: 'transparent',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
  },
}));

const UnlvDashboardAlert = ({ myStories }: { myStories: DashboardProps['myStories'] }) => {
  const navigate = useNavigate();
  const requiredStories = 5;
  const storiesCreated = myStories.length;
  const surveysCompleted = myStories.filter(s => s.has_completed_survey).length;
  const isComplete = storiesCreated >= requiredStories && surveysCompleted >= requiredStories;
  
  return (
    <Alert
      icon={isComplete ? <CheckCircle fontSize="inherit" /> : <Assignment fontSize="inherit" />}
      severity={isComplete ? "success" : "info"}
      sx={{
        mb: 3,
        backgroundColor: isComplete ? '#2e7d32' : '#B10202', // Green when complete, UNLV Scarlet otherwise
        color: 'white',
        '& .MuiAlert-icon': {
          color: 'white',
        },
        borderRadius: '12px',
        '& .MuiAlert-message': {
          width: '100%',
        },
      }}
      action={
        <Button
          onClick={() => navigate('/unlv-portal')}
          variant="contained"
          size="large"
          startIcon={<School />}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            color: 'white',
            fontWeight: 600,
            px: 2,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.25)',
            },
            whiteSpace: 'nowrap',
            height: '100%',
            borderRadius: '8px',
          }}
        >
          {isComplete ? 'View Completion' : 'View Progress'}
        </Button>
      }
    >
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
          UNLV Course Assignment {isComplete && '- Complete!'}
        </Typography>
        <Typography variant="body2">
          {isComplete 
            ? 'Congratulations! You have completed all required stories and surveys.'
            : 'Create 5 stories and complete surveys throughout the course. Track your progress in the UNLV portal.'}
        </Typography>
      </Box>
    </Alert>
  );
};

const QuickStartBackground = () => {
  return null;
  /* return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "-50%",
          left: "-50%",
          right: "-50%",
          bottom: "-50%",
          background: "radial-gradient(circle, rgba(255,138,0,0.1) 0%, rgba(255,79,0,0.1) 100%)",
          animation: "rotate 20s linear infinite",
        },
        "&::after": {
          content: '""',
          position: "absolute",
          top: "-50%",
          left: "-50%",
          right: "-50%",
          bottom: "-50%",
          background: "radial-gradient(circle, rgba(255,138,0,0.05) 0%, rgba(255,79,0,0.05) 100%)",
          animation: "rotate 30s linear infinite reverse",
        },
        borderRadius: '24px',
      }}
    />
  ); */
};

const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const getImageUrl = (source: string | undefined | null): string => {
  if (!source) return FALLBACK_IMAGE;
  if (source.startsWith('http') || source.startsWith('data:')) return source;
  if (source.startsWith('/static/')) return source;
  return `${S3_BUCKET_URL}/${source}`;
};

const transformStory = (story: any) => {
  // Determine learning style/mode
  let learningStyle = 'interactive_lecture';
  if (story.learning_mode) {
    learningStyle = story.learning_mode;
  } else if (story.is_educational === false) {
    learningStyle = 'entertainment';
  } else if (story.story_modes?.includes('magical_academy')) {
    learningStyle = 'story_based';
  }

  // Determine story mode
  let storyModes = ['default'];
  if (Array.isArray(story.story_modes) && story.story_modes.length > 0) {
    storyModes = story.story_modes;
  } else if (story.is_educational) {
    // If educational, default to magical_academy mode
    storyModes = ['magical_academy'];
  }

  return {
    id: story.id,
    title: story.title,
    current_node: {
      scene_number: story.current_node?.scene_number || 1,
      image_url: getImageUrl(story.current_node?.image_url || story.cover_image),
    },
    context: story.context,
    is_complete: story.completion_percentage === 100,
    has_completed_survey: story.has_completed_survey || false,
    story_modes: storyModes,
    tags: story.tags || [],
    education_level: story.education_level || 'General',
    learning_style: learningStyle,
    learning_mode: learningStyle, // Keep these in sync
    is_educational: story.is_educational || false,
    rating: story.rating || 0,
    plays: story.plays || 0,
    shares: 0,
    like_count: story.like_count || 0,
    is_liked: story.is_liked || false,
    author: story.author || 'Anonymous',
    complexity: story.complexity || 'medium',
  };
};

const Dashboard: React.FC<DashboardProps> = ({
  exploreStories = [],
  myStories = [],
  handleAction,
  quickTitle,
  setQuickTitle,
  handleQuickCreate,
  ImageWithFallback,
  isUnlvStudent,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStyle, setFilterStyle] = useState('all');
  const [filterMode, setFilterMode] = useState('all');
  const [sortBy, setSortBy] = useState('recent');
  const [filteredStories, setFilteredStories] = useState<typeof exploreStories>([]);
  const [dayStreak, setDayStreak] = useState(0);
  const [stories, setStories] = useState(exploreStories);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    setStories(exploreStories);
  }, [exploreStories]);

  useEffect(() => {
    console.log('[Dashboard] Render state:', {
      isUnlvStudent,
      type: typeof isUnlvStudent,
      storiesCount: myStories.length,
      shouldShowAlert: Boolean(isUnlvStudent),
      timestamp: new Date().toISOString()
    });
  }, [isUnlvStudent, myStories.length]);

  useEffect(() => {
    // Calculate day streak from local storage
    const lastActivityDate = localStorage.getItem('lastActivityDate');
    const currentStreak = parseInt(localStorage.getItem('currentStreak') || '0');
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (lastActivityDate) {
      const lastDate = new Date(lastActivityDate);
      lastDate.setHours(0, 0, 0, 0);
      
      const diffDays = Math.floor((today.getTime() - lastDate.getTime()) / (1000 * 60 * 60 * 24));
      
      if (diffDays === 0) {
        // Same day, keep streak
        setDayStreak(currentStreak);
      } else if (diffDays === 1) {
        // Next day, increment streak
        const newStreak = currentStreak + 1;
        localStorage.setItem('currentStreak', newStreak.toString());
        setDayStreak(newStreak);
      } else {
        // Streak broken
        localStorage.setItem('currentStreak', '1');
        setDayStreak(1);
      }
    } else {
      // First activity
      localStorage.setItem('currentStreak', '1');
      setDayStreak(1);
    }
    
    // Update last activity date
    localStorage.setItem('lastActivityDate', today.toISOString());
  }, []);

  useEffect(() => {
    // Fetch user profile data when component mounts
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get<UserProfile>(`${API_BASE_URL}/user/profile/`);
        setUserProfile(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const showUnlvAlert = Boolean(isUnlvStudent);

  const learningStyles = [
    { value: 'all', label: 'All Modes' },
    { value: 'interactive_lecture', label: 'Interactive Lecture 🎓 💭' },
    { value: 'story_based', label: 'Story Based 📚 🧙‍♀️' },
    { value: 'entertainment', label: 'Entertainment 🎮 🗺️' }
  ];

  const storyModes = [
    { value: 'all', label: 'All Modes' },
    // Interactive Lecture Modes
    { value: 'socratic_discussion', label: 'Socratic Discussion 🎓 💭' },
    { value: 'case_study', label: 'Case Study 🎓 🔍' },
    { value: 'problem_based', label: 'Problem Based 🎓 🧩' },
    { value: 'demonstration', label: 'Demonstration 🎓 🎯' },
    { value: 'group_discussion', label: 'Group Discussion 👥' },
    { value: 'visual_learning', label: 'Visual Learning 📊' },
    // Story Based Modes
    { value: 'magical_academy', label: 'Magical Academy 📚 🧙‍♀️' },
    { value: 'space_explorer', label: 'Space Explorer 📚 🚀' },
    { value: 'time_traveler', label: 'Time Traveler 📚 ⏰' },
    { value: 'secret_agent', label: 'Secret Agent 🕵️' },
    { value: 'monster_tamer', label: 'Monster Tamer 🐉' },
    { value: 'ancient_ruins', label: 'Ancient Ruins 🏺' },
    { value: 'jungle_explorer', label: 'Jungle Explorer 🌴' },
    { value: 'ocean_voyager', label: 'Ocean Voyager 🌊' },
    { value: 'inventors_workshop', label: 'Inventor\'s Workshop ⚡' },
    // Entertainment Modes
    { value: 'adventure', label: 'Adventure 🎮 🗺️' },
    { value: 'mystery', label: 'Mystery 🎮 🔍' },
    { value: 'fantasy', label: 'Fantasy 🎮 🐉' },
    { value: 'sci_fi', label: 'Sci-Fi 🎮 🚀' },
    { value: 'horror', label: 'Horror 👻' },
    { value: 'romance', label: 'Romance 💝' },
    { value: 'comedy', label: 'Comedy 😄' },
    { value: 'slice_of_life', label: 'Slice of Life 🌅' }
  ];

  const handleLike = async (e: React.MouseEvent, storyId: number) => {
    e.stopPropagation();
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${storyId}/like/`);
      
      // Update the story in our local state
      setStories(prevStories => 
        prevStories.map(story => 
          story.id === storyId 
            ? { 
                ...story, 
                is_liked: response.data.is_liked,
                like_count: response.data.like_count
              }
            : story
        )
      );
    } catch (error) {
      console.error('Error liking story:', error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate('/login', { state: { from: location.pathname } });
      }
    }
  };

  useEffect(() => {
    if (!exploreStories) {
      setFilteredStories([]);
      return;
    }

    const filtered = exploreStories.filter((story: Story) => {
      const searchTerm = searchQuery.toLowerCase().trim();
      const titleMatch = story.title?.toLowerCase().includes(searchTerm);
      const contextMatch = story.context?.toLowerCase().includes(searchTerm);
      const tagsMatch = Array.isArray(story.tags) && story.tags.some(tag => 
        tag.toLowerCase().includes(searchTerm)
      );
      const matchesSearch = searchTerm === '' || titleMatch || contextMatch || tagsMatch;
      
      const matchesStyle = filterStyle === 'all' || story.learning_style === filterStyle;
      const matchesMode = filterMode === 'all' || 
        (story.story_modes && Array.isArray(story.story_modes) && story.story_modes.includes(filterMode));

      return matchesSearch && matchesStyle && matchesMode;
    });

    // Sort the filtered stories
    const sorted = [...filtered];
    switch (sortBy) {
      case "popular":
        sorted.sort((a, b) => (b.plays || 0) - (a.plays || 0));
        break;
      case "rated":
        sorted.sort((a, b) => (b.like_count || 0) - (a.like_count || 0));
        break;
      // "recent" is default, stories are already sorted by date
    }

    setFilteredStories(sorted);

    // Debug log
    console.log('Filter/Sort Update:', {
      totalStories: exploreStories.length,
      searchQuery,
      filterStyle,
      filterMode,
      sortBy,
      filteredCount: filtered.length,
      firstStory: filtered[0]?.title
    });
  }, [exploreStories, searchQuery, filterStyle, filterMode, sortBy]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleStyleChange = (event: SelectChangeEvent) => {
    setFilterStyle(event.target.value);
  };

  const handleModeChange = (event: SelectChangeEvent) => {
    setFilterMode(event.target.value);
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value);
  };

  const renderStoryGrid = (stories: typeof exploreStories) => {
    if (!stories || stories.length === 0) {
      return (
        <Typography variant="body1" color="text.secondary" align="center">
          No stories found
        </Typography>
      );
    }

    return (
      <Grid container spacing={3}>
        {stories.map((story) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={story.id}>
            <StoryCard
              story={transformStory(story)}
              onClick={() => handleAction(`/story/${story.id}`)}
              showUnlvAlert={false}
              onLikeClick={handleLike}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const getRank = (level: number) => {
    if (level < 5) return 'Novice';
    if (level < 10) return 'Apprentice';
    if (level < 15) return 'Storyteller';
    if (level < 20) return 'Master';
    if (level < 25) return 'Grandmaster';
    return 'Legend';
  };

  const getNextAchievements = (userProfile: UserProfile) => {
    return [
      {
        name: 'Story Streak',
        description: 'Maintain a story streak for 7 days',
        progress: `${userProfile.current_streak}/7 days`,
        percentComplete: (userProfile.current_streak / 7) * 100,
        unlocked: userProfile.current_streak >= 7,
        icon: <LocalFireDepartment />
      },
      {
        name: 'Prolific Writer',
        description: 'Create 10 unique stories',
        progress: `${userProfile.stories_created}/10 stories`,
        percentComplete: (userProfile.stories_created / 10) * 100,
        unlocked: userProfile.stories_created >= 10,
        icon: <Create />
      },
      {
        name: 'Story Master',
        description: 'Complete 5 stories',
        progress: `${userProfile.stories_completed}/5 stories`,
        percentComplete: (userProfile.stories_completed / 5) * 100,
        unlocked: userProfile.stories_completed >= 5,
        icon: <EmojiEvents />
      }
    ];
  };

  return (
    <DashboardContainer>
      {isUnlvStudent && <UnlvDashboardAlert myStories={myStories} />}
      
      {/* Quick Start Section */}
      <Box sx={{ 
        mb: 4, 
        position: 'relative', 
        borderRadius: '24px', 
        overflow: 'hidden',
        bgcolor: theme.palette.mode === 'dark' 
          ? 'rgba(255,255,255,0.02)'
          : 'rgba(0,0,0,0.02)',
        border: `1px solid ${theme.palette.divider}`,
      }}>
        <Box sx={{ p: 4, maxWidth: '1400px', mx: 'auto', width: '100%', position: 'relative' }}>
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h3" 
              sx={{ 
                mb: 1,
                fontFamily: 'source-code-pro, monospace',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: theme => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.75)' : '#fff'
              }}
            >
              Welcome back, {userProfile?.username}! 👋
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 3,
                color: theme => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.7)'
              }}
            >
              Create and explore interactive stories.
            </Typography>

            {userProfile && (
              <>
                {/* Level and Progress Section */}
                <Box sx={{ 
                  background: theme.palette.mode === 'dark' 
                    ? 'linear-gradient(180deg, rgba(32,32,42,0.8) 0%, rgba(28,28,36,0.9) 100%)'
                    : 'linear-gradient(180deg, rgba(255,255,255,0.9) 0%, rgba(248,248,252,0.95) 100%)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: 3,
                  p: 3,
                  border: `1px solid ${theme.palette.divider}`,
                  mb: 3,
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                  gap: 3,
                }}>
                  {/* Level Badge Side */}
                  <Box>
                    <Box sx={{ position: 'relative', mb: 2 }}>
                      <Avatar 
                        sx={{ 
                          width: 100, 
                          height: 100,
                          bgcolor: 'primary.main',
                          fontSize: '2.5rem',
                          fontWeight: 'bold',
                          boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
                          border: '4px solid',
                          borderColor: 'primary.main',
                          animation: 'pulse 2s infinite',
                          mx: 'auto'
                        }}
                      >
                        {userProfile.level}
                      </Avatar>
                      <Box sx={{
                        position: 'absolute',
                        bottom: -10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bgcolor: 'warning.main',
                        color: 'warning.contrastText',
                        px: 2,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                      }}>
                        {getRank(userProfile.level)}
                      </Box>
                    </Box>

                    {/* Points Info */}
                    <Box sx={{ 
                      p: 2,
                      borderRadius: 2,
                      bgcolor: theme.palette.mode === 'dark' 
                        ? 'rgba(255,255,255,0.05)' 
                        : 'rgba(0,0,0,0.05)',
                      textAlign: 'center'
                    }}>
                      <Typography variant="body2" color="text.secondary">
                        Current Level Points
                      </Typography>
                      <Typography variant="h6" sx={{ 
                        fontWeight: 'bold',
                        background: 'linear-gradient(45deg, #FF6B6B 0%, #FFE66D 100%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        mb: 1
                      }}>
                        {(userProfile.total_points % 15000).toLocaleString()} / 15,000
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Total XP: {userProfile.total_points.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Progress Side */}
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        Level {userProfile.level}
                      </Typography>
                      <Tooltip title="Keep writing stories to level up!" arrow>
                        <StarsIcon sx={{ color: theme.palette.warning.main }} />
                      </Tooltip>
                    </Box>
                    <Box sx={{ position: 'relative', mb: 3 }}>
                      <Box sx={{
                        height: 24,
                        background: theme.palette.mode === 'dark' 
                          ? 'rgba(255,255,255,0.1)' 
                          : 'rgba(0,0,0,0.1)',
                        borderRadius: 2,
                        overflow: 'hidden',
                      }}>
                        <Box sx={{
                          height: '100%',
                          width: `${((userProfile.total_points % 15000) / 15000) * 100}%`,
                          background: 'linear-gradient(90deg, #FF6B6B 0%, #FFE66D 100%)',
                          transition: 'width 1s cubic-bezier(0.4, 0, 0.2, 1)',
                          position: 'relative',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0.1) 100%)',
                            animation: 'shine 2s infinite linear',
                          }
                        }} />
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: 'white',
                          fontWeight: 'bold',
                          textShadow: '0 1px 2px rgba(0,0,0,0.5)',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {userProfile.points_to_next_level.toLocaleString()} XP to Level {userProfile.level + 1}
                      </Typography>
                    </Box>

                    {/* Quick Stats */}
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Box sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          background: 'linear-gradient(135deg, #FF6B6B 0%, #FF4F00 100%)',
                          color: 'white',
                          borderRadius: 3,
                          transition: 'all 0.3s ease',
                          boxShadow: '0 4px 12px rgba(255,79,0,0.2)',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 8px 16px rgba(255,79,0,0.3)',
                          }
                        }}>
                          <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            mb: 1
                          }}>
                            <AutoStories sx={{ 
                              fontSize: '2rem',
                              animation: 'float 3s ease-in-out infinite',
                              '@keyframes float': {
                                '0%, 100%': { transform: 'translateY(0)' },
                                '50%': { transform: 'translateY(-5px)' },
                              }
                            }} />
                            <Typography variant="h4" sx={{ 
                              fontWeight: 600,
                              color: 'white',
                              fontFamily: 'source-code-pro, monospace',
                            }}>{myStories?.length || 0}</Typography>
                          </Box>
                          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>Stories Created</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          background: 'linear-gradient(135deg, #4FACFE 0%, #00F2FE 100%)',
                          color: 'white',
                          borderRadius: 3,
                          transition: 'all 0.3s ease',
                          boxShadow: '0 4px 12px rgba(33,150,243,0.2)',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 8px 16px rgba(33,150,243,0.3)',
                          }
                        }}>
                          <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            mb: 1
                          }}>
                            <PlayCircle sx={{ 
                              fontSize: '2rem',
                              animation: 'pulse 2s ease-in-out infinite',
                              '@keyframes pulse': {
                                '0%': { transform: 'scale(1)' },
                                '50%': { transform: 'scale(1.1)' },
                                '100%': { transform: 'scale(1)' },
                              }
                            }} />
                            <Typography variant="h4" sx={{ 
                              fontWeight: 600,
                              color: 'white',
                              fontFamily: 'source-code-pro, monospace',
                            }}>{myStories?.reduce((total, story) => {
                              const plays = typeof story.plays === 'number' ? story.plays : 0;
                              return total + plays;
                            }, 0) || 0}</Typography>
                          </Box>
                          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>Total Plays</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{ 
                          textAlign: 'center', 
                          p: 2, 
                          background: 'linear-gradient(135deg, #43E97B 0%, #38F9D7 100%)',
                          color: 'white',
                          borderRadius: 3,
                          transition: 'all 0.3s ease',
                          boxShadow: '0 4px 12px rgba(0,200,83,0.2)',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 8px 16px rgba(0,200,83,0.3)',
                          }
                        }}>
                          <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            mb: 1
                          }}>
                            <LocalFireDepartment 
                              sx={{ 
                                fontSize: '2rem',
                                animation: 'flame 1.5s ease-in-out infinite alternate',
                                '@keyframes flame': {
                                  '0%': { transform: 'scale(1)', filter: 'brightness(100%)' },
                                  '100%': { transform: 'scale(1.1)', filter: 'brightness(120%)' },
                                },
                                color: 'rgba(255,255,255,0.9)'
                              }} 
                            />
                            <Typography variant="h4" sx={{ 
                              fontWeight: 600,
                              color: 'white',
                              fontFamily: 'source-code-pro, monospace',
                            }}>{userProfile?.current_streak || dayStreak}</Typography>
                          </Box>
                          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.9)' }}>Day Streak</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {/* Achievement and Stats Grid */}
                <Box sx={{ 
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 3
                }}>
                  {/* Achievement Progress */}
                  <Box sx={{ 
                    p: 2,
                    borderRadius: 2,
                    bgcolor: theme.palette.mode === 'dark' 
                      ? 'rgba(255,255,255,0.05)' 
                      : 'rgba(0,0,0,0.05)',
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Next Achievements
                      </Typography>
                      <Tooltip title="Complete achievements to earn XP and rewards!" arrow>
                        <EmojiEvents sx={{ color: theme.palette.warning.main }} />
                      </Tooltip>
                    </Box>
                    <Box sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1.5
                    }}>
                      {getNextAchievements(userProfile).map((achievement, index) => (
                        <Box key={index} sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                        }}>
                          <Box sx={{ 
                            width: 40,
                            height: 40,
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: achievement.unlocked 
                              ? 'rgba(0,200,83,0.1)'
                              : 'rgba(0,0,0,0.1)',
                            color: achievement.unlocked 
                              ? theme.palette.success.main
                              : theme.palette.text.secondary,
                          }}>
                            {achievement.icon}
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <Typography variant="body2" sx={{ 
                              fontWeight: 'bold',
                              color: achievement.unlocked 
                                ? theme.palette.success.main
                                : theme.palette.text.primary
                            }}>
                              {achievement.name}
                            </Typography>
                            <Box sx={{ 
                              mt: 0.5,
                              height: 4,
                              borderRadius: 2,
                              bgcolor: 'rgba(0,0,0,0.1)',
                              overflow: 'hidden'
                            }}>
                              <Box sx={{ 
                                height: '100%',
                                width: `${achievement.percentComplete}%`,
                                bgcolor: achievement.unlocked 
                                  ? theme.palette.success.main
                                  : theme.palette.primary.main,
                                transition: 'width 0.3s ease'
                              }} />
                            </Box>
                            <Typography variant="caption" color="text.secondary">
                              {achievement.progress}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  {/* Stats and Rewards */}
                  <Box sx={{ 
                    p: 2,
                    borderRadius: 2,
                    bgcolor: theme.palette.mode === 'dark' 
                      ? 'rgba(255,255,255,0.05)' 
                      : 'rgba(0,0,0,0.05)',
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Story Stats & Rewards
                      </Typography>
                      <Tooltip title="Earn rewards by creating and completing stories!" arrow>
                        <Star sx={{ color: theme.palette.warning.main }} />
                      </Tooltip>
                    </Box>
                    
                    {/* Weekly Target */}
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        Weekly Story Target
                      </Typography>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 1,
                        mt: 1
                      }}>
                        <Box sx={{ 
                          flex: 1,
                          height: 8,
                          borderRadius: 4,
                          bgcolor: 'rgba(0,0,0,0.1)',
                          overflow: 'hidden'
                        }}>
                          <Box sx={{ 
                            height: '100%',
                            width: `${(userProfile.stories_created % 5) * 20}%`,
                            bgcolor: theme.palette.primary.main,
                            transition: 'width 0.3s ease'
                          }} />
                        </Box>
                        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                          {userProfile.stories_created % 5}/5
                        </Typography>
                      </Box>
                    </Box>

                    {/* Quick Stats */}
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                      <Box sx={{ 
                        p: 1.5,
                        borderRadius: 2,
                        bgcolor: 'rgba(33,150,243,0.1)',
                      }}>
                        <Typography variant="caption" color="text.secondary">
                          Avg. Scenes/Day
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2196F3' }}>
                          {Math.round(userProfile.scenes_played / Math.max(1, userProfile.current_streak))}
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        p: 1.5,
                        borderRadius: 2,
                        bgcolor: 'rgba(255,79,0,0.1)',
                      }}>
                        <Typography variant="caption" color="text.secondary">
                          Completion Rate
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF4F00' }}>
                          {Math.round((userProfile.stories_completed / Math.max(1, userProfile.stories_created)) * 100)}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {/* Pick up where you left off */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" gutterBottom sx={{ 
          mb: 3,
          fontWeight: 600,
          color: 'text.primary'
        }}>
          Pick up where you left off
        </Typography>
        <Grid container spacing={3}>
          {stories.slice(0, 8).map((story) => (
            <Grid item xs={12} sm={6} md={3} key={story.id}>
              <StoryCard 
                story={transformStory(story)}
                showUnlvAlert={showUnlvAlert}
                onLikeClick={handleLike}
                onClick={() => handleAction(`/story/${story.id}`)}
                sx={{
                  '& .MuiTypography-root': {
                    color: theme => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.75)' : '#fff'
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Search and Filter Section */}
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Search Stories"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Learning Mode</InputLabel>
              <Select
                value={filterStyle}
                onChange={handleStyleChange}
                label="Learning Mode"
              >
                {learningStyles.map((style) => (
                  <MenuItem key={style.value} value={style.value}>
                    {style.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Story Mode</InputLabel>
              <Select
                value={filterMode}
                onChange={handleModeChange}
                label="Story Mode"
              >
                <MenuItem value="all">All Modes</MenuItem>
                
                <ListSubheader>Lecture Modes</ListSubheader>
                <MenuItem value="socratic_discussion">Socratic Discussion 💭</MenuItem>
                <MenuItem value="case_study">Case Study 🔍</MenuItem>
                <MenuItem value="problem_based">Problem-Based 🧩</MenuItem>
                <MenuItem value="demonstration">Demonstration 🎯</MenuItem>
                <MenuItem value="group_discussion">Group Discussion 👥</MenuItem>
                <MenuItem value="visual_learning">Visual Learning 📊</MenuItem>
                
                <ListSubheader>Educational Modes</ListSubheader>
                <MenuItem value="magical_academy">Magical Academy 🧙‍♀️</MenuItem>
                <MenuItem value="space_explorer">Space Explorer 🚀</MenuItem>
                <MenuItem value="time_traveler">Time Traveler ⏰</MenuItem>
                <MenuItem value="secret_agent">Secret Agent 🕵️</MenuItem>
                <MenuItem value="monster_tamer">Monster Tamer 🐉</MenuItem>
                <MenuItem value="ancient_ruins">Ancient Ruins 🏺</MenuItem>
                <MenuItem value="jungle_explorer">Jungle Explorer 🌴</MenuItem>
                <MenuItem value="ocean_voyager">Ocean Voyager 🌊</MenuItem>
                <MenuItem value="inventors_workshop">Inventor's Workshop ⚡</MenuItem>
                
                <ListSubheader>Entertainment Modes</ListSubheader>
                <MenuItem value="adventure">Adventure 🎮 🗺️</MenuItem>
                <MenuItem value="mystery">Mystery 🎮 🔍</MenuItem>
                <MenuItem value="fantasy">Fantasy 🎮 🐉</MenuItem>
                <MenuItem value="sci_fi">Sci-Fi 🎮 🚀</MenuItem>
                <MenuItem value="horror">Horror 👻</MenuItem>
                <MenuItem value="romance">Romance 💝</MenuItem>
                <MenuItem value="comedy">Comedy 😄</MenuItem>
                <MenuItem value="slice_of_life">Slice of Life 🌅</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Sort By</InputLabel>
              <Select
                value={sortBy}
                onChange={handleSortChange}
                label="Sort By"
              >
                <MenuItem value="popular">Most Popular</MenuItem>
                <MenuItem value="recent">Most Recent</MenuItem>
                <MenuItem value="rated">Highest Rated</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {(filterStyle !== 'all' || filterMode !== 'all' || searchQuery !== '') && (
          <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
            <Chip 
              label={`${filteredStories.length} stories`} 
              color="primary" 
              sx={{ fontWeight: 'bold' }}
            />
            <Chip
              label="Clear Filters"
              onClick={() => {
                setFilterStyle('all');
                setFilterMode('all');
                setSearchQuery('');
              }}
              onDelete={() => {
                setFilterStyle('all');
                setFilterMode('all');
                setSearchQuery('');
              }}
            />
          </Box>
        )}
      </Box>

      {/* Stories Grid */}
      <Box sx={{ py: 4 }}>
        {renderStoryGrid(filteredStories)}
      </Box>
    </DashboardContainer>
  );
};

export default Dashboard;