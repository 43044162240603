import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Button, 
  Avatar, 
  Chip,
  CircularProgress,
  Alert,
  Menu,
  MenuItem,
  Container,
  Card,
  CardContent,
  Divider,
  Grid,
} from '@mui/material';
import {
  Favorite,
  FavoriteBorder,
  Share,
  BookmarkAdd,
  BookmarkAdded,
  TrendingUp,
  School,
  Public as PublicIcon,
  ThumbUp,
  ThumbUpOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface StoryPost {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  author?: string;
  created_at: string;
  current_node?: {
    image_url: string;
  };
  image_url: string;
  is_liked: boolean;
  is_saved: boolean;
  like_count: number;
  learning_style?: string;
  story_modes?: string[];
  subjects?: string[];
}

const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const Public: React.FC = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<StoryPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = useState<'recent' | 'popular'>('recent');

  const fetchPosts = useCallback(async () => {
    setLoading(true);
    try {
      const endpoint = sortBy === 'recent' ? 'stories/' : 'stories/popular/';
      const response = await axios.get(`${API_BASE_URL}/${endpoint}`);
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to load posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [sortBy]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const getImageUrl = (story: StoryPost): string => {
    if (story.current_node?.image_url) {
      if (story.current_node.image_url.startsWith('http')) return story.current_node.image_url;
      if (story.current_node.image_url.startsWith('data:')) return story.current_node.image_url;
      return `${S3_BUCKET_URL}/${story.current_node.image_url}`;
    }
    return FALLBACK_IMAGE;
  };

  const ImageWithFallback: React.FC<{ story: StoryPost }> = ({ story }) => {
    const [imgSrc, setImgSrc] = useState(() => getImageUrl(story));

    return (
      <Box
        component="img"
        src={imgSrc}
        alt={story.title}
        onError={() => {
          if (imgSrc !== FALLBACK_IMAGE) {
            console.error('Failed to load image:', imgSrc);
            setImgSrc(FALLBACK_IMAGE);
          }
        }}
        sx={{
          width: '100%',
          borderRadius: 4,
          objectFit: 'cover',
          aspectRatio: '16/9',
        }}
      />
    );
  };

  const handleLike = async (postId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    
    try {
      const response = await axios.post(
        `${API_BASE_URL}/stories/${postId}/like/`,
        {},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );

      setPosts(prevPosts => 
        prevPosts.map(post => 
          post.id === postId ? {
            ...post,
            is_liked: response.data.is_liked,
            like_count: response.data.like_count
          } : post
        )
      );
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleSave = async (postId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      const response = await axios.post(`${API_BASE_URL}/stories/${postId}/save/`);
      setPosts(prevPosts => 
        prevPosts.map(post => 
          post.id === postId ? {
            ...post,
            is_saved: response.data.is_saved
          } : post
        )
      );
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  const handleShare = async (post: StoryPost, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      const shareData = {
        title: post.title,
        text: `Check out this story on Storybyte: ${post.title}`,
        url: `${window.location.origin}/story/${post.id}`
      };
      
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(shareData.url);
      }
    } catch (error) {
      console.error('Error sharing post:', error);
    }
  };

  const handlePostClick = async (postId: number) => {
    try {
      // Increment play count first
      setPosts(prevPosts =>
        prevPosts.map(post =>
          post.id === postId ? {
            ...post,
            plays: (post.plays || 0) + 1
          } : post
        )
      );
      
      // Update play count on server
      await axios.post(`${API_BASE_URL}/stories/${postId}/play/`);
      
      // Navigate to story
      navigate(`/story/${postId}`);
    } catch (error) {
      console.error('Error updating play count:', error);
      navigate(`/story/${postId}`); // Still navigate even if play count update fails
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4, px: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Header Section */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <PublicIcon sx={{ fontSize: 32, color: 'primary.main' }} />
          <Typography variant="h4" component="h1" sx={{ fontWeight: 600 }}>
            Public Feed
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant={sortBy === 'recent' ? 'contained' : 'outlined'}
            onClick={() => setSortBy('recent')}
            size="small"
            sx={{ minWidth: 100 }}
          >
            Recent
          </Button>
          <Button
            variant={sortBy === 'popular' ? 'contained' : 'outlined'}
            onClick={() => setSortBy('popular')}
            size="small"
            sx={{ minWidth: 100 }}
          >
            Popular
          </Button>
        </Box>
      </Box>

      {/* Grid Layout */}
      <Grid container spacing={3}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={post.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 3,
                overflow: 'hidden',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                cursor: 'pointer',
                backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'background.paper',
                border: theme => `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.06)'}`,
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: theme => theme.palette.mode === 'dark' 
                    ? '0 8px 24px rgba(0,0,0,0.4)' 
                    : '0 8px 24px rgba(0,0,0,0.12)',
                  borderColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.2)' 
                    : 'rgba(0, 0, 0, 0.12)',
                },
              }}
              onClick={() => handlePostClick(post.id)}
            >
              <Box 
                sx={{ 
                  position: 'relative', 
                  paddingTop: '66.67%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  component="img"
                  src={getImageUrl(post)}
                  alt={post.title}
                  onError={(e: any) => {
                    if (e.target.src !== FALLBACK_IMAGE) {
                      e.target.src = FALLBACK_IMAGE;
                    }
                  }}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)',
                    padding: '48px 16px 12px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Avatar 
                    sx={{ 
                      width: 32, 
                      height: 32,
                      border: '2px solid white',
                    }}
                  >
                    {post.author?.[0] || 'A'}
                  </Avatar>
                  <Box>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        color: 'white',
                        textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                      }}
                    >
                      {post.author || 'Anonymous'}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: 'rgba(255,255,255,0.8)',
                        textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                      }}
                    >
                      {new Date(post.created_at).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <CardContent 
                sx={{ 
                  flexGrow: 1, 
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ mb: 'auto' }}>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      mb: 1.5,
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      lineHeight: 1.3,
                    }}
                  >
                    {post.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mb: 2,
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      lineHeight: 1.5,
                    }}
                  >
                    {post.context}
                  </Typography>

                  <Box 
                    sx={{ 
                      display: 'flex', 
                      gap: 1, 
                      mb: 2,
                      flexWrap: 'wrap'
                    }}
                  >
                    {post.learning_style && (
                      <Chip
                        size="small"
                        label={post.learning_style}
                        sx={{
                          backgroundColor: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(25, 118, 210, 0.15)'
                            : 'rgba(25, 118, 210, 0.08)',
                          color: 'primary.main',
                          fontWeight: 500,
                        }}
                      />
                    )}
                    {post.subjects?.slice(0, 2).map((subject, index) => (
                      <Chip
                        key={`subject-${index}`}
                        size="small"
                        label={subject}
                        sx={{
                          backgroundColor: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(156, 39, 176, 0.15)'
                            : 'rgba(156, 39, 176, 0.08)',
                          color: 'secondary.main',
                          fontWeight: 500,
                        }}
                      />
                    ))}
                    {post.subjects && post.subjects.length > 2 && (
                      <Chip
                        size="small"
                        label={`+${post.subjects.length - 2}`}
                        sx={{
                          backgroundColor: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.05)'
                            : 'rgba(0, 0, 0, 0.05)',
                          color: 'text.secondary',
                        }}
                      />
                    )}
                    {post.story_modes?.slice(0, 2).map((mode, index) => (
                      <Chip
                        key={`mode-${index}`}
                        size="small"
                        label={mode}
                        sx={{
                          backgroundColor: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(245, 124, 0, 0.15)'
                            : 'rgba(245, 124, 0, 0.08)',
                          color: 'warning.main',
                          fontWeight: 500,
                        }}
                      />
                    ))}
                    {post.story_modes && post.story_modes.length > 2 && (
                      <Chip
                        size="small"
                        label={`+${post.story_modes.length - 2}`}
                        sx={{
                          backgroundColor: theme => theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.05)'
                            : 'rgba(0, 0, 0, 0.05)',
                          color: 'text.secondary',
                        }}
                      />
                    )}
                  </Box>

                  <Box 
                    sx={{ 
                      display: 'flex', 
                      gap: 1, 
                      mb: 2,
                      flexWrap: 'wrap'
                    }}
                  >
                    <Chip
                      size="small"
                      icon={<TrendingUp sx={{ fontSize: 16 }} />}
                      label={`${post.plays || 0} plays`}
                      sx={{
                        backgroundColor: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.05)' 
                          : 'rgba(0, 0, 0, 0.05)',
                        '& .MuiChip-icon': {
                          color: 'primary.main',
                        },
                      }}
                    />
                    <Chip
                      size="small"
                      icon={<ThumbUp sx={{ 
                        fontSize: 16, 
                        color: theme => theme.palette.mode === 'dark' ? 'white' : 'inherit'
                      }} />}
                      label={`${post.like_count || 0} likes`}
                      sx={{
                        backgroundColor: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.05)' 
                          : 'rgba(0, 0, 0, 0.05)',
                        '& .MuiChip-icon': {
                          color: theme => theme.palette.mode === 'dark' ? 'white' : 'inherit',
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box>
                  <Divider sx={{ mb: 1.5 }} />
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton 
                        size="small" 
                        onClick={(e) => handleLike(post.id, e)}
                        sx={{
                          transition: 'transform 0.2s ease',
                          '&:hover': {
                            transform: 'scale(1.1)',
                          },
                          color: post.is_liked ? 'primary.main' : 'inherit'
                        }}
                      >
                        {post.is_liked ? (
                          <ThumbUp fontSize="small" color="primary" />
                        ) : (
                          <ThumbUpOutlined fontSize="small" />
                        )}
                      </IconButton>
                      <IconButton 
                        size="small"
                        onClick={(e) => handleShare(post, e)}
                        sx={{
                          transition: 'transform 0.2s ease',
                          '&:hover': {
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        <Share fontSize="small" />
                      </IconButton>
                    </Box>
                    <IconButton 
                      size="small"
                      onClick={(e) => handleSave(post.id, e)}
                      sx={{
                        transition: 'transform 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        },
                      }}
                    >
                      {post.is_saved ? (
                        <BookmarkAdded fontSize="small" color="primary" />
                      ) : (
                        <BookmarkAdd fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Post Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setAnchorEl(null)}>Report</MenuItem>
      </Menu>
    </Container>
  );
};

export default Public;