import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  styled,
  Chip,
  Tooltip
} from '@mui/material';
import { 
  Star as StarIcon, 
  School as SchoolIcon, 
  Rocket as RocketIcon, 
  AccessTime as AccessTimeIcon, 
  Security as SecurityIcon, 
  Pets as PetsIcon, 
  Terrain as TerrainIcon,
  Person as PersonIcon,
  ThumbUp as ThumbUpIcon,
  Assignment 
} from '@mui/icons-material';
import axios from 'axios';
import { SxProps, Theme } from '@mui/material/styles';

// Types
export interface StoryNode {
  image_url: string;
  scene_number?: number;
}

interface StoryData {
  id: number;
  title: string;
  current_node: StoryNode;
  current_scene: number;
  plays: number;
  likes: number;
  shares: number;
  cache_percentage: number;
  cover_image?: string;
  is_liked: boolean;
  is_saved: boolean;
  like_count: number;
  rating: number;
  num_ratings: number;
  comments: Array<{
    id: number;
    user?: {
      id: number;
      username: string;
    };
    text: string;
    created_at: string;
    rating: number;
  }>;
  nodes: StoryNode[];
}

export interface Story {
  id: number;
  title: string;
  current_node?: {
    scene_number?: number;
    image_url?: string;
  };
  context?: string;
  is_complete?: boolean;
  has_completed_survey?: boolean;
  
  // Optional additional fields
  story_modes?: string[];
  tags?: string[];
  education_level?: string;
  learning_mode?: string;
  is_educational?: boolean;
  rating?: number;
  plays?: number;
  shares?: number;
  like_count?: number;
  is_liked?: boolean;
  author?: string;
  complexity?: string;
}

interface StoryCardProps {
  story: Story;
  showUnlvAlert?: boolean;
  onClick?: (storyId: number) => void;
  onSurveyClick?: (e: React.MouseEvent, storyId: number) => void;
  onLikeClick?: (e: React.MouseEvent, storyId: number) => void;
  className?: string;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
}

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '280px',
  width: '100%',
  margin: '0 auto',
  transition: 'all 0.3s ease',
  borderRadius: '24px',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.palette.mode === 'dark' 
      ? '0 8px 24px rgba(0,0,0,0.3)' 
      : '0 8px 24px rgba(0,0,0,0.15)',
  },
  position: 'relative',
  overflow: 'hidden',
  minHeight: '180px',
  backgroundColor: 'none',
}));

const CardImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '56.25%', // 16:9 aspect ratio
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.1)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const CardOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, transparent 100%)',
  padding: theme.spacing(2),
  color: 'white',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

const ProgressDot = styled(Box)(({ theme }) => ({
  flex: 1,
  height: 6,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(255,255,255,0.2)',
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'scaleX(1)',
  }
}));

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const StoryCard: React.FC<StoryCardProps> = ({
  story: initialStory,
  showUnlvAlert = false,
  onClick,
  onSurveyClick,
  onLikeClick,
  className,
  style,
  sx
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const totalScenes = 8;
  const [story, setStory] = useState(initialStory);

  // Helper function to truncate title
  const truncateTitle = (title: string, maxLength: number = 30) => {
    if (title.length <= maxLength) return title;
    return `${title.substring(0, maxLength)}...`;
  };

  useEffect(() => {
    setStory(initialStory);
  }, [initialStory]);

  const handleLikeClick = async (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent card click
    if (onLikeClick) {
      onLikeClick(e, story.id);
    } else {
      try {
        const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${story.id}/like/`);
        setStory(prevStory => ({
          ...prevStory,
          is_liked: response.data.is_liked,
          like_count: response.data.like_count
        }));
      } catch (error) {
        console.error('Error liking story:', error);
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          navigate('/login', { state: { from: location.pathname } });
        }
      }
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick(story.id);
    } else {
      navigate(`/story/${story.id}`);
    }
  };

  const handleSurveyClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onSurveyClick) {
      onSurveyClick(e, story.id);
    }
  };

  const getProgressText = (sceneNumber?: number) => {
    if (!sceneNumber) return 'Just started';
    if (sceneNumber === 1) return 'Just started';
    if (sceneNumber === 2) return 'Recently started';
    if (sceneNumber === totalScenes) return 'Completed';
    return `Scene ${sceneNumber} of ${totalScenes}`;
  };

  // Helper function to get learning mode emoji
  const getLearningModeEmoji = (mode?: string) => {
    // Normalize the mode to lowercase for comparison
    const normalizedMode = mode?.toLowerCase();
    
    // Map of learning modes to emojis - only 3 modes
    const emojis: {[key: string]: string} = {
      'interactive_lecture': '🎓', // Graduation cap for interactive lecture
      'story_based': '📚', // Books for story-based
      'entertainment': '🎮', // Gaming controller for entertainment
      'default': '📖' // Open book for default
    };

    return emojis[normalizedMode || ''] || emojis['default'];
  };

  // Helper function to get story mode emoji
  const getStoryModeEmoji = (mode?: string) => {
    const emojis: {[key: string]: string} = {
      // Interactive Lecture Modes
      'socratic_discussion': '💭',
      'case_study': '🔍',
      'problem_based': '🧩',
      'demonstration': '🎯',
      'group_discussion': '👥',
      'visual_learning': '📊',
      'lecture': '📖',
      
      // Story Based Modes
      'magical_academy': '🧙‍♀️',
      'space_explorer': '🚀',
      'time_traveler': '⏰',
      'secret_agent': '🕵️',
      'monster_tamer': '🐉',
      'ancient_ruins': '🏺',
      'jungle_explorer': '🌴',
      'ocean_voyager': '🌊',
      'inventors_workshop': '⚡',
      
      // Entertainment Modes
      'adventure': '🗺️',
      'mystery': '🔍',
      'fantasy': '🐉',
      'sci_fi': '🚀',
      'horror': '👻',
      'romance': '💝',
      'comedy': '😄',
      'slice_of_life': '🌅',
      
      'default': '📖'
    };
    return emojis[mode || 'default'];
  };

  return (
    <StyledCard 
      onClick={handleClick}
      className={className}
      sx={sx}
      style={{
        ...style,
        background: 'none',
      }}
    >
      {/* Background Color/Image Overlay */}
      {story.current_node?.image_url ? (
        <Box
          component="img"
          src={story.current_node.image_url}
          alt=""
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0.9,
            mixBlendMode: 'normal'
          }}
          onError={() => {
            // If image fails to load, force a re-render to show the fallback color
            setStory(prev => ({...prev, current_node: {...prev.current_node, image_url: ''}}));
          }}
        />
      ) : (
        <Box
          role="presentation"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.mode === 'dark' 
              ? 'rgba(55, 65, 81, 0.9)'
              : 'rgba(229, 231, 235, 0.9)',
          }}
        />
      )}
      
      {/* Content Section */}
      <Box 
        sx={{ 
          position: 'relative', 
          zIndex: 2, 
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          background: theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 100%)'
            : 'linear-gradient(180deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.2) 100%)',
          borderRadius: '24px'
        }}
      >
        {/* Top Row: Title and Icons */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Typography 
            variant="h5" 
            component="div" 
            sx={{ 
              fontWeight: 'bold', 
              flex: 1,
              color: theme.palette.mode === 'dark' ? 'white' : 'text.primary',
              textShadow: theme.palette.mode === 'dark' 
                ? '2px 2px 4px rgba(0,0,0,0.9)' 
                : '2px 2px 4px rgba(0,0,0,0.4)' // Stronger text shadow for better readability
            }}
          >
            <Tooltip title={story.title.length > 30 ? story.title : ""} placement="top">
              <span>{truncateTitle(story.title)}</span>
            </Tooltip>
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {story.learning_mode && (
              <Tooltip title={`Learning Mode: ${story.learning_mode}`}>
                <Typography variant="h6">
                  {getLearningModeEmoji(story.learning_mode)}
                </Typography>
              </Tooltip>
            )}
            {story.story_modes && story.story_modes[0] && (
              <Tooltip title={`Story Mode: ${story.story_modes[0]}`}>
                <Typography variant="h6">
                  {getStoryModeEmoji(story.story_modes[0])}
                </Typography>
              </Tooltip>
            )}
          </Box>
        </Box>

        {/* Tags */}
        {story.tags && story.tags.length > 0 && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {story.tags.slice(0, 2).map((tag, index) => (
              <Chip 
                key={index} 
                label={tag} 
                size="small" 
                variant="outlined"
                sx={{ 
                  backgroundColor: theme.palette.mode === 'dark'
                    ? 'rgba(255,255,255,0.1)'
                    : 'rgba(0,0,0,0.05)',
                  color: theme.palette.mode === 'dark' ? 'white' : 'text.primary',
                  borderColor: theme.palette.mode === 'dark'
                    ? 'rgba(255,255,255,0.3)'
                    : 'rgba(0,0,0,0.2)',
                  textShadow: theme.palette.mode === 'dark'
                    ? '0 1px 2px rgba(0,0,0,0.3)'
                    : 'none'
                }}
              />
            ))}
          </Box>
        )}

        {/* Stats and Progress Section */}
        <Box sx={{ mt: 'auto' }}>
          {/* Stats */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2,
            mb: 2
          }}>
            <Typography 
              variant="caption" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 0.5,
                color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'text.secondary'
              }}
            >
              <StarIcon sx={{ fontSize: 16 }} /> {story.rating || 0}
            </Typography>
            <Typography 
              variant="caption" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 0.5,
                color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'text.secondary'
              }}
            >
              <PersonIcon sx={{ fontSize: 16 }} /> {story.plays || 0}
            </Typography>
            <Box
              onClick={handleLikeClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                cursor: 'pointer',
                color: story.is_liked ? 'primary.main' : (theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'text.secondary'),
                '&:hover': {
                  color: 'primary.main'
                }
              }}
            >
              <ThumbUpIcon 
                sx={{ 
                  fontSize: 16,
                  transform: story.is_liked ? 'scale(1.1)' : 'scale(1)',
                  transition: 'transform 0.2s ease'
                }} 
              />
              <Typography variant="caption">
                {story.like_count || 0}
              </Typography>
            </Box>
          </Box>

          {/* Progress */}
          <Typography 
            variant="caption" 
            sx={{ 
              color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'text.secondary',
              display: 'block',
              mb: 0.25,
            }}
          >
            {getProgressText(story.current_node?.scene_number)}
          </Typography>

          <Box sx={{ display: 'flex', gap: '2px', width: '100%' }}>
            {[...Array(totalScenes)].map((_, index) => (
              <ProgressDot
                key={index}
                sx={{
                  '&::after': {
                    backgroundColor: index < (story.current_node?.scene_number || 1)
                      ? (story.current_node?.scene_number === totalScenes 
                          ? theme.palette.success.main 
                          : '#FF4F00')
                      : 'transparent',
                  }
                }}
              />
            ))}
          </Box>
        </Box>

        {/* Survey Button */}
        {showUnlvAlert && story.is_complete && !story.has_completed_survey && (
          <Box sx={{ mt: 2 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSurveyClick}
              startIcon={<Assignment />}
              size="small"
            >
              Complete Survey
            </Button>
          </Box>
        )}
      </Box>
    </StyledCard>
  );
};

export default StoryCard;
