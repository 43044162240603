import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  TextField, 
  Grid, 
  Snackbar, 
  Alert, 
  Paper, 
  Avatar, 
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  CardActionArea
} from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import api from '../api/axios';
import { API_BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

interface Story {
  id: number;
  title: string;
  cover_image?: string;
  plays: number;
  created_at: string;
}

interface UserProfile {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_unlv_student?: boolean;
  date_joined?: string;
  stories_count?: number;
  total_plays?: number;
}

const ProfileInfo: React.FC = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState<UserProfile>({ 
    username: '', 
    email: '', 
    first_name: '', 
    last_name: '',
    is_unlv_student: false,
    date_joined: '',
    stories_count: 0,
    total_plays: 0
  });
  const [userStories, setUserStories] = useState<Story[]>([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  useEffect(() => {
    fetchProfile();
    fetchUserStories();
  }, []);

  const fetchProfile = async () => {
    setLoading(true);
    try {
      const [profileRes, statsRes] = await Promise.all([
        api.get(`${API_BASE_URL}/accounts/user/`),
        api.get(`${API_BASE_URL}/stories/user_stats/`)
      ]);
      
      console.log('Profile Response:', profileRes.data);
      console.log('Stats Response:', statsRes.data);
      
      setProfile({
        ...profileRes.data,
        ...statsRes.data
      });
    } catch (error) {
      console.error('Error fetching profile:', error);
      showSnackbar('Failed to fetch profile', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserStories = async () => {
    try {
      const response = await api.get(`${API_BASE_URL}/stories/personal_library/`);
      console.log('User Stories Response:', response.data);
      setUserStories(response.data);
    } catch (error) {
      console.error('Error fetching user stories:', error);
      showSnackbar('Failed to fetch stories', 'error');
    }
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const response = await api.put(`${API_BASE_URL}/accounts/profile/`, {
        email: profile.email,
        first_name: profile.first_name,
        last_name: profile.last_name
      });

      console.log('Save Response:', response.data);
      
      setProfile(prev => ({ ...prev, ...response.data }));
      setEditing(false);
      showSnackbar('Profile updated successfully', 'success');
    } catch (error) {
      console.error('Error updating profile:', error);
      showSnackbar('Failed to update profile', 'error');
    } finally {
      setSaving(false);
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Grid container spacing={4}>
          {/* Profile Stats */}
          <Grid item xs={12}>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <Avatar
                sx={{ 
                  width: 100, 
                  height: 100, 
                  mx: 'auto', 
                  mb: 2,
                  bgcolor: 'primary.main'
                }}
              >
                {profile.first_name?.[0] || profile.username?.[0]?.toUpperCase()}
              </Avatar>
              <Typography variant="h5" gutterBottom>
                {profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Member since {new Date(profile.date_joined || '').toLocaleDateString()}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mt: 2 }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6">{profile.stories_count}</Typography>
                  <Typography variant="body2" color="text.secondary">Stories</Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6">{profile.total_plays}</Typography>
                  <Typography variant="body2" color="text.secondary">Total Plays</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Profile Form */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Username"
                  value={profile.username}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  value={profile.first_name}
                  disabled={!editing}
                  onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  value={profile.last_name}
                  disabled={!editing}
                  onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  value={profile.email}
                  disabled={!editing}
                  onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              {profile.is_unlv_student && (
                <Grid item xs={12}>
                  <Alert severity="info">
                    Verified UNLV Student Account
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                  {!editing ? (
                    <Button
                      variant="contained"
                      startIcon={<EditIcon />}
                      onClick={() => setEditing(true)}
                    >
                      Edit Profile
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => setEditing(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        disabled={saving}
                      >
                        {saving ? 'Saving...' : 'Save Changes'}
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* User Stories Section */}
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>
          My Stories
        </Typography>
        {userStories.length === 0 ? (
          <Typography variant="body1" color="text.secondary" textAlign="center">
            You haven't created any stories yet.
          </Typography>
        ) : (
          <Box>
            {/* Header */}
            <Grid container sx={{ 
              borderBottom: 1, 
              borderColor: 'divider',
              bgcolor: '#f5f5f5',
              py: 1,
              px: 2,
            }}>
              <Grid item xs={4}>
                <Typography component="span" sx={{ fontWeight: 600, color: '#000000', bgcolor: 'transparent' }}>
                  Title
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography component="span" sx={{ fontWeight: 600, color: '#000000', bgcolor: 'transparent' }}>
                  Created
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography component="span" sx={{ fontWeight: 600, color: '#000000', bgcolor: 'transparent' }}>
                  Plays
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography component="span" sx={{ fontWeight: 600, color: '#000000', bgcolor: 'transparent' }}>
                  Actions
                </Typography>
              </Grid>
            </Grid>
            
            {/* Story Rows */}
            {userStories.map((story) => (
              <Grid 
                container 
                key={story.id} 
                sx={{ 
                  borderBottom: 1, 
                  borderColor: 'divider',
                  py: 2,
                  px: 2,
                  '&:hover': {
                    bgcolor: 'grey.50'
                  }
                }}
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {story.cover_image && (
                      <Box
                        component="img"
                        src={story.cover_image}
                        alt={story.title}
                        sx={{ 
                          width: 40, 
                          height: 40, 
                          objectFit: 'cover',
                          borderRadius: 1
                        }}
                      />
                    )}
                    <Typography variant="body1" noWrap>
                      {story.title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    {new Date(story.created_at).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" color="text.secondary">
                    {story.plays}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => navigate(`/stories/${story.id}`)}
                  >
                    View Story
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfileInfo;