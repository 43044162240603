import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { 
  Box, Typography, Grid, Card, CardContent, CardActionArea, CardMedia,
  CircularProgress, Alert, Button, Chip, Snackbar, Container,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlayArrow, Favorite } from '@mui/icons-material';
import axios from 'axios';
import StoryCard from './StoryCard';

interface Story {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  cover_image?: string;
  current_node?: {
    image_url?: string;
    scene_number?: number;
  };
}

interface ClassDetails {
  id: number;
  name: string;
  description: string;
  class_code: string;
  is_public: boolean;
  owner: string;
  students_count: number;
  stories: Story[];
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
// Use a simple placeholder image as fallback
const FALLBACK_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgZmlsbD0iI2VlZSIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBmb250LWZhbWlseT0iQXJpYWwiIGZvbnQtc2l6ZT0iMjQiIGZpbGw9IiM5OTkiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGRvbWluYW50LWJhc2VsaW5lPSJtaWRkbGUiPk5vIGltYWdlIGF2YWlsYWJsZTwvdGV4dD48L3N2Zz4=';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  }
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  height: 200,
  backgroundSize: 'cover',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
  }
}));

const ClassDetail: React.FC = () => {
  const theme = useTheme();
  const { classId } = useParams<{ classId: string }>();
  const navigate = useNavigate();
  const [classDetails, setClassDetails] = useState<ClassDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'info' | 'warning';
  }>({ open: false, message: '', severity: 'success' });

  const getImageUrl = useCallback((story: Story): string => {
    // Check for valid story cover image
    if (story.cover_image) {
      if (story.cover_image.startsWith('http')) return story.cover_image;
      if (story.cover_image.startsWith('data:')) return story.cover_image;
      return `${S3_BUCKET_URL}/${story.cover_image}`;
    }

    // Check for valid story node image
    if (story.current_node?.image_url) {
      if (story.current_node.image_url.startsWith('http')) return story.current_node.image_url;
      if (story.current_node.image_url.startsWith('data:')) return story.current_node.image_url;
      return `${S3_BUCKET_URL}/${story.current_node.image_url}`;
    }

    // Return embedded fallback image if no valid image is found
    return FALLBACK_IMAGE;
  }, []);

  const fetchClassDetails = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<ClassDetails>(`${API_BASE_URL}/classes/${classId}/`);
      setClassDetails(response.data);
    } catch (error) {
      console.error('Error fetching class details:', error);
      setError('Failed to fetch class details. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [classId]);

  useEffect(() => {
    fetchClassDetails();
  }, [fetchClassDetails]);

  const handleEnroll = async () => {
    try {
      await axios.post(`${API_BASE_URL}/classes/${classId}/enroll/`);
      setSnackbar({ 
        open: true, 
        message: 'Successfully enrolled in class!', 
        severity: 'success' 
      });
      fetchClassDetails();
    } catch (error: any) {
      console.error('Error enrolling in class:', error);
      let errorMessage = 'Failed to enroll in class.';
      
      if (error.response) {
        // Handle specific error cases from the backend
        switch (error.response.status) {
          case 400:
            errorMessage = error.response.data.detail || 'Invalid enrollment request.';
            break;
          case 401:
            errorMessage = 'Please log in to enroll in this class.';
            break;
          case 403:
            errorMessage = 'You do not have permission to enroll in this class.';
            break;
          case 404:
            errorMessage = 'This class no longer exists.';
            break;
          case 409:
            errorMessage = 'You are already enrolled in this class.';
            break;
          default:
            errorMessage = 'An unexpected error occurred. Please try again later.';
        }
      }
      
      setSnackbar({ 
        open: true, 
        message: errorMessage, 
        severity: 'error' 
      });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !classDetails) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="error">{error || 'Class not found'}</Alert>
        <Button component={Link} to="/classes" sx={{ mt: 2 }}>
          Back to Classes
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ pt: 10 }}>
      <Container maxWidth="lg">
        <Button 
          onClick={() => navigate(-1)} 
          sx={{ 
            mb: 2,
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          Back to Classes
        </Button>
        
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
          {classDetails.name}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, gap: 2 }}>
          <Chip 
            label={classDetails.is_public ? "Public" : "Private"} 
            color={classDetails.is_public ? "success" : "default"}
            sx={{ borderRadius: '8px' }}
          />
          <Typography variant="body1" sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            padding: '8px 16px',
            borderRadius: '8px',
            fontFamily: 'monospace'
          }}>
            Class Code: {classDetails.class_code}
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleEnroll}
            sx={{ 
              ml: 'auto',
              borderRadius: '8px',
              background: 'linear-gradient(45deg, #2196F3, #1976D2)',
              color: '#fff',
              '&:hover': {
                background: 'linear-gradient(45deg, #1E88E5, #1565C0)',
                color: '#fff'
              }
            }}
          >
            Enroll
          </Button>
        </Box>

        <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
          {classDetails.description}
        </Typography>

        <Box sx={{ 
          mb: 4, 
          p: 2, 
          borderRadius: '12px',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          display: 'flex',
          gap: 4
        }}>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            Owner: <span style={{ color: 'text.primary', fontWeight: 500 }}>{classDetails.owner}</span>
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            Students: <span style={{ color: 'text.primary', fontWeight: 500 }}>{classDetails.students_count}</span>
          </Typography>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
          Stories in this Class
        </Typography>

        {classDetails.stories.length === 0 ? (
          <Box sx={{ 
            p: 4, 
            textAlign: 'center',
            borderRadius: '12px',
            backgroundColor: 'rgba(255, 255, 255, 0.05)'
          }}>
            <Typography variant="h6" sx={{ color: 'text.secondary', mb: 2 }}>
              No stories found in this class
            </Typography>
            <Button 
              variant="contained"
              component={Link}
              to="/new"
              sx={{ 
                borderRadius: '8px',
                background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #FF9500, #FF6000)',
                }
              }}
            >
              Create First Story
            </Button>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {classDetails.stories.map((story) => (
              <Grid item xs={12} sm={6} md={4} key={story.id}>
                <StoryCard
                  story={{
                    ...story,
                    current_node: {
                      ...story.current_node,
                      image_url: story.current_node?.image_url || story.cover_image
                    }
                  }}
                  showUnlvAlert={false}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default ClassDetail;