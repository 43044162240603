import React from 'react';
import {
  Grid,
  Box,
  Typography,
  useTheme
} from '@mui/material';
import StoryModeCard from './StoryModeCard';

interface StoryModeSelectorProps {
  selectedMode: string;
  onSelect: (modeId: string) => void;
}

export const STORY_MODES = [
  {
    id: 'magical_academy',
    name: 'Magical Academy',
    description: 'Learn through magical adventures and spellbinding lessons',
    icon: '🏰',
    subjects: ['Magic', 'Adventure', 'Mystery'],
    learningStyle: 'story_based'
  },
  {
    id: 'space_explorer',
    name: 'Space Explorer',
    description: 'Discover knowledge across the cosmos',
    icon: '🚀',
    subjects: ['Science', 'Exploration', 'Discovery'],
    learningStyle: 'story_based'
  },
  {
    id: 'time_traveler',
    name: 'Time Traveler',
    description: 'Journey through history and learn from the past',
    icon: '⏰',
    subjects: ['History', 'Culture', 'Change'],
    learningStyle: 'story_based'
  },
  {
    id: 'secret_agent',
    name: 'Secret Agent',
    description: 'Solve mysteries and crack educational codes',
    icon: '🕵️',
    subjects: ['Logic', 'Problem-Solving', 'Investigation'],
    learningStyle: 'story_based'
  },
  {
    id: 'pirate_adventure',
    name: 'Pirate Adventure',
    description: 'Navigate the seas of knowledge through maritime exploration',
    icon: '🏴‍☠️',
    subjects: ['Navigation', 'History', 'Trade'],
    learningStyle: 'story_based'
  },
  {
    id: 'ancient_ruins',
    name: 'Ancient Ruins',
    description: 'Explore ancient knowledge and lost wisdom',
    icon: '🏛️',
    subjects: ['Archaeology', 'History', 'Mystery'],
    learningStyle: 'story_based'
  },
  {
    id: 'jungle_explorer',
    name: 'Jungle Explorer',
    description: 'Learn about nature and ecosystems in wild adventures',
    icon: '🌴',
    subjects: ['Biology', 'Environment', 'Survival'],
    learningStyle: 'story_based'
  },
  {
    id: 'ocean_voyager',
    name: 'Ocean Voyager',
    description: 'Dive deep into marine science and underwater mysteries',
    icon: '🌊',
    subjects: ['Marine Biology', 'Oceanography', 'Conservation'],
    learningStyle: 'story_based'
  },
  {
    id: 'inventors_workshop',
    name: "Inventor's Workshop",
    description: 'Create, innovate, and learn through invention challenges',
    icon: '⚡',
    subjects: ['Engineering', 'Innovation', 'Design'],
    learningStyle: 'story_based'
  }
];

const StoryModeSelector: React.FC<StoryModeSelectorProps> = ({ selectedMode, onSelect }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%', py: 2 }}>
      <Typography 
        variant="h5" 
        component="h2" 
        gutterBottom 
        sx={{ 
          textAlign: 'center',
          mb: 4,
          color: theme.palette.primary.main,
          fontWeight: 'bold'
        }}
      >
        Choose Your Story Mode
      </Typography>
      <Grid container spacing={3}>
        {STORY_MODES.map((mode) => (
          <Grid item xs={12} sm={6} md={4} key={mode.id}>
            <StoryModeCard
              mode={mode}
              selected={selectedMode === mode.id}
              onSelect={onSelect}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StoryModeSelector;
