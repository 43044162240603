import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Chip,
    Avatar,
    CircularProgress,
    Alert,
    Stack,
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { styled, alpha } from '@mui/material/styles';
import {
    Create,
    Explore,
    TrendingUp,
    School,
    People,
    MenuBook,
    KeyboardArrowDown,
    PeopleAlt,
    MenuBook as MenuBookIcon,
    PlayArrow,
    Search as SearchIcon,
} from "@mui/icons-material";
import WhyStorybyte from './WhyStorybyte';
import RoadmapSection from './RoadmapSection';
import axios from 'axios';
import scene1Image from '../assets/scene1.png';
import scene2Image from '../assets/scene2.png';
import scene3Image from '../assets/scene3.png';
import scene4Image from '../assets/scene4.png';
import scene5Image from '../assets/scene5.png';
import scene6Image from '../assets/scene6.png';
import scene7Image from '../assets/scene7.png';
import scene8Image from '../assets/scene8.png';
import revolution1Image from '../assets/revolution1.webp';
import resistanceImage from '../assets/resistance.webp';
import declarationImage from '../assets/declaration.webp';
import membraneImage from '../assets/membrane.jpg';
import mitochondriaImage from '../assets/mitochondria.jpg';
import proteinImage from '../assets/protein.webp';
import bstImage from '../assets/bst.png';
import graphImage from '../assets/graph.png';
import hashImage from '../assets/hash.webp';
import { GradientBackground } from '../styles/shared';
import { StoryPreview } from '../types';

const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';
const API_BASE_URL = 'https://api.storybyte.com';

interface ClassPreview {
  id: number;
  name: string;
  description: string;
  students_count: number;
  stories_count: number;
  owner: string;
}

interface HeroImageProps {
  src: string | undefined | null;
  alt: string;
  className?: string;
}

interface HeroImageState {
  currentIndex: number;
  nextIndex: number;
  isTransitioning: boolean;
}

interface LandingPageProps {
  validImageStories: StoryPreview[];
  stories: StoryPreview[];
  popularClasses: ClassPreview[];
  currentImageIndex: number;
  getImageUrl: (url: string) => string;
  loading: boolean;
  error: string | null;
  FALLBACK_IMAGE: string;
  totalStoriesCreated: number;
  totalScenesPlayed: number;
}

const keyframes = {
    '@keyframes fadeInUp': {
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' }
    },
    '@keyframes gradientRotate': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' }
    },
    '@keyframes gradientMove': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '100%': { backgroundPosition: '0% 50%' }
    },
    '@keyframes scrollDown': {
        '0%': { transform: 'translateY(0)' },
        '50%': { transform: 'translateY(10px)' },
        '100%': { transform: 'translateY(0)' }
    },
    '@keyframes pulseGlow': {
        '0%': {
            transform: 'translate(-50%, -50%) scale(1)',
            opacity: 0.5
        },
        '50%': {
            transform: 'translate(-50%, -50%) scale(1.2)',
            opacity: 0.3
        },
        '100%': {
            transform: 'translate(-50%, -50%) scale(1)',
            opacity: 0.5
        }
    },
    '@keyframes gradient': {
        '0%': {
            backgroundPosition: '0% 50%',
            transform: 'rotate(0deg)'
        },
        '50%': {
            backgroundPosition: '100% 50%',
            transform: 'rotate(180deg)'
        },
        '100%': {
            backgroundPosition: '0% 50%',
            transform: 'rotate(360deg)'
        }
    },
};

const SECTION_SPACING = { xs: 4, md: 6 };
const CARD_SPACING = 3;
const CONTENT_PADDING = { xs: 2, sm: 3, md: 4 };
const ROTATION_INTERVAL = 8000;
const FADE_DURATION = 1000;

const cardShadow = '0 4px 20px rgba(0,0,0,0.12)';
const cardHoverShadow = '0 6px 25px rgba(0,0,0,0.2)';

const HeroImage: React.FC<HeroImageProps> = ({
    src,
    alt,
    className
}) => {
    const [imgSrc, setImgSrc] = useState<string | null>(() => {
        const url = src;
        if (!url) return null;

        if (url.startsWith('http')) return url;
        if (url.startsWith('data:')) return url;
        if (url.startsWith('/static/')) return url;

        let normalizedUrl = url;
        if (url.startsWith('/')) {
            normalizedUrl = url.substring(1);
        }

        if (!normalizedUrl.startsWith('media/')) {
            normalizedUrl = `media/${normalizedUrl}`;
        }

        return `${S3_BUCKET_URL}/${normalizedUrl}`;
    });

    useEffect(() => {
        const normalizeUrl = (rawUrl: string | null | undefined) => {
            if (!rawUrl) return null;

            if (rawUrl.startsWith('http')) return rawUrl;
            if (rawUrl.startsWith('data:')) return rawUrl;
            if (rawUrl.startsWith('/static/')) return rawUrl;

            let normalized = rawUrl;
            if (rawUrl.startsWith('/')) {
                normalized = rawUrl.substring(1);
            }

            if (!normalized.startsWith('media/')) {
                normalized = `media/${normalized}`;
            }

            return `${S3_BUCKET_URL}/${normalized}`;
        };

        setImgSrc(normalizeUrl(src));
    }, [src]);

    if (!imgSrc) return null;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                transition: 'opacity 0.5s ease-in-out'
            }}
            className={className}
        />
    );
};

const StaticImageWithFallback: React.FC<{
    src: string | undefined | null;
    alt: string;
    className?: string;
}> = ({ src, alt, className }) => {
    const [imgSrc, setImgSrc] = useState(() => {
        const url = src;
        if (!url) return FALLBACK_IMAGE;

        if (url.startsWith('http')) return url;
        if (url.startsWith('data:')) return url;
        if (url.startsWith('/static/')) return url;

        let normalizedUrl = url;
        if (url.startsWith('/')) {
            normalizedUrl = url.substring(1);
        }

        if (!normalizedUrl.startsWith('media/')) {
            normalizedUrl = `media/${normalizedUrl}`;
        }

        return `${S3_BUCKET_URL}/${normalizedUrl}`;
    });

    return (
        <Box
            component="img"
            src={imgSrc}
            alt={alt}
            className={className}
            onError={() => setImgSrc(FALLBACK_IMAGE)}
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'opacity 0.3s ease',
            }}
        />
    );
};

const StoryCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  maxWidth: '320px',
  width: '100%',
  background: theme.palette.mode === 'dark'
    ? 'rgba(38,38,38,0.9)'
    : 'rgba(255,255,255,0.9)',
  backdropFilter: 'blur(10px)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0,0,0,0.2)'
    : '0 4px 20px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 6px 25px rgba(0,0,0,0.3)'
      : '0 6px 25px rgba(0,0,0,0.15)',
  }
}));

const StoryImage = styled('div')({
  height: 140,
  overflow: 'hidden',
  position: 'relative',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  '&:hover img': {
    transform: 'scale(1.05)',
  }
});

const ClassCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  maxWidth: '320px',
  width: '100%',
  background: theme.palette.mode === 'dark'
    ? 'rgba(38,38,38,0.9)'
    : 'rgba(255,255,255,0.9)',
  backdropFilter: 'blur(10px)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0,0,0,0.2)'
    : '0 4px 20px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 6px 25px rgba(0,0,0,0.3)'
      : '0 6px 25px rgba(0,0,0,0.15)',
  }
}));

const StoryExampleCard = styled(Card)(({ theme }) => ({
  maxWidth: '800px',
  width: '100%',
  margin: '0 auto',
  background: theme.palette.mode === 'dark'
    ? 'rgba(38,38,38,0.95)'
    : 'rgba(255,255,255,0.95)',
  backdropFilter: 'blur(20px)',
  borderRadius: '24px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 30px rgba(0,0,0,0.3)'
    : '0 4px 30px rgba(0,0,0,0.15)',
  position: 'relative',
  overflow: 'hidden',
  transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark'
      ? '0 8px 40px rgba(0,0,0,0.4)'
      : '0 8px 40px rgba(0,0,0,0.2)',
    transform: 'translateY(-2px)',
  }
}));

const StoryExampleImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '250px', 
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const StoryText = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem', 
  lineHeight: 1.4, 
  marginBottom: '6px', 
  padding: '8px 16px', 
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.87)',
}));

const StoryContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '90%', // Added width constraint
  maxWidth: '650px', // Added max-width
  height: '700px',
  margin: '0 auto', // Center the container
  backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 8px 32px rgba(0, 0, 0, 0.4)'
    : '0 8px 32px rgba(0, 0, 0, 0.1)',
}));

const ChoicesStack = styled(Stack)(({ theme }) => ({
  gap: '6px', 
  width: '95%', // Reduced from 100%
  maxWidth: '550px', // Reduced from 600px
  margin: '0 auto',
}));

const ChoiceButton = styled(Button)<{ $selected?: boolean }>(({ theme, $selected }) => ({
  padding: '4px 10px', 
  borderRadius: '6px', 
  textTransform: 'none',
  fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI", Roboto, sans-serif',
  fontSize: '0.85rem', 
  minHeight: 'unset', 
  height: 'auto', 
  lineHeight: '1.3', 
  backgroundColor: $selected 
    ? alpha(theme.palette.primary.main, 0.15)
    : theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.03)'
      : 'rgba(0,0,0,0.02)',
  border: `1px solid ${
    $selected 
      ? alpha(theme.palette.primary.main, 0.3)
      : theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.1)'
        : 'rgba(0,0,0,0.08)'
  }`,
  '&:hover': {
    backgroundColor: $selected 
      ? alpha(theme.palette.primary.main, 0.2)
      : theme.palette.mode === 'dark' 
        ? 'rgba(255,255,255,0.05)'
        : 'rgba(0,0,0,0.04)',
  },
}));

const SlideTransition = styled('div')<{ $direction: 'left' | 'right', $show: boolean }>(
  ({ $direction, $show }) => ({
    opacity: $show ? 1 : 0,
    transform: $show 
      ? 'translateX(0) scale(1)' 
      : `translateX(${$direction === 'left' ? '-20px' : '20px'}) scale(0.98)`,
    transition: 'opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1), transform 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  })
);

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(135deg, 
    ${theme.palette.mode === 'dark' ? '#64b5f6' : '#1976d2'} 0%, 
    ${theme.palette.mode === 'dark' ? '#ff9800' : '#f57c00'} 100%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  cursor: 'pointer',
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(145deg, rgba(30,30,30,0.9), rgba(20,20,20,0.9))'
    : 'linear-gradient(145deg, rgba(255,255,255,0.9), rgba(240,240,240,0.9))',
  backdropFilter: 'blur(10px)',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 8px 25px rgba(0,0,0,0.3)'
      : '0 8px 25px rgba(0,0,0,0.1)',
  },
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
  },
}));

const StatItem = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  minWidth: 160,
  background: theme.palette.mode === 'dark'
    ? 'rgba(255,255,255,0.05)'
    : 'rgba(0,0,0,0.02)',
  borderRadius: theme.spacing(2),
  backdropFilter: 'blur(10px)',
  flex: '0 0 auto',
}));

const MainContainer = styled(GradientBackground)(({ theme }) => ({
  // Any additional styles specific to the landing page can go here
}));

const LandingPage: React.FC<LandingPageProps> = ({
  validImageStories,
  stories,
  popularClasses,
  currentImageIndex,
  getImageUrl,
  loading,
  error,
  FALLBACK_IMAGE,
  totalStoriesCreated,
  totalScenesPlayed
}) => {
  const navigate = useNavigate();
  const [popularClassesState, setPopularClasses] = useState<ClassPreview[]>([]);
  const [imageState, setImageState] = useState<HeroImageState>({
    currentIndex: 0,
    nextIndex: 1,
    isTransitioning: false
  });

  useEffect(() => {
    const fetchPopularContent = async () => {
      setPopularClasses(popularClasses);
    };

    fetchPopularContent();
  }, [popularClasses]);

  useEffect(() => {
    if (!validImageStories.length) return;

    const rotateImage = () => {
      setImageState(prev => ({
        currentIndex: prev.nextIndex,
        nextIndex: (prev.nextIndex + 1) % validImageStories.length,
        isTransitioning: true
      }));

      setTimeout(() => {
        setImageState(prev => ({
          ...prev,
          isTransitioning: false
        }));
      }, FADE_DURATION);
    };

    const intervalId = setInterval(rotateImage, ROTATION_INTERVAL);
    return () => clearInterval(intervalId);
  }, [validImageStories.length]);

  const handleScrollDown = () => {
    const nextSection = document.getElementById('whyStorybyte');
    if (nextSection) {
      nextSection.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const [currentScene, setCurrentScene] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('left');
  const [selectedChoice, setSelectedChoice] = useState<number | null>(null);
  const [demoStep, setDemoStep] = useState(0);

  // Story Topics
  const storyTopics = {
    mars: {
      name: "Space Exploration",
      description: "Journey to Mars",
      scenes: {
        1: {
          title: "Space Exploration: Journey to Mars",
          image: scene1Image,
          content: "As we explore the challenges of Mars colonization, we must consider the crucial aspects of human survival on the Red Planet. The atmospheric composition, radiation levels, and resource utilization all play vital roles in establishing a sustainable presence. What aspect should we analyze first?",
          choices: [
            "Analyze Mars' atmospheric composition and its implications for life support systems",
            "Study radiation shielding technologies and their effectiveness on Mars",
            "Explore methods of extracting and processing Mars' resources for survival",
            "Examine the psychological effects of long-term Mars habitation"
          ],
          nextChoice: 0
        },
        2: {
          title: "Life Support Systems on Mars",
          image: scene2Image,
          content: "The Martian atmosphere is 95% carbon dioxide, presenting unique challenges for oxygen generation. Through atmospheric processing, we can produce breathable oxygen. Current technologies like MOXIE (Mars Oxygen In-Situ Resource Utilization Experiment) show promising results. Which aspect of life support systems should we focus on?",
          choices: [
            "Explore oxygen generation through CO2 electrolysis",
            "Analyze water extraction from Mars' polar ice caps",
            "Study closed-loop ecological life support systems",
            "Investigate pressure regulation in Martian habitats"
          ],
          nextChoice: 2
        },
        3: {
          title: "Ecological Life Support Systems",
          image: scene3Image,
          content: "Closed-loop ecological life support systems (ECLSS) are crucial for long-term Mars habitation. These systems recycle air and water, grow food, and manage waste. The success of such systems on Earth, like in Biosphere 2, provides valuable insights. Which component should we develop first?",
          choices: [
            "Design hydroponic farming systems for food production",
            "Develop water recycling and purification methods",
            "Create waste management and composting systems",
            "Implement air filtration and quality control measures"
          ],
          nextChoice: 0
        },
        4: {
          title: "Martian Hydroponics",
          image: scene4Image,
          content: "Hydroponic farming in Martian habitats allows us to grow fresh food without soil, using nutrient-rich water solutions. This efficient method conserves water and space while providing essential nutrition for colonists. What crops should we prioritize?",
          choices: [
            "Fast-growing leafy greens for immediate nutrition",
            "High-calorie root vegetables for sustenance",
            "Protein-rich legumes for dietary balance",
            "Multi-purpose crops for food and oxygen production"
          ],
          nextChoice: 3
        },
        5: {
          title: "Multi-Purpose Agriculture",
          image: scene5Image,
          content: "Multi-purpose crops like bamboo and algae can serve multiple functions in our Mars colony. They produce oxygen, provide food, and can be used as construction materials or biofuel. How should we integrate these versatile organisms into our habitat?",
          choices: [
            "Create vertical farming systems along habitat walls",
            "Establish dedicated algae bioreactors",
            "Design modular growing units for different purposes",
            "Develop hybrid systems combining multiple species"
          ],
          nextChoice: 1
        },
        6: {
          title: "Radiation Protection Systems",
          image: scene6Image,
          content: "Protecting Mars colonists from harmful radiation is crucial for long-term survival. We need to develop effective shielding systems that can be integrated into habitat designs and spacesuits. Which radiation protection strategy should we implement first?",
          choices: [
            "Design multi-layered habitat walls with radiation-absorbing materials",
            "Develop advanced spacesuit materials with enhanced radiation protection",
            "Create underground living spaces using Martian regolith",
            "Implement electromagnetic radiation shields around key areas"
          ],
          nextChoice: 2
        },
        7: {
          title: "Psychological Support Systems",
          image: scene7Image,
          content: "The psychological well-being of Mars colonists is essential for mission success. Extended isolation, confined spaces, and distance from Earth create unique mental health challenges. How should we address these psychological needs?",
          choices: [
            "Create virtual reality systems for Earth-like experiences",
            "Design communal spaces that promote social interaction",
            "Implement regular group activities and team-building exercises",
            "Develop AI companions for emotional support"
          ],
          nextChoice: 0
        },
        8: {
          title: "Emergency Response Protocols",
          image: scene8Image,
          content: "Emergency situations on Mars require quick, effective responses with limited resources. We need to establish robust protocols for various scenarios while maintaining crew safety. Which emergency system should we prioritize?",
          choices: [
            "Develop rapid habitat breach detection and repair systems",
            "Create backup life support system protocols",
            "Design emergency evacuation procedures",
            "Implement medical emergency response systems"
          ],
          nextChoice: 1
        }
      }
    },
    history: {
      name: "US History",
      description: "American Revolution",
      scenes: {
        1: {
          title: "The Road to Revolution",
          image: revolution1Image,
          content: "In 1775, tensions between the American colonies and British rule reached a breaking point. Colonial leaders faced crucial decisions that would shape the future of a nation. What aspect of the revolution should we explore?",
          choices: [
            "Examine the economic tensions and taxation policies",
            "Study the formation of the Continental Congress",
            "Analyze the role of colonial militias",
            "Explore the influence of Enlightenment ideas"
          ],
          nextChoice: 0
        },
        2: {
          title: "Colonial Resistance",
          image: resistanceImage,
          content: "The British Parliament's taxation policies sparked widespread resistance in the colonies. From the Boston Tea Party to the Continental Congress, colonists organized their opposition. Which form of resistance proved most effective?",
          choices: [
            "Economic boycotts of British goods",
            "Formation of Committees of Correspondence",
            "Public demonstrations and protests",
            "Legal petitions to King George III"
          ],
          nextChoice: 1
        },
        3: {
          title: "Declaration of Independence",
          image: declarationImage,
          content: "In Philadelphia, 1776, delegates gathered to draft a declaration that would change the course of history. The document needed to justify revolution while establishing founding principles. What key elements should be included?",
          choices: [
            "Natural rights and self-governance",
            "List of grievances against the King",
            "Vision for a new democratic nation",
            "Call for international support"
          ],
          nextChoice: 0
        }
      }
    },
    biology: {
      name: "Cell Biology",
      description: "Journey Inside the Cell",
      scenes: {
        1: {
          title: "The Cell Membrane",
          image: membraneImage,
          content: "The cell membrane is a sophisticated barrier that controls what enters and exits the cell. This selective permeability is crucial for cell survival. Which aspect of membrane function should we investigate?",
          choices: [
            "Study passive transport mechanisms",
            "Analyze protein channels and pumps",
            "Examine membrane structure and fluidity",
            "Explore cell signaling pathways"
          ],
          nextChoice: 2
        },
        2: {
          title: "Energy Production",
          image: mitochondriaImage,
          content: "Mitochondria are the powerhouses of the cell, converting nutrients into ATP through cellular respiration. This complex process involves multiple steps and molecules. Which part of energy production should we focus on?",
          choices: [
            "Glycolysis in the cytoplasm",
            "The citric acid cycle",
            "Electron transport chain",
            "ATP synthesis and regulation"
          ],
          nextChoice: 1
        },
        3: {
          title: "Protein Synthesis",
          image: proteinImage,
          content: "Proteins are essential for virtually every cellular function. The process of protein synthesis involves multiple steps and cellular components. Which stage should we examine first?",
          choices: [
            "Transcription in the nucleus",
            "mRNA processing and export",
            "Translation on ribosomes",
            "Protein folding and modification"
          ],
          nextChoice: 2
        }
      }
    },
    dataStructures: {
      name: "Data Structures",
      description: "Algorithmic Thinking",
      scenes: {
        1: {
          title: "Binary Search Trees",
          image: bstImage,
          content: "Binary Search Trees (BST) are fundamental data structures that maintain sorted data and enable efficient searching. Understanding their properties is crucial for many applications. What aspect should we explore?",
          choices: [
            "Tree traversal algorithms",
            "Insertion and deletion operations",
            "Tree balancing techniques",
            "Common BST applications"
          ],
          nextChoice: 1
        },
        2: {
          title: "Graph Algorithms",
          image: graphImage,
          content: "Graphs represent relationships between objects and are used in countless applications, from social networks to GPS navigation. Which fundamental graph concept should we study?",
          choices: [
            "Depth-First Search (DFS)",
            "Breadth-First Search (BFS)",
            "Shortest path algorithms",
            "Minimum spanning trees"
          ],
          nextChoice: 2
        },
        3: {
          title: "Hash Tables",
          image: hashImage,
          content: "Hash tables provide constant-time access to data through clever key-value mapping. Their implementation involves several important considerations. Which aspect should we focus on?",
          choices: [
            "Hash function design",
            "Collision resolution strategies",
            "Dynamic resizing operations",
            "Performance optimization techniques"
          ],
          nextChoice: 0
        }
      }
    }
  };

  const [currentTopic, setCurrentTopic] = useState<keyof typeof storyTopics>('mars');
  const [topicTransitioning, setTopicTransitioning] = useState(false);

  const rotateTopic = useCallback(() => {
    if (topicTransitioning) return;

    const topics = Object.keys(storyTopics) as Array<keyof typeof storyTopics>;
    const currentIndex = topics.indexOf(currentTopic);
    const nextIndex = (currentIndex + 1) % topics.length;

    setTopicTransitioning(true);
    setSlideDirection('left');

    setTimeout(() => {
      setCurrentTopic(topics[nextIndex]);
      setCurrentScene(1);
      setSelectedChoice(null);

      setTimeout(() => {
        setSlideDirection('right');
        setTopicTransitioning(false);
      }, 50);
    }, 300);
  }, [currentTopic, topicTransitioning]);

  const advanceDemo = useCallback(() => {
    if (isTransitioning) return;

    const currentSceneData = storyTopics[currentTopic].scenes[currentScene as keyof typeof storyTopics[typeof currentTopic]['scenes']];

    setTimeout(() => {
      setSelectedChoice(currentSceneData.nextChoice);

      setTimeout(() => {
        setIsTransitioning(true);
        setSlideDirection('left');
        
        setTimeout(() => {
          setCurrentScene(prev => (prev === Object.keys(storyTopics[currentTopic].scenes).length ? 1 : prev + 1));
          setSelectedChoice(null);
          
          setTimeout(() => {
            setSlideDirection('right');
            setIsTransitioning(false);
          }, 50);
        }, 300);
      }, 1800);
    }, 200);
  }, [currentScene, isTransitioning]);

  useEffect(() => {
    const interval = setInterval(() => {
      advanceDemo();
    }, 4000);

    return () => clearInterval(interval);
  }, [advanceDemo]);

  const currentSceneData = storyTopics[currentTopic].scenes[currentScene as keyof typeof storyTopics[typeof currentTopic]['scenes']];

  const [filterStyle, setFilterStyle] = useState<string>('all');
  const [filterMode, setFilterMode] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const learningStyles = [
    { value: 'all', label: 'All Styles 📚' },
    { value: 'story_based', label: 'Story-Based Learning 📚' },
    { value: 'interactive_lecture', label: 'Interactive Lecture 🎓' },
    { value: 'entertainment', label: 'Entertainment 🎬' },
  ];

  const storyModes = [
    { value: 'all', label: 'All Modes' },
    // Educational Modes
    { value: 'magical_academy', label: 'Magical Academy 🧙‍♀️' },
    { value: 'space_explorer', label: 'Space Explorer 🚀' },
    { value: 'time_traveler', label: 'Time Traveler ⏰' },
    { value: 'secret_agent', label: 'Secret Agent 🕵️' },
    { value: 'monster_tamer', label: 'Monster Tamer 🐉' },
    { value: 'ancient_ruins', label: 'Ancient Ruins 🏺' },
    { value: 'jungle_explorer', label: 'Jungle Explorer 🌴' },
    { value: 'ocean_voyager', label: 'Ocean Voyager 🌊' },
    { value: 'inventors_workshop', label: 'Inventor\'s Workshop ⚡' },
    // Entertainment Modes
    { value: 'adventure', label: 'Adventure 🗺️' },
    { value: 'mystery', label: 'Mystery 🔍' },
    { value: 'fantasy', label: 'Fantasy 🐉' },
    { value: 'sci_fi', label: 'Sci-Fi 🚀' },
    { value: 'horror', label: 'Horror 👻' },
    { value: 'romance', label: 'Romance 💝' },
    { value: 'comedy', label: 'Comedy 😄' },
    { value: 'slice_of_life', label: 'Slice of Life 🌅' },
  ];

  const filteredStories = stories.filter(story => {
    const matchesStyle = filterStyle === 'all' || story.learning_style === filterStyle;
    const matchesMode = filterMode === 'all' || story.story_modes?.includes(filterMode);
    const matchesSearch = searchQuery === '' || 
      story.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      story.context.toLowerCase().includes(searchQuery.toLowerCase());
    
    return matchesStyle && matchesMode && matchesSearch;
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box component="main" sx={{ 
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden'
    }}>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          minHeight: '90vh',
          display: 'flex',
          alignItems: 'center',
          pt: { xs: 8, md: 12 }, // Add top padding to account for navbar
          zIndex: 0, // Set lower z-index for the container
        }}
      >
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={4} alignItems="center" sx={{ position: 'relative' }}>
            {/* Left content */}
            <Grid item xs={12} md={4} sx={{ 
              pr: { md: 4 },
              position: 'relative',
              zIndex: 2 
            }}>
              <Box sx={{ 
                textAlign: { xs: 'center', md: 'left' },
              }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
                    fontWeight: 600,
                    mb: 2,
                    fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI", Roboto, sans-serif',
                    letterSpacing: '-0.025em',
                    background: theme => `linear-gradient(135deg, 
                      ${theme.palette.mode === 'dark' ? '#64b5f6' : '#1976d2'} 0%, 
                      ${theme.palette.mode === 'dark' ? '#ff9800' : '#f57c00'} 100%)`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                  }}
                >
                  Interactive Stories
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 500,
                    mb: 3,
                    color: theme => theme.palette.mode === 'dark' 
                      ? 'rgba(255, 255, 255, 0.9)'
                      : 'rgba(0, 0, 0, 0.8)',
                    maxWidth: '600px',
                    mx: 'auto',
                  }}
                >
                  Learn Through Immersive Storytelling
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 4,
                    color: theme => theme.palette.mode === 'dark' 
                      ? 'rgba(255, 255, 255, 0.7)'
                      : 'rgba(0, 0, 0, 0.6)',
                    maxWidth: '600px',
                    mx: 'auto',
                  }}
                >
                  Dive into interactive stories that make learning engaging and memorable. Choose your path, make decisions, and see how your choices shape the narrative.
                </Typography>
                <Stack
                  direction="row"
                  spacing={4}
                  justifyContent="center"
                  sx={{ mb: 4 }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        color: theme => theme.palette.mode === 'dark' 
                          ? '#64b5f6'
                          : '#1976d2',
                      }}
                    >
                      {totalStoriesCreated}+
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.7)'
                          : 'rgba(0, 0, 0, 0.6)',
                      }}
                    >
                      Stories Created
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        color: theme => theme.palette.mode === 'dark' 
                          ? '#ff9800'
                          : '#f57c00',
                      }}
                    >
                      {totalScenesPlayed}+
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme => theme.palette.mode === 'dark' 
                          ? 'rgba(255, 255, 255, 0.7)'
                          : 'rgba(0, 0, 0, 0.6)',
                      }}
                    >
                      Scenes Played
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <Button
                    variant="contained"
                    onClick={() => navigate('/new')}
                    sx={{
                      px: 4,
                      py: 1.5,
                      fontSize: '1rem',
                      fontWeight: 600,
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI", Roboto, sans-serif',
                      background: theme => `linear-gradient(135deg, 
                        ${theme.palette.mode === 'dark' ? '#64b5f6' : '#1976d2'} 0%, 
                        ${theme.palette.mode === 'dark' ? '#1976d2' : '#1565c0'} 100%)`,
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
                        background: theme => `linear-gradient(135deg, 
                          ${theme.palette.mode === 'dark' ? '#82c4f7' : '#1976d2'} 0%, 
                          ${theme.palette.mode === 'dark' ? '#1e88e5' : '#1565c0'} 100%)`,
                      },
                    }}
                  >
                    Start Creating
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => navigate('/library')}
                    sx={{
                      px: 4,
                      py: 1.5,
                      fontSize: '1rem',
                      fontWeight: 600,
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI", Roboto, sans-serif',
                      borderWidth: 2,
                      borderColor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.12)',
                      color: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.8)',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        borderColor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.2)',
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    Explore Stories
                  </Button>
                </Stack>
              </Box>
            </Grid>

            {/* Demo Section */}
            <Grid item xs={12} md={8} sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              pl: { md: 8 },
              position: 'relative',
              zIndex: 2
            }}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '700px', // Reduced from 800px
                  mx: { xs: 'auto', md: '0' },
                  mt: { xs: 8, md: 0 },
                }}
              >
                <StoryContainer>
                  <SlideTransition $direction={slideDirection} $show={!isTransitioning && !topicTransitioning}>
                    <Box>
                      <StoryExampleImage>
                        <img 
                          src={currentSceneData.image}
                          alt="Scene"
                          style={{ 
                            filter: 'brightness(1)'
                          }}
                        />
                        <Box sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: 'flex',
                          alignItems: 'flex-end',
                          padding: 3,
                          background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
                        }}>
                          <Box>
                            <Typography 
                              variant="subtitle1" 
                              sx={{ 
                                color: 'rgba(255,255,255,0.9)',
                                mb: 0.5,
                              }}
                            >
                              {storyTopics[currentTopic].description}
                            </Typography>
                            <Typography 
                              variant="h5" 
                              sx={{ 
                                color: 'white',
                                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                                fontWeight: 600,
                              }}
                            >
                              {currentSceneData.title}
                            </Typography>
                          </Box>
                        </Box>
                      </StoryExampleImage>

                      <Box sx={{ p: 2 }}>
                        <StoryText variant="body1" sx={{ mb: 2 }}>
                          {currentSceneData.content}
                        </StoryText>
                        <ChoicesStack spacing={1.5}>
                          {currentSceneData.choices.map((choice, index) => (
                            <ChoiceButton
                              key={index}
                              fullWidth
                              $selected={selectedChoice === index}
                              disabled={true}
                              sx={{
                                animation: `fadeSlideIn 0.2s cubic-bezier(0.4, 0, 0.2, 1) ${index * 0.05}s both`,
                                '@keyframes fadeSlideIn': {
                                  from: {
                                    opacity: 0,
                                    transform: 'translateY(8px)'
                                  },
                                  to: {
                                    opacity: 1,
                                    transform: 'translateY(0)'
                                  }
                                },
                                opacity: selectedChoice !== null && selectedChoice !== index ? 0.7 : 1,
                                mx: 'auto',  
                              }}
                            >
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                width: '100%',
                                justifyContent: 'flex-start',
                                py: 0.75,
                              }}>
                                <Box
                                  className="choice-number"
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexShrink: 0,
                                    fontWeight: 600,
                                    fontSize: '0.85rem',
                                    background: selectedChoice === index
                                      ? 'transparent'
                                      : (theme) => theme.palette.mode === 'dark'
                                        ? 'rgba(255,255,255,0.1)'
                                        : 'rgba(0,0,0,0.05)',
                                    color: selectedChoice === index 
                                      ? '#fff'
                                      : (theme) => theme.palette.mode === 'dark'
                                        ? theme.palette.common.white
                                        : theme.palette.common.black,
                                    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                                  }}
                                >
                                  {String.fromCharCode(65 + index)}
                                </Box>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: selectedChoice === index ? 600 : 500,
                                    textAlign: 'left',
                                    flex: 1,
                                    color: selectedChoice === index 
                                      ? '#fff'
                                      : (theme) => theme.palette.mode === 'dark'
                                        ? alpha(theme.palette.common.white, 0.9)
                                        : alpha(theme.palette.common.black, 0.9),
                                  }}
                                >
                                  {choice}
                                </Typography>
                              </Box>
                            </ChoiceButton>
                          ))}
                        </ChoicesStack>
                      </Box>
                    </Box>
                  </SlideTransition>
                </StoryContainer>

                {/* Topic Selection moved below demo */}
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  mt: 3,
                  gap: 2,
                  flexWrap: 'wrap'
                }}>
                  {Object.entries(storyTopics).map(([key, topic]) => (
                    <Button
                      key={key}
                      variant={currentTopic === key ? "contained" : "outlined"}
                      onClick={() => {
                        if (currentTopic !== key && !topicTransitioning) {
                          setTopicTransitioning(true);
                          setSlideDirection('left');
                          
                          setTimeout(() => {
                            setCurrentTopic(key as keyof typeof storyTopics);
                            setCurrentScene(1);
                            setSelectedChoice(null);

                            setTimeout(() => {
                              setSlideDirection('right');
                              setTopicTransitioning(false);
                            }, 50);
                          }, 300);
                        }
                      }}
                      sx={{
                        borderRadius: '20px',
                        px: 3,
                        py: 1.2,
                        textTransform: 'none',
                        fontWeight: 600,
                        transition: 'all 0.3s ease',
                        background: currentTopic === key 
                          ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
                          : 'transparent',
                        borderColor: currentTopic === key 
                          ? 'transparent'
                          : theme => theme.palette.mode === 'dark' 
                            ? 'rgba(255,255,255,0.3)'
                            : 'rgba(0,0,0,0.23)',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: currentTopic === key 
                            ? '0 4px 15px rgba(33, 150, 243, 0.3)'
                            : 'none',
                        },
                      }}
                    >
                      {topic.name}
                    </Button>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Add spacing before WhyStorybyte */}
      <Box sx={{ height: { xs: 60, md: 100 } }} />

      {/* Why Storybyte Section */}
      <Box
        id="whyStorybyte"
        sx={{
          py: { xs: 8, md: 12 },
          scrollMarginTop: theme => `${theme.spacing(10)}`, // Add scroll margin to account for fixed header
        }}
      >
        <WhyStorybyte />
      </Box>

      {/* Roadmap Section */}
      <RoadmapSection />
    </Box>
  );
};

export default LandingPage;