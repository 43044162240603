import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  styled,
  CardActionArea,
  useTheme
} from '@mui/material';

interface StoryMode {
  id: string;
  name: string;
  description: string;
  icon: string;
  subjects: string[];
  learningStyle: string;
}

interface StoryModeCardProps {
  mode: StoryMode;
  selected: boolean;
  onSelect: (id: string) => void;
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<{ selected?: boolean }>(({ theme, selected }) => ({
  height: '100%',
  transition: 'all 0.3s ease',
  border: selected ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent',
  backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: '2.5rem',
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '60px',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  }
}));

const SubjectsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(1),
}));

const StoryModeCard: React.FC<StoryModeCardProps> = ({ mode, selected, onSelect }) => {
  const theme = useTheme();

  return (
    <StyledCard selected={selected}>
      <CardActionArea onClick={() => onSelect(mode.id)} sx={{ height: '100%' }}>
        <CardContent>
          <IconWrapper>
            <span role="img" aria-label={mode.name}>
              {mode.icon}
            </span>
          </IconWrapper>
          <Typography 
            variant="h6" 
            component="div" 
            gutterBottom 
            align="center"
            sx={{
              fontWeight: selected ? 'bold' : 'medium',
              color: selected ? 'primary.main' : 'text.primary',
            }}
          >
            {mode.name}
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary" 
            sx={{ 
              mb: 2,
              textAlign: 'center',
              minHeight: '2.5em',
            }}
          >
            {mode.description}
          </Typography>
          <SubjectsWrapper>
            {mode.subjects.map((subject) => (
              <Chip
                key={subject}
                label={subject}
                size="small"
                variant="outlined"
                color={selected ? "primary" : "default"}
                sx={{
                  borderRadius: '16px',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  }
                }}
              />
            ))}
          </SubjectsWrapper>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default StoryModeCard;
