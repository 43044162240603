import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, Typography, Rating, TextField, Button, Dialog, DialogContent
} from '@mui/material';

interface StoryEndProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onRestart: () => void;
  storyData: {
    rating: number;
    num_ratings: number;
    comments: Array<{
      id: number;
      user?: {
        id: number;
        username: string;
      };
      text: string;
      created_at: string;
      rating: number;
    }>;
  };
}

const StoryEnd: React.FC<StoryEndProps> = ({ open, onClose, title, onRestart, storyData }) => {
  const [userRating, setUserRating] = useState<number | null>(null);
  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!userRating) return;
    setSubmitting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API call
      setSubmitting(false);
      onClose();
      navigate('/');
    } catch (error) {
      console.error('Error submitting review:', error);
      setSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: 'background.paper',
          color: 'text.primary',
          borderRadius: 2,
        }
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" component="h2" gutterBottom color="text.primary">
            {title}
          </Typography>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            Story Complete!
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="text.primary">
            Rate this story
          </Typography>
          <Rating
            value={userRating}
            onChange={(_, value) => setUserRating(value)}
            size="large"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Share your thoughts about the story..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'text.primary',
                '& fieldset': {
                  borderColor: 'divider',
                },
                '&:hover fieldset': {
                  borderColor: 'primary.main',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'text.secondary',
              },
            }}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            disabled={submitting || !userRating}
            sx={{ 
              mt: 2,
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
          >
            {submitting ? 'Submitting...' : 'Submit Review'}
          </Button>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom color="text.primary">
            Story Stats
          </Typography>
          <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
            <Box>
              <Typography variant="h4" color="text.primary">
                {storyData.rating.toFixed(1)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Average Rating
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" color="text.primary">
                {storyData.num_ratings}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Total Ratings
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="h6" gutterBottom color="text.primary">
            Comments
          </Typography>
          {storyData.comments.length === 0 ? (
            <Typography variant="body1" color="text.secondary">
              No comments yet. Be the first to share your thoughts!
            </Typography>
          ) : (
            storyData.comments.map((comment) => (
              <Box key={comment.id} sx={{ mb: 2, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="subtitle2" color="text.primary">
                    {comment.user?.username || 'Anonymous'}
                  </Typography>
                  <Rating value={comment.rating} readOnly size="small" />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {comment.text}
                </Typography>
              </Box>
            ))
          )}
        </Box>

        <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={onRestart}
            sx={{
              color: 'text.primary',
              borderColor: 'divider',
              '&:hover': {
                borderColor: 'primary.main',
                bgcolor: 'action.hover',
              },
            }}
          >
            Restart Story
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/')}
            sx={{
              color: 'text.primary',
              borderColor: 'divider',
              '&:hover': {
                borderColor: 'primary.main',
                bgcolor: 'action.hover',
              },
            }}
          >
            Home
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StoryEnd;