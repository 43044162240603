import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../constants';
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  Alert,
  useTheme,
} from '@mui/material';
import StoryCard, { Story as StoryCardStory } from './StoryCard';

interface StoryData {
  id: number;
  title: string;
  current_node: {
    scene_number: number;
    image_url: string;
  };
  is_liked: boolean;
  like_count: number;
}

interface Story {
  id: number;
  title: string;
  context: string;
  plays: number;
  like_count: number;
  is_liked: boolean;
  cover_image?: string;
  current_node?: {
    scene_number: number;
    image_url: string;
  };
  is_public: boolean;
  is_educational: boolean;
  rating: number;
  completion_percentage: number;
  cache_percentage: number;
  tags: string[];
  author?: string;
  user_progress?: any[];
  story_modes?: string[];
  learning_mode?: string;
  complexity?: string;
  has_completed_survey?: boolean;
  education_level?: string;
  learning_style?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`library-tabpanel-${index}`}
      aria-labelledby={`library-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const UnifiedLibrary: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [inProgressStories, setInProgressStories] = useState<Story[]>([]);
  const [completedStories, setCompletedStories] = useState<Story[]>([]);
  const [suggestedStories, setSuggestedStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);

  const getImageUrl = (source: string | undefined | null): string => {
    if (!source) return FALLBACK_IMAGE;
    if (source.startsWith('http') || source.startsWith('data:')) return source;
    if (source.startsWith('/static/')) return source;
    return `${S3_BUCKET_URL}/${source}`;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
  }, []);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        setLoading(true);
        setError(null);

        const [continueReadingResponse, personalLibraryResponse, suggestedResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/stories/continue_reading/`),
          axios.get(`${API_BASE_URL}/stories/personal_library/`),
          axios.get(`${API_BASE_URL}/stories/suggested/`),
        ]);

        console.log('API Responses:', {
          continueReading: continueReadingResponse.data,
          personalLibrary: personalLibraryResponse.data,
          suggested: suggestedResponse.data
        });

        // Log each story's raw data
        continueReadingResponse.data.forEach((story: any) => {
          console.log('Raw story from API (continue reading):', {
            title: story.title,
            learning_mode: story.learning_mode,
            learning_style: story.learning_style,
            is_educational: story.is_educational,
            story_modes: story.story_modes,
            tags: story.tags,
            education_level: story.education_level,
            complexity: story.complexity,
            raw: story // Log the entire raw story object
          });
        });

        personalLibraryResponse.data.forEach((story: any) => {
          console.log('Raw story from API (personal library):', {
            title: story.title,
            learning_mode: story.learning_mode,
            learning_style: story.learning_style,
            is_educational: story.is_educational,
            story_modes: story.story_modes,
            tags: story.tags,
            education_level: story.education_level,
            complexity: story.complexity,
            raw: story // Log the entire raw story object
          });
        });

        suggestedResponse.data.forEach((story: any) => {
          console.log('Raw story from API (suggested):', {
            title: story.title,
            learning_mode: story.learning_mode,
            learning_style: story.learning_style,
            is_educational: story.is_educational,
            story_modes: story.story_modes,
            tags: story.tags,
            education_level: story.education_level,
            complexity: story.complexity,
            raw: story // Log the entire raw story object
          });
        });

        // In-progress stories come from continue reading
        setInProgressStories(continueReadingResponse.data);

        // Filter completed stories from personal library
        const completed = personalLibraryResponse.data.filter((story: Story) => {
          return story.completion_percentage === 100;
        });
        setCompletedStories(completed);

        // Suggested stories
        setSuggestedStories(suggestedResponse.data);
      } catch (err) {
        console.error('Error fetching stories:', err);
        setError('Failed to fetch stories. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  const transformStory = (story: Story): StoryCardStory => {
    // Raw API data
    console.log('Transforming story:', {
      title: story.title,
      learning_mode: story.learning_mode,
      is_educational: story.is_educational,
      story_modes: story.story_modes,
      raw: story // Log the entire raw story object
    });

    // Determine learning style from learning_mode
    let learningStyle: string;
    const normalizedMode = story.learning_mode?.toLowerCase();

    // Map learning_mode to learning style
    if (normalizedMode === 'interactive_lecture' || normalizedMode === 'lecture') {
      learningStyle = 'interactive_lecture';
    } else if (normalizedMode === 'entertainment') {
      learningStyle = 'entertainment';
    } else {
      learningStyle = 'story_based';
    }

    console.log('Learning style determination:', {
      title: story.title,
      originalMode: story.learning_mode,
      normalizedMode,
      finalStyle: learningStyle,
      raw: story // Log the entire raw story object
    });

    // Determine story mode
    let storyModes = ['default'];
    if (Array.isArray(story.story_modes) && story.story_modes.length > 0) {
      storyModes = story.story_modes;
    } else if (story.is_educational) {
      // If educational, default to magical_academy mode
      storyModes = ['magical_academy'];
    }

    const transformed = {
      id: story.id,
      title: story.title,
      current_node: {
        scene_number: story.current_node?.scene_number || 1,
        image_url: getImageUrl(story.current_node?.image_url || story.cover_image),
      },
      context: story.context,
      is_complete: story.completion_percentage === 100,
      has_completed_survey: story.has_completed_survey || false,
      story_modes: storyModes,
      tags: story.tags || [],
      education_level: story.education_level || 'General',
      learning_style: learningStyle,
      learning_mode: learningStyle, // Keep these in sync
      is_educational: story.is_educational || false,
      rating: story.rating || 0,
      plays: story.plays || 0,
      shares: 0,
      like_count: story.like_count || 0,
      is_liked: story.is_liked || false,
      author: story.author || 'Anonymous',
      complexity: story.complexity || 'medium',
    };

    // Log the final transformed story
    console.log('Final transformed story:', {
      title: transformed.title,
      learning_style: transformed.learning_style,
      learning_mode: transformed.learning_mode,
      story_modes: transformed.story_modes,
      is_educational: transformed.is_educational,
      raw: story // Log the entire raw story object
    });

    return transformed;
  };

  const handleLike = async (e: React.MouseEvent, storyId: number) => {
    e.stopPropagation();
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${storyId}/like/`);
      
      // Update the story in our local state
      setInProgressStories(prevStories => 
        prevStories.map(story => 
          story.id === storyId 
            ? { 
                ...story, 
                is_liked: response.data.is_liked,
                like_count: response.data.like_count
              }
            : story
        )
      );
      setCompletedStories(prevStories => 
        prevStories.map(story => 
          story.id === storyId 
            ? { 
                ...story, 
                is_liked: response.data.is_liked,
                like_count: response.data.like_count
              }
            : story
        )
      );
      setSuggestedStories(prevStories => 
        prevStories.map(story => 
          story.id === storyId 
            ? { 
                ...story, 
                is_liked: response.data.is_liked,
                like_count: response.data.like_count
              }
            : story
        )
      );
    } catch (error) {
      console.error('Error liking story:', error);
    }
  };

  const renderStoryGrid = (stories: Story[]) => {
    return stories.length === 0 ? (
      <Typography variant="body1" color="text.secondary" align="center">
        No stories found
      </Typography>
    ) : (
      <Grid container spacing={3}>
        {stories.map((story) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={story.id}>
            <StoryCard
              story={transformStory(story)}
              onClick={() => navigate(`/story/${story.id}`)}
              onLikeClick={handleLike}
              showUnlvAlert={false}
              sx={{
                height: '100%',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '100vh',
        mt: 12, 
        width: '100%'
      }}>
        <CircularProgress sx={{ color: '#FF4F00' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: '1400px',
      margin: '0 auto',
      mt: 12,
      px: { xs: 2, sm: 3 },
    }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
        My Library
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          aria-label="library tabs"
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
            },
          }}
        >
          <Tab label="In Progress" />
          <Tab label="Completed" />
          <Tab label="Suggested" />
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        {renderStoryGrid(inProgressStories)}
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {renderStoryGrid(completedStories)}
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        {renderStoryGrid(suggestedStories)}
      </TabPanel>
    </Box>
  );
};

export default UnifiedLibrary;
