import React from 'react';
import { Box, Typography } from '@mui/material';
import ProfileInfo from './ProfileInfo';

const Profile: React.FC = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        My Profile
      </Typography>
      <Box sx={{ mt: 2 }}>
        <ProfileInfo />
      </Box>
    </Box>
  );
};

export default Profile;