import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  Link,
  Alert,
  AlertTitle,
  Divider,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import {
  School,
  Create,
  Assignment,
  CheckCircle,
  RadioButtonUnchecked,
  Warning,
  Launch,
  AccessTime,
  Info,
  DeleteOutline,
} from "@mui/icons-material";
import axios from 'axios';
import { StoryPreview } from '../types';

const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';

interface Story extends StoryPreview {
  conclusion_node?: any;
  current_scene?: number;
  word_count?: number;
  time_spent?: number;
}

interface UnlvStudentPortalProps {
  stories: Story[];
  onCreateStory: () => void;
  onTakeSurvey: (storyId: number) => void;
  deadline?: string;
}

const UnlvStudentPortal: React.FC<UnlvStudentPortalProps> = ({
  stories,
  onCreateStory,
  onTakeSurvey,
  deadline = "2025-04-26",
}) => {
  const theme = useTheme();
  const [openedSurveys, setOpenedSurveys] = useState<number[]>([]);
  const [completingStoryId, setCompletingStoryId] = useState<number | null>(null);
  
  // Sort stories by creation date (oldest first)
  const sortedStories = [...stories].sort((a, b) => 
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );
  
  const requiredStories = 5;
  const TOTAL_SCENES = 8; // Updated to match actual total number of scenes
  
  // Enhanced story completion check with scene number fallback and progress validation
  const getStoryStatus = (story: Story) => {
    if (!story) return { isComplete: false, status: 'Not Started', progress: 0 };
    
    const currentScene = story.current_scene ?? story.current_node?.scene_number ?? 1;
    // Story is complete if it has conclusion_node or has reached scene 8
    const isComplete = Boolean(story.conclusion_node) || currentScene >= TOTAL_SCENES;
    const progress = Math.min(100, Math.round((currentScene / TOTAL_SCENES) * 100));
    
    let status = 'Not Started';
    if (isComplete) {
      status = 'Completed';
    } else if (currentScene > 1) {
      status = `Scene ${currentScene} of ${TOTAL_SCENES}`;
    } else if (story.word_count && story.word_count > 0) {
      status = 'Just Started';
    }
    
    return { isComplete, status, progress };
  };
  
  const completedStories = sortedStories.filter(s => getStoryStatus(s).isComplete).length;
  const surveysCompleted = sortedStories.filter(s => s.has_completed_survey).length;
  const progress = (completedStories / requiredStories) * 100;

  // Calculate days remaining until deadline
  const daysRemaining = Math.ceil((new Date(deadline).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

  // Calculate recommended pace
  const recommendedStoriesPerWeek = Math.max(1, Math.ceil((requiredStories - completedStories) / (daysRemaining / 7)));

  // Check if stories were created too close together
  const hasTimeSpacingIssue = () => {
    if (sortedStories.length < 2) return false;
    const sortedDates = [...sortedStories]
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .map(s => new Date(s.created_at));

    for (let i = 1; i < sortedDates.length; i++) {
      const daysBetween = (sortedDates[i-1].getTime() - sortedDates[i].getTime()) / (1000 * 60 * 60 * 24);
      if (daysBetween < 1) return true;
    }
    return false;
  };

  const openSurvey = (story: Story, storyNumber: number) => {
    if (!getStoryStatus(story).isComplete) {
      alert('Please complete the story before taking the survey.');
      return;
    }
    window.open('https://forms.gle/M9Mgda9Nf4shtZKRA', '_blank');
    setOpenedSurveys(prev => [...prev, story.id]);
  };

  const markSurveyComplete = async (storyId: number) => {
    const story = sortedStories.find(s => s.id === storyId);
    if (!story) return;

    if (!getStoryStatus(story).isComplete) {
      alert('You must complete the story before marking the survey as complete.');
      return;
    }

    if (!openedSurveys.includes(storyId)) {
      alert('Please open and complete the survey form first before marking it as done.');
      return;
    }

    const message = 
      "Important: Please confirm the following:\n\n" +
      "1. I understand that survey completion will be verified before grades are submitted to my professor\n" +
      "2. I have completed the story to its conclusion\n" +
      "3. I have filled out AND submitted the Google Form survey\n" +
      "4. I understand this action cannot be undone\n" +
      "5. I am being truthful about completing the survey\n\n" +
      "Do you confirm all of the above?";
    
    if (window.confirm(message)) {
      setCompletingStoryId(storyId);
      try {
        const finalUrl = `${API_BASE_URL}/surveys/mark_complete/`;
        console.log('Attempting to mark survey complete at URL:', finalUrl);
        const response = await axios.post(finalUrl, {
          story: storyId
        });
        console.log('Survey completion response:', response);
        
        // Update local state
        story.has_completed_survey = true;
        
        // Notify parent component
        onTakeSurvey(storyId);
        
        // Clear from opened surveys
        setOpenedSurveys(prev => prev.filter(id => id !== storyId));
        
        // Force a re-render
        window.location.reload();
      } catch (error: any) {
        console.error('Error details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
          url: error.config?.url
        });
        alert(`Failed to mark survey as complete. Please try again. Error: ${error.response?.data?.error || error.message}`);
      } finally {
        setCompletingStoryId(null);
      }
    }
  };

  const deleteStory = async (storyId: number) => {
    const story = sortedStories.find(s => s.id === storyId);
    if (!story) return;

    const { isComplete } = getStoryStatus(story);
    if (isComplete) {
      alert("Cannot delete a completed story");
      return;
    }

    if (window.confirm(`Are you sure you want to delete "${story.title}"? This cannot be undone.`)) {
      try {
        await axios.delete(`${API_BASE_URL}/stories/${storyId}/`);
        window.location.reload(); // Refresh to update the story list
      } catch (error) {
        console.error('Error deleting story:', error);
        alert('Failed to delete story. Please try again.');
      }
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        paddingTop: theme.spacing(12),
        paddingX: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingX: theme.spacing(3),
        },
      }}
    >
      {/* Header with Deadline */}
      <Paper
        elevation={0}
        sx={{
          p: 4,
          mb: 4,
          backgroundColor: '#B10202',
          color: 'white',
          borderRadius: '16px',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h4" component="h1" gutterBottom>
              UNLV Assignment Portal
            </Typography>
            <Typography variant="subtitle1">
              Complete 5 stories and their surveys to fulfill your course requirements
            </Typography>
          </Box>
          <Tooltip title="Course deadline">
            <Box textAlign="center">
              <AccessTime sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="h6">{daysRemaining} days</Typography>
              <Typography variant="caption">remaining</Typography>
            </Box>
          </Tooltip>
        </Box>
      </Paper>

      {/* Time Spacing Warning */}
      {hasTimeSpacingIssue() && (
        <Alert 
          severity="warning" 
          sx={{ 
            mb: 4,
            borderRadius: 2,
            '& .MuiAlert-icon': {
              fontSize: '2rem'
            }
          }}
        >
          <AlertTitle sx={{ fontWeight: 600 }}>Time Spacing Issue Detected</AlertTitle>
          Some stories were created too close together. Please spread your story creation throughout the course for better learning outcomes.
        </Alert>
      )}

      {/* Assignment Requirements */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <Assignment sx={{ mr: 1 }} />
            <Typography variant="h6">Assignment Requirements</Typography>
          </Box>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircle color="success" />
              </ListItemIcon>
              <ListItemText
                primary="Create a Storybyte Account"
                secondary="Use your initials + last 4 digits of NSHE (e.g., JS2987)"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                {completedStories >= requiredStories ? (
                  <CheckCircle color="success" />
                ) : (
                  <RadioButtonUnchecked color="action" />
                )}
              </ListItemIcon>
              <ListItemText
                primary="Create 5 Complete Stories"
                secondary="Create 5 stories and complete them to the final scene. Spread these creations throughout the course."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                {surveysCompleted === completedStories && completedStories > 0 ? (
                  <CheckCircle color="success" />
                ) : (
                  <RadioButtonUnchecked color="action" />
                )}
              </ListItemIcon>
              <ListItemText
                primary="Complete Surveys"
                secondary="Fill out the survey after completing each story to the final scene"
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>

      {/* Progress Overview with Enhanced Stats */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h6">Assignment Progress</Typography>
            <Tooltip title="Your overall completion percentage">
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={60}
                  sx={{ color: progress === 100 ? 'success.main' : 'primary.main' }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="text.secondary">
                    {Math.round(progress)}%
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          </Box>

          {/* Story Progress Cards */}
          <Grid container spacing={2}>
            {[1, 2, 3, 4, 5].map((storyNum) => {
              const story = sortedStories[storyNum - 1];
              const { isComplete, status, progress } = getStoryStatus(story);
              const hasSurvey = story?.has_completed_survey;

              return (
                <Grid item xs={12} key={storyNum}>
                  <Paper
                    sx={{
                      p: 2,
                      border: 1,
                      borderColor: 'divider',
                      bgcolor: 'background.paper',
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={3}>
                        <Box>
                          <Typography variant="h6" color="primary">
                            {story?.title || `Story ${storyNum}`}
                          </Typography>
                          {story && (
                            <Typography variant="caption" display="block" color="text.secondary">
                              Created: {new Date(story.created_at).toLocaleDateString()}
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Story Status
                          </Typography>
                          <Box display="flex" alignItems="center">
                            {story && (
                              <>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={{
                                      height: 8,
                                      borderRadius: 4,
                                      mb: 1,
                                      backgroundColor: 'grey.200',
                                      '& .MuiLinearProgress-bar': {
                                        backgroundColor: isComplete ? 'success.main' : 'warning.main',
                                      },
                                    }}
                                  />
                                </Box>
                                <Box display="flex" alignItems="center">
                                  {isComplete ? (
                                    <Tooltip title="Story completed">
                                      <CheckCircle color="success" sx={{ mr: 1 }} />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Continue the story until you reach the end">
                                      <RadioButtonUnchecked color="warning" sx={{ mr: 1 }} />
                                    </Tooltip>
                                  )}
                                  <Typography>{status}</Typography>
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Box display="flex" flexDirection="column" gap={1}>
                          {/* For completed stories with pending surveys */}
                          {story && isComplete && !hasSurvey && (
                            <>
                              {!openedSurveys.includes(story.id) ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => openSurvey(story, storyNum)}
                                  startIcon={<Launch />}
                                  fullWidth
                                >
                                  Open Survey Form
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => markSurveyComplete(story.id)}
                                  startIcon={completingStoryId === story.id ? <CircularProgress size={20} color="inherit" /> : <CheckCircle />}
                                  disabled={completingStoryId === story.id}
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: 'success.light',
                                      color: 'common.white',
                                    }
                                  }}
                                  fullWidth
                                >
                                  {completingStoryId === story.id ? 'Marking Complete...' : 'Mark Survey Done'}
                                </Button>
                              )}
                            </>
                          )}

                          {/* For completed stories with completed surveys */}
                          {story && isComplete && hasSurvey && (
                            <Box display="flex" alignItems="center" sx={{ color: 'success.main', justifyContent: 'center' }}>
                              <CheckCircle sx={{ mr: 1 }} />
                              <Typography>All Complete!</Typography>
                            </Box>
                          )}

                          {/* For incomplete stories */}
                          {story && !isComplete && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => window.location.href = `/story/${story.id}`}
                                fullWidth
                              >
                                Continue Story
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => deleteStory(story.id)}
                                startIcon={<DeleteOutline />}
                                size="small"
                              >
                                Delete Story
                              </Button>
                            </>
                          )}

                          {/* For empty story slots */}
                          {!story && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={onCreateStory}
                              fullWidth
                            >
                              Start Story
                            </Button>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Box>
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Survey Status
                          </Typography>
                          <Box display="flex" alignItems="center">
                            {hasSurvey ? (
                              <Box display="flex" alignItems="center">
                                <CheckCircle color="success" sx={{ mr: 1 }} />
                                <Typography>Survey Complete</Typography>
                              </Box>
                            ) : isComplete ? (
                              <Box display="flex" alignItems="center">
                                <RadioButtonUnchecked color="warning" sx={{ mr: 1 }} />
                                <Typography>Ready for Survey</Typography>
                              </Box>
                            ) : (
                              <Box display="flex" alignItems="center">
                                <RadioButtonUnchecked color="action" sx={{ mr: 1 }} />
                                <Typography>Finish Story First</Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>

          {/* Summary Statistics */}
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Tooltip title="Number of stories you've completed out of the required 5">
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Stories Completed
                  </Typography>
                  <Typography variant="h6">
                    {completedStories} / {requiredStories}
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Tooltip title="Number of surveys you've completed">
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Surveys Completed
                  </Typography>
                  <Typography variant="h6">
                    {surveysCompleted} / {requiredStories}
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Tooltip title="Recommended number of stories to complete per week to meet the deadline">
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Recommended Pace
                  </Typography>
                  <Typography variant="h6">
                    {recommendedStoriesPerWeek} per week
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Tooltip title="Days remaining until the deadline">
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Time Remaining
                  </Typography>
                  <Typography variant="h6" color={daysRemaining < 14 ? "error.main" : "text.primary"}>
                    {daysRemaining} days
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Deadline Warning */}
      {daysRemaining < 14 && completedStories < requiredStories && (
        <Alert
          severity="error"
          sx={{ mb: 4 }}
          action={
            <Tooltip title="You need to complete all stories and surveys before the deadline">
              <Info color="action" />
            </Tooltip>
          }
        >
          <AlertTitle>Deadline Approaching</AlertTitle>
          You have less than 2 weeks to complete your remaining {requiredStories - completedStories} stories. Please make sure to complete them before the deadline.
        </Alert>
      )}

      {/* Story Creation History */}
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <Create sx={{ mr: 1 }} />
            <Typography variant="h6">Your Stories</Typography>
          </Box>
          {sortedStories.length > 0 ? (
            <List>
              {sortedStories.map((story, index) => (
                <React.Fragment key={story.id}>
                  {index > 0 && <Divider />}
                  <ListItem
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems: { xs: 'flex-start', sm: 'center' },
                      py: 2
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: 'white',
                          borderRadius: '50%',
                          width: 24,
                          height: 24,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mr: 2,
                        }}
                      >
                        {index + 1}
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1" component="div">
                          {story.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Created: {new Date(story.created_at).toLocaleDateString()}
                        </Typography>
                      </Box>
                      {!story.has_completed_survey && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => openSurvey(story, index + 1)}
                          sx={{
                            ml: { xs: 0, sm: 2 },
                            mt: { xs: 1, sm: 0 },
                            '& .MuiSvgIcon-root': {
                              fontSize: '1.2rem',
                              ml: 1
                            }
                          }}
                          endIcon={<Launch />}
                        >
                          Open Survey Form
                        </Button>
                      )}
                      {story.has_completed_survey && (
                        <Box sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 1, sm: 0 }, display: 'flex', alignItems: 'center' }}>
                          <CheckCircle color="success" sx={{ mr: 1 }} />
                          <Typography variant="body2" color="success.main">
                            Survey Complete
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography variant="body1" color="text.secondary" align="center" py={4}>
              You haven't created any stories yet. Click the button below to get started!
            </Typography>
          )}
          {completedStories < requiredStories && (
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={onCreateStory}
                startIcon={<Create />}
              >
                Create Story {completedStories + 1}
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UnlvStudentPortal;
