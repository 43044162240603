import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
  Button,
  Paper,
  Avatar,
  useTheme,
} from '@mui/material';
import {
  School as SchoolIcon,
  Psychology as BrainIcon,
  AutoStories as BookIcon,
  BarChart as StatsIcon,
  Speed as SpeedIcon,
  Diversity3 as DiversityIcon,
  AutoAwesome as CustomizeIcon,
  Create as CreateIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

interface StatsItemProps {
  value: string;
  label: string;
  delay: number;
}

const StatsItem: React.FC<StatsItemProps> = ({ value, label, delay }) => {
  const [displayValue, setDisplayValue] = useState('0');
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
      const numericValue = parseInt(value);
      if (!isNaN(numericValue)) {
        let start = 0;
        const increment = numericValue / 30;
        const counter = setInterval(() => {
          start += increment;
          if (start >= numericValue) {
            setDisplayValue(value);
            clearInterval(counter);
          } else {
            setDisplayValue(Math.floor(start).toString());
          }
        }, 50);
        return () => clearInterval(counter);
      } else {
        setDisplayValue(value);
      }
    }, delay);
    return () => clearTimeout(timer);
  }, [value, delay]);

  return (
    <Box sx={{ 
      textAlign: 'center',
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
      transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
    }}>
      <Typography variant="h3" component="div" sx={{ 
        fontWeight: 'bold',
        color: 'primary.main'
      }}>
        {displayValue}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        {label}
      </Typography>
    </Box>
  );
};

const About: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const features = [
    {
      icon: BookIcon,
      title: "Interactive Learning",
      description: "Engage with stories that adapt to your choices and learning style"
    },
    {
      icon: BrainIcon,
      title: "AI-Enhanced",
      description: "Advanced AI technology that creates personalized educational narratives"
    },
    {
      icon: DiversityIcon,
      title: "Collaborative",
      description: "Connect with teachers and peers in interactive learning spaces"
    },
    {
      icon: CustomizeIcon,
      title: "Personalized",
      description: "Content that adjusts to your knowledge level and interests"
    }
  ];

  const steps = [
    {
      icon: CreateIcon,
      title: "Create",
      description: "Start by crafting your educational story"
    },
    {
      icon: SchoolIcon,
      title: "Learn",
      description: "Engage with interactive content"
    },
    {
      icon: StatsIcon,
      title: "Track",
      description: "Monitor progress and achievements"
    },
    {
      icon: SpeedIcon,
      title: "Improve",
      description: "Accelerate your learning journey"
    }
  ];

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* Hero Section */}
      <Box sx={{
        position: 'relative',
        bgcolor: 'background.default',
        pt: 15,
        pb: 20,
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" component="h1" 
                sx={{ 
                  fontWeight: 700,
                  mb: 3,
                  opacity: animate ? 1 : 0,
                  transform: animate ? 'translateY(0)' : 'translateY(20px)',
                  transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
                  background: 'linear-gradient(135deg, #2196F3, #1976D2)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>
                Revolutionizing Education Through Stories
              </Typography>
              <Typography variant="h5" 
                sx={{ 
                  mb: 4, 
                  color: 'text.secondary',
                  opacity: animate ? 1 : 0,
                  transform: animate ? 'translateY(0)' : 'translateY(20px)',
                  transition: 'opacity 0.8s ease-out 0.2s, transform 0.8s ease-out 0.2s',
                }}>
                Transform complex concepts into engaging narratives that make learning stick.
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/new')}
                sx={{
                  background: 'linear-gradient(135deg, #2196F3, #1976D2)',
                  px: 4,
                  py: 2,
                  fontSize: '1.1rem',
                  opacity: animate ? 1 : 0,
                  transform: animate ? 'translateY(0)' : 'translateY(20px)',
                  transition: 'opacity 0.8s ease-out 0.4s, transform 0.8s ease-out 0.4s, background 0.3s',
                  '&:hover': {
                    background: 'linear-gradient(135deg, #1E88E5, #1565C0)',
                  }
                }}
              >
                Start Creating
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Stats Section */}
      <Container maxWidth="lg" sx={{ mt: -10, mb: 8, position: 'relative' }}>
        <Paper 
          elevation={3} 
          sx={{ 
            py: 6, 
            px: 4, 
            borderRadius: 4,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <StatsItem value="85" label="% Higher Retention" delay={0} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsItem value="2.5" label="x Faster Learning" delay={200} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsItem value="1000" label="+ Active Stories" delay={400} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsItem value="93" label="% Student Satisfaction" delay={600} />
            </Grid>
          </Grid>
        </Paper>
      </Container>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h3" component="h2" align="center" gutterBottom sx={{
          fontWeight: 700,
          background: 'linear-gradient(135deg, #2196F3, #1976D2)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          opacity: animate ? 1 : 0,
          transform: animate ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        }}>
          Why Choose Storybyte
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={feature.title}>
              <Card sx={{ 
                height: '100%',
                transition: 'all 0.3s ease-in-out',
                border: '1px solid',
                borderColor: 'divider',
                opacity: animate ? 1 : 0,
                transform: animate ? 'translateY(0)' : 'translateY(20px)',
                animation: `${scaleIn} 0.6s ease-out ${index * 0.2}s forwards`,
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: theme.shadows[8],
                  borderColor: '#2196F3',
                }
              }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Avatar sx={{ 
                    margin: '0 auto',
                    width: 60,
                    height: 60,
                    mb: 2,
                    background: 'linear-gradient(135deg, #2196F3, #1976D2)',
                  }}>
                    <feature.icon sx={{ fontSize: 30, color: 'white' }} />
                  </Avatar>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* How It Works Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8, borderTop: '1px solid', borderColor: 'divider' }}>
        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" align="center" gutterBottom sx={{
            fontWeight: 700,
            background: 'linear-gradient(135deg, #2196F3, #1976D2)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            opacity: animate ? 1 : 0,
            transform: animate ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
          }}>
            How It Works
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {steps.map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={step.title}>
                <Paper sx={{ 
                  p: 3, 
                  height: '100%',
                  textAlign: 'center',
                  border: '1px solid',
                  borderColor: 'divider',
                  transition: 'all 0.3s ease-in-out',
                  opacity: animate ? 1 : 0,
                  transform: animate ? 'translateY(0)' : 'translateY(20px)',
                  animation: `${scaleIn} 0.6s ease-out ${index * 0.2}s forwards`,
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: theme.shadows[8],
                    borderColor: '#2196F3',
                  }
                }}>
                  <Avatar sx={{ 
                    margin: '0 auto',
                    width: 60,
                    height: 60,
                    mb: 2,
                    background: 'linear-gradient(135deg, #2196F3, #1976D2)',
                  }}>
                    <step.icon sx={{ fontSize: 30, color: 'white' }} />
                  </Avatar>
                  <Typography variant="h6" gutterBottom>
                    {step.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {step.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom sx={{
            fontWeight: 700,
            background: 'linear-gradient(135deg, #2196F3, #1976D2)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            opacity: animate ? 1 : 0,
            transform: animate ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
          }}>
            Ready to Transform Your Learning?
          </Typography>
          <Typography 
            variant="body1" 
            color="text.secondary" 
            paragraph
            sx={{
              opacity: animate ? 1 : 0,
              transform: animate ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 0.8s ease-out 0.2s, transform 0.8s ease-out 0.2s',
            }}
          >
            Join thousands of educators and students who are already using Storybyte to make learning more engaging and effective.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/signup')}
            sx={{
              background: 'linear-gradient(135deg, #2196F3, #1976D2)',
              px: 4,
              py: 2,
              fontSize: '1.1rem',
              opacity: animate ? 1 : 0,
              transform: animate ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 0.8s ease-out 0.4s, transform 0.8s ease-out 0.4s, background 0.3s',
              '&:hover': {
                background: 'linear-gradient(135deg, #1E88E5, #1565C0)',
              }
            }}
          >
            Get Started
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default About;