import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Switch,
  CircularProgress,
  Alert,
  Snackbar,
} from '@mui/material';
import { 
  Create,
  AutoStories,
  Psychology,
  Settings,
  ArrowForward,
  ArrowBack,
  School,
  SportsEsports,
} from '@mui/icons-material';
import axios from 'axios';
import StoryModeCard from './StoryModeCard';
import { STORY_MODES } from './StoryModeSelector';

// Styled components
import { styled } from '@mui/material/styles';

const StoryCreationContainer = styled(Box)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  padding: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[1],
}));

const StyledStepContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& .MuiCard-root': {
    height: '100%',
  },
}));

const LearningStyleCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  background: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const StoryTypeIcon = styled(Box)(({ theme }) => ({
  fontSize: '1.75rem',
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '48px',
  margin: '0 auto',
  background: theme.palette.action.hover,
}));

const ModeIcon = styled(Box)(({ theme }) => ({
  fontSize: '1.5rem',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  margin: '0 auto',
  background: theme.palette.action.hover,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: '10px 24px',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
}));

// Types
interface Class {
  id: number;
  name: string;
  stories_count: number;
  owner: string;
}

interface StoryData {
  title: string;
  context: string;
  education_level: string;
  class_id: number | null;
  is_public: boolean;
  is_educational: boolean;
  tags: string[];
  learning_mode?: string;
  story_modes?: string[];
  teaching_method?: string;
  interaction_level?: string;
  complexity?: string;
  narrative_perspective?: string;
}

const educationLevels = [
  "General",
  "Elementary",
  "Middle School",
  "High School",
  "College",
  "Professional"
];

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const LECTURE_MODES = [
  {
    id: 'socratic_discussion',
    name: 'Socratic Discussion',
    emoji: '💭',
    description: 'Guide learning through questions and dialogue',
  },
  {
    id: 'case_study',
    name: 'Case Study',
    emoji: '🔍',
    description: 'Learn through real-world examples and analysis',
  },
  {
    id: 'problem_based',
    name: 'Problem-Based',
    emoji: '🧩',
    description: 'Solve complex problems step by step',
  },
  {
    id: 'demonstration',
    name: 'Demonstration',
    emoji: '🎯',
    description: 'Learn through practical demonstrations',
  },
  {
    id: 'group_discussion',
    name: 'Group Discussion',
    emoji: '👥',
    description: 'Collaborative learning through group interaction',
  },
  {
    id: 'visual_learning',
    name: 'Visual Learning',
    emoji: '📊',
    description: 'Learn through diagrams, charts, and visual aids',
  }
];

const ENTERTAINMENT_MODES = [
  {
    id: 'adventure',
    name: 'Adventure',
    emoji: '🗺️',
    description: 'Epic quests and thrilling journeys',
  },
  {
    id: 'mystery',
    name: 'Mystery',
    emoji: '🔍',
    description: 'Solve intriguing cases and uncover secrets',
  },
  {
    id: 'fantasy',
    name: 'Fantasy',
    emoji: '🐉',
    description: 'Magical worlds and mythical creatures',
  },
  {
    id: 'sci_fi',
    name: 'Sci-Fi',
    emoji: '🚀',
    description: 'Futuristic tales and space exploration',
  },
  {
    id: 'horror',
    name: 'Horror',
    emoji: '👻',
    description: 'Spooky stories and supernatural encounters',
  },
  {
    id: 'romance',
    name: 'Romance',
    emoji: '💝',
    description: 'Love stories and relationships',
  },
  {
    id: 'comedy',
    name: 'Comedy',
    emoji: '😄',
    description: 'Humor and funny situations',
  },
  {
    id: 'slice_of_life',
    name: 'Slice of Life',
    emoji: '🌅',
    description: 'Everyday stories and relatable moments',
  }
];

const LEARNING_MODE_CHOICES = ['interactive_lecture', 'story_based', 'entertainment'] as const;

const NewStory: React.FC = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<StoryData>({
    title: '',
    context: '',
    education_level: 'General',
    class_id: null,
    is_public: false,
    is_educational: true,
    tags: [],
    learning_mode: 'story_based',
    story_modes: [],
    complexity: '',
    narrative_perspective: 'third_person'
  });
  const [classes, setClasses] = useState<Class[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({ 
    open: false, 
    message: '', 
    severity: 'success' as 'success' | 'error' | 'warning' 
  });
  const navigate = useNavigate();

  // Steps configuration
  const steps = ['Select Learning Mode', 'Choose Story Mode', 'Story Details'];

  useEffect(() => {
    const urlTitle = searchParams.get('title');
    if (urlTitle) {
      setFormData(prev => ({ ...prev, title: decodeURIComponent(urlTitle) }));
    }
    fetchClasses();
  }, [searchParams]);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
      // Only use owned classes, not enrolled classes
      const ownedClasses = response.data.owned_classes;
      setClasses(ownedClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
      setSnackbar({ open: true, message: 'Failed to fetch classes. Please try again.', severity: 'error' });
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    try {
      setLoading(true);
      setError(null);

      const response = await axios.post(`${API_BASE_URL}/stories/`, formData);
      navigate(`/story/${response.data.id}`);
    } catch (error: any) {
      setError(error.response?.data?.message || 'An error occurred while creating the story.');
      setSnackbar({
        open: true,
        message: 'Failed to create story. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field: keyof StoryData, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleStoryModeSelect = (modeId: string) => {
    setFormData(prev => ({
      ...prev,
      story_modes: [modeId] // We only allow one mode for now
    }));
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <StyledStepContent>
            <Typography variant="h6" gutterBottom>
              Choose your mode
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Card 
                    sx={getCardStyle(formData.learning_mode === 'story_based')}
                  >
                    <CardActionArea 
                      onClick={() => {
                        handleInputChange('learning_mode', 'story_based');
                        handleInputChange('is_educational', true);
                        handleInputChange('story_modes', []);
                      }}
                      sx={{ height: '100%' }}
                    >
                      <CardContent sx={getContentStyle(formData.learning_mode === 'story_based')}>
                        <Box className="icon-wrapper">
                          <Typography variant="h2" component="div">📚</Typography>
                        </Box>
                        <Typography variant="h6" component="div" gutterBottom align="center">
                          Story-Based Learning
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="center">
                          Learn through engaging stories and interactive narratives. Perfect for immersive and contextual learning.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Card 
                    sx={getCardStyle(formData.learning_mode === 'interactive_lecture')}
                  >
                    <CardActionArea 
                      onClick={() => {
                        handleInputChange('learning_mode', 'interactive_lecture');
                        handleInputChange('is_educational', true);
                        handleInputChange('story_modes', []);
                        handleInputChange('teaching_method', '');
                      }}
                      sx={{ height: '100%' }}
                    >
                      <CardContent sx={getContentStyle(formData.learning_mode === 'interactive_lecture')}>
                        <Box className="icon-wrapper">
                          <Typography variant="h2" component="div">🎓</Typography>
                        </Box>
                        <Typography variant="h6" component="div" gutterBottom align="center">
                          Interactive Lecture
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="center">
                          Traditional learning with interactive elements. Great for structured and focused learning sessions.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Card 
                    sx={getCardStyle(formData.learning_mode === 'entertainment')}
                  >
                    <CardActionArea 
                      onClick={() => {
                        handleInputChange('learning_mode', 'entertainment');
                        handleInputChange('is_educational', false);
                        handleInputChange('story_modes', []);
                        handleInputChange('teaching_method', '');
                      }}
                      sx={{ height: '100%' }}
                    >
                      <CardContent sx={getContentStyle(formData.learning_mode === 'entertainment')}>
                        <Box className="icon-wrapper">
                          <Typography variant="h2" component="div">🎮</Typography>
                        </Box>
                        <Typography variant="h6" component="div" gutterBottom align="center">
                          Entertainment
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="center">
                          Create fun, engaging stories focused on entertainment. Perfect for creative writing and interactive fiction.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </StyledStepContent>
        );
      case 1:
        if (formData.learning_mode === 'story_based') {
          return (
            <StyledStepContent>
              <Typography variant="h6" gutterBottom>
                Select a story mode that best fits your teaching style
              </Typography>
              <Grid container spacing={3}>
                {STORY_MODES.map((mode) => (
                  <Grid item xs={12} sm={6} md={4} key={mode.id}>
                    <StoryModeCard
                      mode={mode}
                      selected={formData.story_modes?.includes(mode.id)}
                      onSelect={handleStoryModeSelect}
                    />
                  </Grid>
                ))}
              </Grid>

              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Narrative Perspective
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  Choose how your story will be told
                </Typography>
                <Grid container spacing={2}>
                  {[
                    { label: 'First Person', value: 'first_person', emoji: '👤', desc: '"I" perspective - through the protagonist\'s eyes' },
                    { label: 'Second Person', value: 'second_person', emoji: '👉', desc: '"You" perspective - reader as protagonist' },
                    { label: 'Third Person', value: 'third_person', emoji: '👥', desc: '"They" perspective - external narrator' }
                  ].map((perspective) => (
                    <Grid item xs={12} sm={4} key={perspective.value}>
                      <Card 
                        sx={getCardStyle(formData.narrative_perspective === perspective.value)}
                      >
                        <CardActionArea onClick={() => handleInputChange('narrative_perspective', perspective.value)} sx={{ height: '100%' }}>
                          <CardContent sx={getContentStyle(formData.narrative_perspective === perspective.value)}>
                            <Box className="icon-wrapper">
                              <Typography variant="h4" component="div">
                                {perspective.emoji}
                              </Typography>
                            </Box>
                            <Typography variant="h6" component="div" align="center">
                              {perspective.label}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                              {perspective.desc}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </StyledStepContent>
          );
        } else if (formData.learning_mode === 'interactive_lecture') {
          return (
            <StyledStepContent>
              <Typography variant="h6" gutterBottom>
                Configure Your Interactive Lecture
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                Customize your lecture settings to create an engaging learning experience
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  {LECTURE_MODES.map((mode) => (
                    <Grid item xs={12} sm={6} md={4} key={mode.id}>
                      <Card 
                        sx={getCardStyle(formData.teaching_method === mode.id)}
                      >
                        <CardActionArea onClick={() => handleInputChange('teaching_method', mode.id)} sx={{ height: '100%' }}>
                          <CardContent sx={getContentStyle(formData.teaching_method === mode.id)}>
                            <Box className="icon-wrapper">
                              <Typography variant="h2" component="div">
                                {mode.emoji}
                              </Typography>
                            </Box>
                            <Typography variant="h6" component="div" gutterBottom align="center">
                              {mode.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center">
                              {mode.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Interaction Level
                  </Typography>
                  <Grid container spacing={2}>
                    {['Low', 'Medium', 'High'].map((level) => (
                      <Grid item xs={12} sm={4} key={level}>
                        <Card 
                          sx={getCardStyle(formData.interaction_level === level.toLowerCase())}
                        >
                          <CardActionArea onClick={() => handleInputChange('interaction_level', level.toLowerCase())} sx={{ height: '100%' }}>
                            <CardContent sx={getContentStyle(formData.interaction_level === level.toLowerCase())}>
                              <Box className="icon-wrapper">
                                <Typography variant="h4" component="div">
                                  {level === 'Low' ? '🌱' : level === 'Medium' ? '🌿' : '🌳'}
                                </Typography>
                              </Box>
                              <Typography variant="h6" align="center">
                                {level}
                              </Typography>
                              <Typography variant="body2" color="text.secondary" align="center">
                                {level === 'Low' ? 'Minimal interaction, focus on content delivery' :
                                 level === 'Medium' ? 'Balanced mix of content and interaction' :
                                 'Highly interactive with frequent engagement'}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </StyledStepContent>
          );
        } else {
          // Entertainment mode
          return (
            <StyledStepContent>
              <Typography variant="h6" gutterBottom>
                Choose Your Story Genre
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                Select a genre to create your entertaining story
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  {ENTERTAINMENT_MODES.map((mode) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={mode.id}>
                      <Card 
                        sx={getCardStyle(formData.story_modes?.includes(mode.id))}
                      >
                        <CardActionArea onClick={() => handleInputChange('story_modes', [mode.id])} sx={{ height: '100%' }}>
                          <CardContent sx={getContentStyle(formData.story_modes?.includes(mode.id))}>
                            <Box className="icon-wrapper">
                              <Typography variant="h2" component="div">
                                {mode.emoji}
                              </Typography>
                            </Box>
                            <Typography variant="h6" component="div" gutterBottom align="center">
                              {mode.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center">
                              {mode.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Story Complexity
                  </Typography>
                  <Grid container spacing={2}>
                    {['Simple', 'Medium', 'Complex'].map((level) => (
                      <Grid item xs={12} sm={4} key={level}>
                        <Card 
                          sx={getCardStyle(formData.complexity === level.toLowerCase())}
                        >
                          <CardActionArea onClick={() => handleInputChange('complexity', level.toLowerCase())} sx={{ height: '100%' }}>
                            <CardContent sx={getContentStyle(formData.complexity === level.toLowerCase())}>
                              <Box className="icon-wrapper">
                                <Typography variant="h4" component="div">
                                  {level === 'Simple' ? '🎯' : level === 'Medium' ? '🎲' : '🧩'}
                                </Typography>
                              </Box>
                              <Typography variant="h6" align="center">
                                {level}
                              </Typography>
                              <Typography variant="body2" color="text.secondary" align="center">
                                {level === 'Simple' ? 'Straightforward storyline with clear progression' :
                                 level === 'Medium' ? 'Multiple paths and engaging choices' :
                                 'Rich narrative with intricate plot elements'}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Narrative Perspective
                  </Typography>
                  <Grid container spacing={2}>
                    {[
                      { label: 'First Person', value: 'first_person', emoji: '👤', desc: '"I" perspective - through the protagonist\'s eyes' },
                      { label: 'Second Person', value: 'second_person', emoji: '👉', desc: '"You" perspective - reader as protagonist' },
                      { label: 'Third Person', value: 'third_person', emoji: '👥', desc: '"They" perspective - external narrator' }
                    ].map((perspective) => (
                      <Grid item xs={12} sm={4} key={perspective.value}>
                        <Card 
                          sx={getCardStyle(formData.narrative_perspective === perspective.value)}
                        >
                          <CardActionArea onClick={() => handleInputChange('narrative_perspective', perspective.value)} sx={{ height: '100%' }}>
                            <CardContent sx={getContentStyle(formData.narrative_perspective === perspective.value)}>
                              <Box className="icon-wrapper">
                                <Typography variant="h4" component="div">
                                  {perspective.emoji}
                                </Typography>
                              </Box>
                              <Typography variant="h6" component="div" align="center">
                                {perspective.label}
                              </Typography>
                              <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                                {perspective.desc}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </StyledStepContent>
          );
        }
      case 2:
        return (
          <StyledStepContent>
            <Typography variant="h6" gutterBottom>
              Enter story details
            </Typography>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Title"
                value={formData.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
                margin="normal"
                required
                helperText="Give your story a catchy, descriptive title"
              />
              <Box sx={{ mt: 3, mb: 2 }}>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Writing an Effective Context
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  Describe what you want to teach in detail. The more specific you are, the better the story will be.
                </Typography>
                <Box sx={{ 
                  backgroundColor: 'background.paper', 
                  p: 2, 
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: 'divider',
                  mb: 2
                }}>
                  <Typography variant="subtitle2" color="primary.main" gutterBottom>
                    Tips for Great Prompts:
                  </Typography>
                  <Typography component="div" variant="body2">
                    <ul style={{ marginTop: 0, paddingLeft: '1.5rem' }}>
                      <li>Be specific about the topic (e.g., "Photosynthesis in plants" instead of just "Biology")</li>
                      <li>Include key concepts you want to cover</li>
                      <li>Mention any specific examples or analogies you'd like to use</li>
                      <li>Consider your audience's prior knowledge</li>
                    </ul>
                  </Typography>
                  <Typography variant="subtitle2" color="primary.main" sx={{ mt: 2 }} gutterBottom>
                    Example Prompts:
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      ✅ "Teach the water cycle, focusing on evaporation, condensation, and precipitation. Include real-world examples like morning dew and storm clouds."
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      ✅ "Explain how fractions work using pizza slices as examples. Cover addition and subtraction of fractions with like denominators."
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ❌ "Teach math" (too vague)
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <TextField
                fullWidth
                label="Context"
                value={formData.context}
                onChange={(e) => handleInputChange('context', e.target.value)}
                margin="normal"
                multiline
                rows={4}
                required
                placeholder="Describe what you want to teach..."
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Education Level</InputLabel>
                <Select
                  value={formData.education_level}
                  onChange={(e) => handleInputChange('education_level', e.target.value)}
                  label="Education Level"
                >
                  {educationLevels.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Select the appropriate level to ensure content matches your audience
                </FormHelperText>
              </FormControl>
              {classes.length > 0 && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Class</InputLabel>
                  <Select
                    value={formData.class_id?.toString() || ''}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setFormData({ 
                        ...formData, 
                        class_id: newValue ? Number(newValue) : null,
                        is_public: newValue ? false : formData.is_public
                      });
                    }}
                    label="Class"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {classes.map((cls) => (
                      <MenuItem key={cls.id} value={cls.id.toString()}>
                        {cls.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Optionally assign this story to one of your classes
                  </FormHelperText>
                </FormControl>
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.is_public}
                    onChange={(e) => handleInputChange('is_public', e.target.checked)}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body1">Make story public</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Public stories can be discovered and used by other educators
                    </Typography>
                  </Box>
                }
                sx={{ mt: 2 }}
              />
            </Box>
          </StyledStepContent>
        );
      default:
        return 'Unknown step';
    }
  };

  const getCardStyle = (isSelected: boolean) => ({
    cursor: 'pointer',
    height: '100%',
    transition: 'all 0.3s ease',
    border: isSelected ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent',
    backgroundColor: isSelected ? theme.palette.action.selected : theme.palette.background.paper,
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: theme.shadows[4],
    },
  });

  const getContentStyle = (isSelected: boolean) => ({
    '& .MuiTypography-h6': {
      fontWeight: isSelected ? 'bold' : 'medium',
      color: isSelected ? 'primary.main' : 'text.primary',
    },
    '& .icon-wrapper': {
      fontSize: '2.5rem',
      mb: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '60px',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.1)',
      }
    }
  });

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        paddingTop: theme.spacing(4),
        paddingX: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingX: theme.spacing(3),
        },
      }}
    >
      <Paper elevation={3} sx={{ 
        p: 4, 
        borderRadius: theme => theme.shape.borderRadius * 2 
      }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
          Create New Story
        </Typography>
        
        <Stepper activeStep={activeStep} sx={{ my: 4 }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={() => (
                <Typography 
                  variant="h6" 
                  component="span" 
                  sx={{ 
                    opacity: activeStep >= index ? 1 : 0.5,
                    transition: 'opacity 0.2s ease-in-out'
                  }}
                >
                  {index === 0 ? '🎯' : index === 1 ? '🎨' : '📝'}
                </Typography>
              )}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {getStepContent(activeStep)}

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          mt: 4,
          gap: 2
        }}>
          <StyledButton
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{
              bgcolor: 'background.paper',
              color: 'text.primary',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: theme => theme.shape.borderRadius * 2,
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <span className="back-emoji" role="img" aria-label="back">
              ⬅️
            </span>
            Back
          </StyledButton>
          
          <Box>
            {activeStep === steps.length - 1 ? (
              <StyledButton
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                  color: 'white',
                  borderRadius: theme => theme.shape.borderRadius * 2,
                }}
              >
                <span className="emoji" role="img" aria-label="sparkles">
                  ✨
                </span>
                {loading ? 'Creating...' : 'Create Story'}
                {loading && <CircularProgress size={20} sx={{ ml: 1, color: 'white' }} />}
              </StyledButton>
            ) : (
              <StyledButton
                variant="contained"
                onClick={handleNext}
                sx={{
                  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                  color: 'white',
                  borderRadius: theme => theme.shape.borderRadius * 2,
                }}
              >
                Next
                <span className="forward-emoji" role="img" aria-label="forward">
                  ➡️
                </span>
              </StyledButton>
            )}
          </Box>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mt: 2, borderRadius: theme => theme.shape.borderRadius }}>
            {error}
          </Alert>
        )}
      </Paper>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          severity={snackbar.severity} 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          sx={{ borderRadius: theme => theme.shape.borderRadius }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewStory;