import React from 'react';
import storybyteIcon from '../assets/Asset 4.png';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  useMediaQuery,
  useTheme,
  Tooltip,
  Slide,
  useScrollTrigger,
  Avatar,
  ListItemIcon,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Menu as MenuIcon,
  ChevronRight as ChevronRightIcon,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
  Logout,
} from '@mui/icons-material';
import { ColorModeContext } from '../App';

interface NavbarProps {
  isAuthenticated: boolean;
  onLogout: () => void;
  isTransparent?: boolean;
}

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 100,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Navbar: React.FC<NavbarProps> = ({ isAuthenticated, onLogout, isTransparent = false }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { toggleColorMode, mode } = React.useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [bannerVisible, setBannerVisible] = React.useState<boolean>(
    !localStorage.getItem('discordBannerClosed')
  );

  const handleBannerChange = React.useCallback((event: CustomEvent) => {
    setBannerVisible(event.detail.show);
  }, []);

  React.useEffect(() => {
    window.addEventListener('discordBannerChange', handleBannerChange as EventListener);
    return () => {
      window.removeEventListener('discordBannerChange', handleBannerChange as EventListener);
    };
  }, [handleBannerChange]);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState<string | null>(null);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  const handleDrawerToggle = React.useCallback(() => {
    setDrawerOpen(prev => !prev);
  }, []);

  const handleSubMenuToggle = React.useCallback((menu: string) => {
    setOpenSubMenu(prev => prev === menu ? null : menu);
  }, []);

  const handleNavigation = React.useCallback((path: string) => () => {
    navigate(path);
    setDrawerOpen(false);
    setOpenSubMenu(null);
  }, [navigate]);

  const handleProfileMenuOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    
    if (!anchorRef.current) {
      const anchorElement = document.createElement('div');
      anchorElement.style.position = 'fixed';
      document.body.appendChild(anchorElement);
      anchorRef.current = anchorElement;
    }
    
    anchorRef.current.style.top = `${rect.bottom}px`;
    anchorRef.current.style.left = `${rect.right}px`;
    setProfileAnchorEl(anchorRef.current);
  }, []);

  const handleProfileMenuClose = () => {
    if (anchorRef.current) {
      document.body.removeChild(anchorRef.current);
      anchorRef.current = null;
    }
    setProfileAnchorEl(null);
  };

  React.useEffect(() => {
    return () => {
      if (anchorRef.current) {
        document.body.removeChild(anchorRef.current);
      }
    };
  }, []);

  const handleLogout = () => {
    handleProfileMenuClose();
    onLogout();
  };

  const renderThemeToggle = () => (
    <IconButton
      onClick={toggleColorMode}
      sx={{
        color: mode === 'dark' ? '#FFFFFF' : '#000000',
        transition: 'color 0.3s ease'
      }}
    >
      {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );

  const navButtonStyle = {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
    },
    textTransform: 'none',
    fontWeight: 500,
    ...(isTransparent && theme.palette.mode === 'dark' && {
      textShadow: '0 2px 8px rgba(0,0,0,0.5)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'radial-gradient(circle at center, rgba(0,0,0,0.1) 0%, transparent 70%)',
        filter: 'blur(4px)',
        zIndex: -1
      }
    })
  };

  const menuItemStyle = {
    minWidth: '180px', 
    padding: '10px 20px',
    color: theme.palette.text.primary,
    '&:hover': {
      bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)'
    }
  };

  const MobileDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          width: 280,
          background: theme.palette.mode === 'dark'
            ? 'rgba(18, 18, 18, 0.8)'
            : 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(10px)',
          borderLeft: '1px solid',
          borderColor: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <List sx={{ color: 'inherit' }}>
        <ListItem>
          {renderThemeToggle()}
        </ListItem>
        {!isAuthenticated && (
          <ListItem button onClick={handleNavigation('/about')}>
            <ListItemText primary="About" />
          </ListItem>
        )}

        {isAuthenticated ? (
          <>
            <ListItem button onClick={handleNavigation('/dashboard')}>
              <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem button onClick={handleNavigation('/public')}>
              <ListItemText primary="Feed" />
            </ListItem>

            <ListItem button onClick={() => handleSubMenuToggle('library')}>
              <ListItemText primary="Library" />
              <ChevronRightIcon
                sx={{
                  transform: openSubMenu === 'library' ? 'rotate(90deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            </ListItem>
            <Collapse in={openSubMenu === 'library'} timeout="auto">
              <List component="div" disablePadding>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/library')}>
                  <ListItemText primary="Public Library" />
                </ListItem>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/my-library')}>
                  <ListItemText primary="My Library" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleNavigation('/classes')}>
              <ListItemText primary="Classes" />
            </ListItem>

            <ListItem button onClick={handleNavigation('/profile')}>
              <ListItemText primary="Profile" />
            </ListItem>

            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleNavigation('/classes?join=true')}
                sx={{
                  background: 'linear-gradient(45deg, #2196F3, #1976D2)',
                  color: 'white',
                  py: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                }}
              >
                Join Class
              </Button>

              <Button
                variant="contained"
                fullWidth
                onClick={handleNavigation('/new')}
                sx={{
                  background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                  color: 'white',
                  py: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                }}
              >
                New Story
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button
              component={RouterLink}
              to="/login"
              sx={{
                background: 'linear-gradient(45deg, #2196F3, #1976D2)',
                color: 'white',
                px: 3,
                py: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '1rem',
                boxShadow: '0 4px 15px rgba(33, 150, 243, 0.3)',
                border: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'linear-gradient(45deg, #42A5F5, #1E88E5)',
                  boxShadow: '0 6px 20px rgba(33, 150, 243, 0.4)',
                  transform: 'translateY(-2px)',
                },
                '&:active': {
                  transform: 'translateY(1px)',
                  boxShadow: '0 2px 10px rgba(33, 150, 243, 0.2)',
                },
                ...(isTransparent && {
                  textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                  boxShadow: '0 4px 15px rgba(0,0,0,0.3), 0 4px 15px rgba(33, 150, 243, 0.3)',
                  '&:hover': {
                    boxShadow: '0 6px 20px rgba(0,0,0,0.4), 0 6px 20px rgba(33, 150, 243, 0.4)',
                    transform: 'translateY(-2px)',
                  }
                })
              }}
            >
              Login
            </Button>
            <Button
              variant="contained"
              onClick={handleNavigation('/signup')}
              sx={{
                background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                color: 'white',
                px: 3,
                py: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '1rem',
                boxShadow: '0 4px 15px rgba(255, 138, 0, 0.3)',
                border: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: 'linear-gradient(45deg, #FF9500, #FF5500)',
                  boxShadow: '0 6px 20px rgba(255, 138, 0, 0.4)',
                  transform: 'translateY(-2px)',
                },
                '&:active': {
                  transform: 'translateY(1px)',
                  boxShadow: '0 2px 10px rgba(255, 138, 0, 0.2)',
                }
              }}
            >
              Sign Up
            </Button>
          </Box>
        )}
      </List>
    </Drawer>
  );

  const DesktopNav = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {isAuthenticated && (
          <Button
            component={RouterLink}
            to="/dashboard"
            sx={navButtonStyle}
          >
            Dashboard
          </Button>
        )}
        {!isAuthenticated && (
          <Button
            component={RouterLink}
            to="/about"
            sx={navButtonStyle}
          >
            About
          </Button>
        )}
        <Button
          component={RouterLink}
          to="/public"
          sx={navButtonStyle}
        >
          Feed
        </Button>
        <Button
          component={RouterLink}
          to="/library"
          sx={navButtonStyle}
        >
          Library
        </Button>
        <Button
          component={RouterLink}
          to="/classes"
          sx={navButtonStyle}
        >
          Classes
        </Button>
        <Button
          component={RouterLink}
          to="/classes?join=true"
          sx={{
            background: theme.palette.mode === 'dark' 
              ? 'linear-gradient(45deg, #2196F3, #1976D2)'
              : '#1976D2',
            color: 'white',
            px: 3,
            py: 1,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            border: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: theme.palette.mode === 'dark'
                ? 'linear-gradient(45deg, #1E88E5, #1565C0)'
                : '#1E88E5',
              transform: 'translateY(-2px)',
            }
          }}
        >
          Join Class
        </Button>
        <Button
          component={RouterLink}
          to="/new"
          sx={{
            background: theme.palette.mode === 'dark' 
              ? 'linear-gradient(45deg, #FF8A00, #FF4F00)'
              : '#FF4F00',
            color: 'white',
            px: 3,
            py: 1,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            border: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
              background: theme.palette.mode === 'dark'
                ? 'linear-gradient(45deg, #FF9500, #FF6000)'
                : '#FF6000',
              transform: 'translateY(-2px)',
            }
          }}
        >
          New Story
        </Button>
        {renderThemeToggle()}
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={handleProfileMenuOpen}
            sx={{
              ml: 1,
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          >
            <Avatar sx={{ width: 32, height: 32 }} />
          </IconButton>
          <Menu
            anchorEl={profileAnchorEl}
            id="profile-menu"
            open={Boolean(profileAnchorEl)}
            onClose={handleProfileMenuClose}
            onClick={handleProfileMenuClose}
            sx={{
              '& .MuiMenu-paper': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#FFFFFF',
                borderRadius: 2,
                minWidth: 180,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
              },
              '& .MuiMenuItem-root': {
                padding: '10px 15px',
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)'
                },
              },
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                mt: 1,
                position: 'fixed',
                right: theme.spacing(2),
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#FFFFFF',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem 
              component={RouterLink} 
              to="/profile"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
              }}
            >
              <Avatar sx={{ width: 24, height: 24 }} />
              <Typography>Profile</Typography>
            </MenuItem>
            <MenuItem 
              onClick={onLogout}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
              }}
            >
              <ListItemIcon sx={{ minWidth: 'auto' }}>
                <Logout fontSize="small" />
              </ListItemIcon>
              <Typography>Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    );
  };

  return (
    <HideOnScroll>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: isTransparent ? 'transparent' : 'background.paper',
          boxShadow: isTransparent ? 'none' : 1,
          top: 0,
          transition: 'all 0.3s ease',
          backdropFilter: !isTransparent ? 'blur(20px)' : 'none',
          backgroundImage: 'none',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <img
              src={storybyteIcon}
              alt="Storybyte Logo"
              style={{ 
                height: '40px', 
                marginRight: '10px',
                filter: theme.palette.mode === 'light' && !isTransparent ? 'brightness(0)' : 'brightness(1)',
                transition: 'filter 0.3s ease'
              }}
            />
            <Typography 
              variant="h6" 
              component="div" 
              sx={{ 
                flexGrow: 1, 
                fontWeight: 'bold', 
                letterSpacing: '.1rem',
                display: { xs: 'none', sm: 'block' },
                fontFamily: 'source-code-pro, monospace',
                color: mode === 'dark' ? '#FFFFFF' : '#000000',
                transition: 'color 0.3s ease'
              }}
            >
              Storybyte
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {isMobile ? (
            <>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <MobileDrawer />
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {isAuthenticated ? (
                <DesktopNav />
              ) : (
                <>
                  <Button
                    component={RouterLink}
                    to="/about"
                    sx={navButtonStyle}
                  >
                    About
                  </Button>
                  <Button
                    component={RouterLink}
                    to="/login"
                    sx={{
                      background: 'linear-gradient(45deg, #2196F3, #1976D2)',
                      color: 'white',
                      px: 3,
                      py: 1,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 600,
                      border: 'none',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        background: 'linear-gradient(45deg, #1E88E5, #1565C0)',
                        transform: 'translateY(-2px)',
                      }
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    component={RouterLink}
                    to="/signup"
                    sx={{
                      background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                      color: 'white',
                      px: 3,
                      py: 1,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 600,
                      fontSize: '1rem',
                      boxShadow: '0 4px 15px rgba(255, 138, 0, 0.3)',
                      border: 'none',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        background: 'linear-gradient(45deg, #FF9500, #FF5500)',
                        boxShadow: '0 6px 20px rgba(255, 138, 0, 0.4)',
                        transform: 'translateY(-2px)',
                      },
                      '&:active': {
                        transform: 'translateY(1px)',
                        boxShadow: '0 2px 10px rgba(255, 138, 0, 0.2)',
                      }
                    }}
                  >
                    Sign Up
                  </Button>
                  {renderThemeToggle()}
                </>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default Navbar;